import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Calendar, Camera, Users, MessageSquare, MapPin } from 'lucide-react';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';


const WeddingSettings = () => {
  const { t } = useTranslation();
  const [weddingDetails, setWeddingDetails] = useState({
    wedding_date: '',
    person1_name: '',
    person2_name: '',
    wedding_name: '',
    wedding_location: '',
    image_url: null
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const supabase = getSupabase();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchWeddingDetails();
  }, []);

  const fetchWeddingDetails = async () => {
    setIsLoading(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/wedding-details`, {
          headers: { 'Authorization': `Bearer ${session.access_token}` }
        });
        if (response.ok) {
          const data = await response.json();
          setWeddingDetails(data);
          if (data.image_url) {
            setPreviewUrl(data.image_url);
          }
        } else {
          throw new Error('Failed to fetch wedding details');
        }
      } catch (error) {
        console.error('Error fetching wedding details:', error);
        toast.error(t('components.weddingSettings.fetchError'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWeddingDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWeddingDetails(prevDetails => ({
        ...prevDetails,
        image_url: file
      }));
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        let updatedDetails = { ...weddingDetails };

        console.log('Initial updatedDetails:', updatedDetails); // Debug log

        // If there's a new image file, upload it first
        if (updatedDetails.image_url instanceof File) {
          const formData = new FormData();
          formData.append('image', updatedDetails.image_url);

          const imageResponse = await fetch(`${API_BASE_URL}/upload-image`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${session.access_token}`,
            },
            body: formData
          });

          if (imageResponse.ok) {
            const imageData = await imageResponse.json();
            updatedDetails.image_url = imageData.imageUrl; // Only use the URL from the response
            console.log('Image uploaded, new URL:', updatedDetails.image_url); // Debug log
          } else {
            const errorData = await imageResponse.json();
            throw new Error(errorData.error || 'Failed to upload image');
          }
        } else {
          console.log('No new image file detected'); // Debug log
        }

        console.log('Updated details before sending:', updatedDetails); // Debug log

        // Ensure image_url is included even if it's not changed
        if (!updatedDetails.image_url && weddingDetails.image_url) {
          updatedDetails.image_url = weddingDetails.image_url;
          console.log('Using existing image URL:', updatedDetails.image_url); // Debug log
        }

        // Now send the wedding details with the updated image URL
        const response = await fetch(`${API_BASE_URL}/wedding-details`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedDetails)
        });

        console.log('Response status:', response.status); // Debug log

        if (response.ok) {
          const data = await response.json();
          console.log('Response data:', data); // Debug log
          setWeddingDetails(data);
          setPreviewUrl(data.image_url);
          toast.success(t('components.weddingSettings.updateSuccess'));
        } else {
          const errorData = await response.json();
          console.error('Failed to update wedding details:', errorData);
          toast.error(`${t('components.weddingSettings.updateError')}: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error updating wedding details:', error);
        toast.error(`${t('components.weddingSettings.updateError')}: ${error.message}`);
      } finally {
        setIsUpdating(false);
      }
    } else {
      toast.error(t('components.weddingSettings.noSession'));
      setIsUpdating(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-6">{t('components.weddingSettings.title')}</h2>
      <div className="flex flex-col md:flex-row gap-8">
        <form onSubmit={handleSubmit} className="space-y-6 flex-1">
          <div>
            <label htmlFor="wedding_date" className="block text-sm font-medium text-gray-700 mb-1">
              <Calendar className="inline-block w-5 h-5 mr-2" />
              {t('components.weddingSettings.weddingDate')}
            </label>
            <input
              type="date"
              id="wedding_date"
              name="wedding_date"
              value={weddingDetails.wedding_date}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="person1_name" className="block text-sm font-medium text-gray-700 mb-1">
              <Users className="inline-block w-5 h-5 mr-2" />
              {t('components.weddingSettings.person1Name')}
            </label>
            <input
              type="text"
              id="person1_name"
              name="person1_name"
              value={weddingDetails.person1_name}
              onChange={handleInputChange}
              placeholder="e.g. Alice"
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="person2_name" className="block text-sm font-medium text-gray-700 mb-1">
              <Users className="inline-block w-5 h-5 mr-2" />
              {t('components.weddingSettings.person2Name')}
            </label>
            <input
              type="text"
              id="person2_name"
              name="person2_name"
              value={weddingDetails.person2_name}
              onChange={handleInputChange}
              placeholder="e.g. Bob"
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="wedding_name" className="block text-sm font-medium text-gray-700 mb-1">
              <MessageSquare className="inline-block w-5 h-5 mr-2" />
              {t('components.weddingSettings.weddingName')}
            </label>
            <input
              type="text"
              id="wedding_name"
              name="wedding_name"
              value={weddingDetails.wedding_name}
              onChange={handleInputChange}
              placeholder="e.g. Alice & Bob's Magical Day"
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="wedding_location" className="block text-sm font-medium text-gray-700 mb-1">
              <MapPin className="inline-block w-5 h-5 mr-2" />
              {t('components.weddingSettings.weddingLocation')}
            </label>
            <input
              type="text"
              id="wedding_location"
              name="wedding_location"
              value={weddingDetails.wedding_location}
              onChange={handleInputChange}
              placeholder="e.g. Sunset Beach Resort"
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="image_url" className="block text-sm font-medium text-gray-700 mb-1">
              <Camera className="inline-block w-5 h-5 mr-2" />
              {t('components.weddingSettings.weddingPicture')}
            </label>
            <div className="mt-1">
              <label className="w-full flex items-center px-4 py-2 bg-white text-gray-700 rounded-md border border-gray-300 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                <Camera className="w-5 h-5 mr-2" />
                <span>{t('components.weddingSettings.chooseFile')}</span>
                <input
                  type="file"
                  id="image_url"
                  name="image_url"
                  onChange={handleFileChange}
                  accept="image/*"
                  className="hidden"
                />
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 bg-gray-800 text-white rounded-md text-sm font-medium hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors duration-150"
            disabled={isUpdating}
          >
            {isUpdating ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {t('components.weddingSettings.updating')}
              </span>
            ) : (
              t('components.weddingSettings.saveWeddingDetails')
            )}
          </button>
        </form>
        <div className="flex-1 flex items-center justify-center">
          <div className="w-full h-full max-h-[calc(100vh-16rem)] overflow-hidden rounded-lg shadow-lg">
            {previewUrl ? (
              <img src={previewUrl} alt="Wedding Preview" className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <p className="text-gray-500">{t('components.weddingSettings.noImageUploaded')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeddingSettings;