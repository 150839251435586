import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomIcon from './CustomIcon';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-20 md:py-32 bg-gradient-to-b from-gray-50 to-white">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl mb-6 text-gray-800">{t('components.about.title')}</h1>
              <p className="text-xl text-gray-600 leading-relaxed">{t('components.about.subtitle')}</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <div>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Metztli-Luna.jpg/440px-Metztli-Luna.jpg"
                  alt="Metzi Symbol"
                  className="w-full rounded-lg shadow-xl"
                />
              </div>
              <div className="space-y-6">
                <h2 className="text-3xl font-semibold text-gray-800">{t('components.about.meaningTitle')}</h2>
                <p className="text-lg text-gray-600">{t('components.about.meaningDescription')}</p>
                <div className="flex items-center space-x-4">
                  <CustomIcon d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                  <span className="text-lg font-medium text-gray-800">{t('components.about.moonSymbol')}</span>
                </div>
                <div className="flex items-center space-x-4">
                  <CustomIcon d="M8 13v-1m4 1v-3m4 3V8M12 21a9 9 0 11-18 0 9 9 0 0118 0z" />
                  <span className="text-lg font-medium text-gray-800">{t('components.about.cyclesSymbol')}</span>
                </div>
                <div className="flex items-center space-x-4">
                  <CustomIcon d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  <span className="text-lg font-medium text-gray-800">{t('components.about.balanceSymbol')}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-20 md:py-32 bg-gray-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-semibold tracking-tight sm:text-4xl mb-4 text-gray-800">{t('components.about.valuesTitle')}</h2>
              <p className="text-lg text-gray-600">{t('components.about.valuesSubtitle')}</p>
            </div>
            <div className="grid gap-8 md:grid-cols-3">
              {[
                { title: t('components.about.nurturingTitle'), description: t('components.about.nurturingDesc'), icon: "M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" },
                { title: t('components.about.growthTitle'), description: t('components.about.growthDesc'), icon: "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" },
                { title: t('components.about.harmonyTitle'), description: t('components.about.harmonyDesc'), icon: "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" },
              ].map((value, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                  <div className="w-12 h-12 mb-4 rounded-full bg-gray-100 flex items-center justify-center">
                    <CustomIcon d={value.icon} className="w-6 h-6 text-gray-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;