import React, { useState, useEffect } from 'react';
import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './LoadingSpinner';

export function RSVPSeatingChart({ weddingId, rsvpData }) {
  const [tables, setTables] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const supabase = getSupabase();
  const { t } = useTranslation();

  useEffect(() => {
    fetchSeatingChart();
  }, [weddingId]);

  const fetchSeatingChart = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('seating_charts')
        .select('*')
        .eq('wedding_id', weddingId)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          console.log('No seating chart found for this wedding');
          setTables([]);
        } else {
          throw error;
        }
      } else if (data) {
        setTables(data.tables || []);
      }
    } catch (error) {
      console.error('Error fetching seating chart:', error);
      toast.error(t('components.seatingChart.fetchError'));
    } finally {
      setIsLoading(false);
    }
  };

  const saveSeatingChart = async () => {
    try {
      const { data, error } = await supabase
        .from('seating_charts')
        .upsert(
          {
            wedding_id: weddingId,
            tables: tables
          },
          { onConflict: 'wedding_id' }
        );

      if (error) throw error;

      toast.success(t('components.seatingChart.saveSuccess'));
    } catch (error) {
      console.error('Error saving seating chart:', error);
      toast.error(t('components.seatingChart.saveError'));
    }
  };

  const addTable = () => {
    const newTable = {
      id: `table-${tables.length + 1}`,
      name: `Table ${tables.length + 1}`,
      guests: []
    };
    setTables([...tables, newTable]);
  };

  const deleteTable = (tableId) => {
    const updatedTables = tables.filter(table => table.id !== tableId);
    setTables(updatedTables);
  };

  const selectGuest = (guest) => {
    setSelectedGuest(guest);
  };

  const assignGuestToTable = (tableId) => {
    if (!selectedGuest) return;

    const newTables = tables.map(table => {
      if (table.id === tableId) {
        return { ...table, guests: [...table.guests, selectedGuest] };
      }
      return table;
    });

    setTables(newTables);
    setSelectedGuest(null);
  };

  const unassignGuest = (guest) => {
    const newTables = tables.map(table => ({
      ...table,
      guests: table.guests.filter(g => g.id !== guest.id)
    }));

    setTables(newTables);
  };

  const getUnassignedGuests = () => {
    const assignedGuestIds = tables.flatMap(table => table.guests.map(guest => guest.id));
    return rsvpData.filter(guest => 
      !assignedGuestIds.includes(guest.id) && 
      (guest.rsvp_status === 'confirmed' || guest.rsvp_status === 'rsvp_yes' || guest.rsvp_status === 'invited')
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="mt-4 px-2 sm:px-0">
      <div className="flex gap-4 mb-4">
        <button 
          onClick={addTable} 
          className="px-3 py-1 text-sm border border-black text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
        >
          {t('components.seatingChart.addTable')}
        </button>
        <button 
          onClick={saveSeatingChart} 
          className="px-3 py-1 text-sm border border-black text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
        >
          {t('components.seatingChart.saveChanges')}
        </button>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="bg-gray-50 p-4 rounded-lg shadow-sm min-w-[200px] md:w-1/4">
          <h3 className="font-bold mb-2">Unassigned Guests</h3>
          <p className="text-sm text-gray-600 mb-2">Only showing guests that are RSVP'd yes</p>
          {getUnassignedGuests().map((guest) => (
            <div
              key={guest.id}
              onClick={() => selectGuest(guest)}
              className={`bg-white p-2 mb-2 rounded-md shadow-sm cursor-pointer transition-colors duration-200 ${selectedGuest?.id === guest.id ? 'border-2 border-gray-800' : 'hover:bg-gray-50'}`}
            >
              {guest.full_name}
            </div>
          ))}
        </div>
        <div className="flex-1">
          <h3 className="font-bold mb-2">Tables</h3>
          <div className="flex flex-wrap gap-4">
            {tables.map((table) => (
              <div key={table.id} className="bg-gray-50 p-4 rounded-lg shadow-sm min-w-[200px]">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold">{table.name}</h3>
                  <button
                    onClick={() => deleteTable(table.id)}
                    className="text-sm text-red-600 hover:text-red-800 focus:outline-none transition-colors duration-200"
                  >
                    Delete
                  </button>
                </div>
                <button
                  onClick={() => assignGuestToTable(table.id)}
                  className="w-full mb-2 px-2 py-1 text-sm border border-black text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                >
                  {t('components.seatingChart.assignGuest')}
                </button>
                {table.guests.map((guest) => {
                  const rsvpGuest = rsvpData.find(rsvp => rsvp.id === guest.id);
                  return (
                    <div
                      key={guest.id}
                      className="bg-white p-2 mb-2 rounded-md shadow-sm flex justify-between items-center"
                    >
                      <span className={rsvpGuest && rsvpGuest.rsvp_status === 'rsvp_no' ? 'text-red-500' : ''}>
                        {guest.full_name}
                        {rsvpGuest && rsvpGuest.rsvp_status === 'rsvp_no' && ' *'}
                      </span>
                      <button
                        onClick={() => unassignGuest(guest)}
                        className="text-sm text-gray-800 hover:text-gray-600 focus:outline-none transition-colors duration-200"
                      >
                        &#x2715;
                      </button>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4 text-sm text-gray-600">
        * {t('components.seatingChart.notAttendingLegend')}
      </div>
    </div>
  );
}
