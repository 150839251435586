import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';


export const handleSignup = async (accessToken) => {
    const supabase = getSupabase();
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser(accessToken);
      if (userError) {
        console.error('Failed to get user from token:', userError);
        toast.error('Invalid token');
        return null;
      }

      let { data: profile, error: profileError } = await supabase
        .from('users')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) {
        if (profileError.code === 'PGRST116') {
          // Profile not found, create a new one
          const currentTime = new Date().toISOString();
          const referral_code = user.user_metadata?.referral_code || localStorage.getItem('pendingReferralCode');
          const newUserData = {
            id: user.id,
            full_name: user.user_metadata?.name || null,
            phone: null,
            address: null,
            // get the referral code used to sign up
            referrer: referral_code || null,
            created_at: currentTime,
            updated_at: currentTime
          };

          const { data: newProfile, error: createError } = await supabase
            .from('users')
            .insert(newUserData)
            .select()
            .single();

          if (createError) {
            console.error('Failed to create profile:', createError);
            // toast.error(`Failed to create profile: ${createError.message}`);
            return null;
          }

          profile = newProfile;
          await supabase.auth.updateUser({
            data: { 
              user_table_created: true,
              referralCode: referral_code || null
            }
          });

          // Remove the referral code from localStorage after using it
          localStorage.removeItem('pendingReferralCode');
        } else {
          console.error('Failed to fetch profile:', profileError);
          toast.error(`Failed to fetch profile: ${profileError.message}`);
          return null;
        }
      }

      const fullProfile = {
        id: user.id,
        email: user.email,
        last_sign_in_at: user.last_sign_in_at,
        ...profile
      };

      console.log('Profile fetched or created successfully:', fullProfile);

      return fullProfile;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error(`Error fetching user profile: ${error.message}`);
      return null;
    }
  };