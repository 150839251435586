import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


const ThankYouLetters = () => {
  const { t } = useTranslation();
  const { id: registryId } = useParams();
  const [thankedGifts, setThankedGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const supabase = getSupabase();

  useEffect(() => {
    fetchThankedGifts();
  }, []);

  const fetchThankedGifts = async () => {
    setIsLoading(true);
    setError(null);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/orders/paid?registry_id=${registryId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(t('components.thankYouLetters.fetchError'));
        }
        const data = await response.json();
        if (data && Array.isArray(data.orders)) {
          const thankedOrders = data.orders.filter(order => order.thanked === 'written' || order.thanked === 'hand_written');
          setThankedGifts(thankedOrders);
        } else {
          console.error(t('components.thankYouLetters.unexpectedDataFormat'), data);
          setError(t('components.thankYouLetters.unexpectedDataFormatError'));
        }
      } catch (error) {
        console.error(t('components.thankYouLetters.fetchErrorLog'), error);
        setError(error.message);
      }
    }
    setIsLoading(false);
  };

  const markAsHandwritten = async (giftId) => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/orders/${giftId}/thank`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            thanked: 'hand_written'
          })
        });
        if (!response.ok) {
          throw new Error(t('components.thankYouLetters.markHandwrittenError'));
        }
        setThankedGifts(prevGifts =>
          prevGifts.map(gift =>
            gift.id === giftId ? { ...gift, thanked: 'hand_written' } : gift
          )
        );
      } catch (error) {
        console.error(t('components.thankYouLetters.markHandwrittenErrorLog'), error);
        toast.error(t('components.thankYouLetters.markHandwrittenErrorToast'));
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="text-center py-8 text-red-600">{t('components.thankYouLetters.error', { error })}</div>;
  }

  const sortedThankedGifts = [...thankedGifts].sort((a, b) => {
    if (a.thanked === 'hand_written' && b.thanked === 'written') return 1;
    if (a.thanked === 'written' && b.thanked === 'hand_written') return -1;
    return 0;
  });

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">{t('components.thankYouLetters.title')}</h2>
      {sortedThankedGifts.length === 0 ? (
        <p className="text-center text-gray-500 italic">{t('components.thankYouLetters.noLetters')}</p>
      ) : (
        <div className="space-y-8">
          {sortedThankedGifts.map((gift) => (
            <div key={gift.id} className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 relative">
              {gift.thanked === 'hand_written' && (
                <span className="absolute top-2 right-2 px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full">
                  {t('components.thankYouLetters.handwritten')}
                </span>
              )}
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-semibold">{t('components.thankYouLetters.to', { name: gift.name || gift.email })}</h3>
                </div>
                {gift.thanked === 'written' && (
                  <button
                    onClick={() => markAsHandwritten(gift.id)}
                    className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
                  >
                    {t('components.thankYouLetters.markHandwritten')}
                  </button>
                )}
              </div>
              <div className="mt-4 p-4 bg-gray-50 rounded-md border border-gray-200">
                <p className="text-lg text-gray-800 whitespace-pre-wrap">{gift.thank_you_letter}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThankYouLetters;