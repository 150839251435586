import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-3xl mx-auto py-8">
      <h1 className="text-3xl font-bold mb-6">{t('components.privacyPolicy.title')}</h1>
      <p className="mb-4">{t('components.privacyPolicy.intro')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.informationCollected')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.informationCollectedDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.useOfInformation')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.useOfInformationDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.dataSharing')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.dataSharingDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.dataSecurity')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.dataSecurityDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.thirdPartyLinks')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.thirdPartyLinksDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.childrenPrivacy')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.childrenPrivacyDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.changesPolicy')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.changesPolicyDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.privacyPolicy.contact')}</h2>
      <p className="mb-4">{t('components.privacyPolicy.contactDescription')}</p>
    </div>
  );
};

export default PrivacyPolicy;