

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { Copy, Link as LinkIcon } from 'lucide-react';
import i18n from '../i18n';
import LoadingAnimation from './LoadingAnimation';


const ReferralsPage = () => {
  const { t } = useTranslation();
  const [referralCode, setReferralCode] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [referralBalance, setReferralBalance] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [customReferralCode, setCustomReferralCode] = useState('');
  const [isCreatingCode, setIsCreatingCode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const supabase = getSupabase();

  useEffect(() => {
    fetchReferralData();
  }, []);

  const fetchReferralData = async () => {
    setIsLoading(true);
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('referral_code')
        .eq('id', user.id)
        .single();

      if (userError) {
        console.error('Error fetching user data:', userError);
        setIsLoading(false);
        return;
      }

      const code = userData.referral_code || '';
      setReferralCode(code);
      setReferralLink(`${window.location.origin}/signup?ref=${code}`);
      setIsCreatingCode(!userData.referral_code);

      const { data, error } = await supabase
        .from('users')
        .select('id, full_name, created_at')
        .eq('referrer', code);

      if (error) {
        console.error('Error fetching referral data:', error);
      } else {
        setReferrals(data);
        //TODO: figure out referral scheme
        const totalBalance = data.length * 0;
        setReferralBalance(totalBalance);
      }
    }
    setIsLoading(false);
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    toast.success(t('components.referralsPage.copiedToClipboard', 'Copied {{type}} to clipboard!', { type }), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleCreateReferralCode = async () => {
    if (!customReferralCode.trim()) {
      toast.error(t('components.referralsPage.enterValidCode', 'Please enter a valid referral code'));
      return;
    }

    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('users')
        .update({ referral_code: customReferralCode })
        .eq('id', user.id)
        .select();

      if (error) {
        console.error('Error updating referral code:', error);
        toast.error(t('components.referralsPage.updateCodeError', 'Failed to update referral code, the code is probably taken'));
      } else {
        setReferralCode(customReferralCode);
        setReferralLink(`${window.location.origin}/signup?ref=${customReferralCode}`);
        setIsCreatingCode(false);
        toast.success(t('components.referralsPage.codeUpdated', 'Referral code updated successfully'));
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-8 text-left text-black">{t('components.referralsPage.title', 'Referrals')}</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="p-6 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <h2 className="text-xl font-semibold mb-4 text-black">{t('components.referralsPage.referralInfo', 'Your Referral Information')}</h2>
          <div className="space-y-4">
            {isLoading ? (
              <LoadingAnimation />
            ) : isCreatingCode ? (
              <div>
                <div className="flex items-center justify-between p-3 bg-gray-100 rounded-md mb-2">
                  <input
                    type="text"
                    value={customReferralCode}
                    onChange={(e) => setCustomReferralCode(e.target.value)}
                    placeholder={t('components.referralsPage.enterCustomCode', 'Enter your custom referral code')}
                    className="flex-grow mr-2 p-2 border rounded"
                  />
                  <button
                    onClick={() => setShowWarning(true)}
                    className="p-2 bg-black text-white rounded hover:bg-gray-800 transition duration-300"
                  >
                    {t('components.referralsPage.create', 'Create')}
                  </button>
                </div>
                {showWarning && (
                  <div className="bg-gray-100 border border-gray-300 rounded-md p-4 mb-4" role="alert">
                    <div className="flex items-center mb-2">
                      <svg className="w-6 h-6 text-yellow-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                      </svg>
                      <p className="font-medium text-gray-800">{t('components.referralsPage.warningTitle', 'Confirmation')}</p>
                    </div>
                    <p className="mt-2 text-gray-600">{t('components.referralsPage.warningMessage', 'This referral code is permanent. Are you sure you want to proceed?')}</p>
                    <div className="mt-4 flex space-x-3">
                      <button
                        onClick={handleCreateReferralCode}
                        className="bg-black hover:bg-gray-800 text-white font-medium py-2 px-4 rounded transition duration-300"
                      >
                        {t('components.referralsPage.confirmButton', 'Confirm')}
                      </button>
                      <button
                        onClick={() => setShowWarning(false)}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded transition duration-300"
                      >
                        {t('components.referralsPage.cancelButton', 'Cancel')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between p-3 bg-gray-100 rounded-md">
                  <div>
                    <p className="text-sm text-black mb-1">{t('components.referralsPage.referralCode', 'Referral Code')}</p>
                    <p className="text-lg font-bold text-black">{referralCode}</p>
                  </div>
                  <button 
                    onClick={() => copyToClipboard(referralCode, 'code')} 
                    className="p-2 bg-black text-white rounded-full hover:bg-gray-800 transition duration-300"
                  >
                    <Copy size={18} />
                  </button>
                </div>
                <div className="flex items-center justify-between p-3 bg-gray-100 rounded-md">
                  <div className="flex-grow mr-4">
                    <p className="text-sm text-black mb-1">{t('components.referralsPage.referralLink', 'Referral Link')}</p>
                    <a href={referralLink} className="text-blue-500 hover:text-blue-700 text-sm break-all">{referralLink}</a>
                  </div>
                  <button 
                    onClick={() => copyToClipboard(referralLink, 'link')} 
                    className="p-2 bg-black text-white rounded-full hover:bg-gray-800 transition duration-300"
                  >
                    <LinkIcon size={18} />
                  </button>
                </div>
              </>
            )}
          </div>
          <p className="mt-4 text-sm text-black">{t('components.referralsPage.shareMessage', 'Share your referral code or link with friends to earn commissions!')}</p>
        </div>

        <div className="p-6 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <h2 className="text-xl font-semibold mb-4 text-black">{t('components.referralsPage.referralBalance', 'Your Referral Balance')}</h2>
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <p className="text-3xl font-bold text-black">
              {new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(referralBalance)}
            </p>
          )}
        </div>
      </div>

      <div className="mt-8 p-6 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
        <h2 className="text-xl font-semibold mb-6 text-black">{t('components.referralsPage.yourReferrals', 'Your Referrals')}</h2>
        {isLoading ? (
          <LoadingAnimation />
        ) : referrals.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {referrals.map((referral) => (
              <div key={referral.id} className="p-4 bg-gray-100 rounded-md">
                <p className="font-semibold text-black">{referral.full_name}</p>
                <p className="text-sm text-black">{t('components.referralsPage.joined', 'Joined')}: {new Date(referral.created_at).toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-black italic">{t('components.referralsPage.noReferrals', 'You haven\'t referred anyone yet.')}</p>
        )}
      </div>

      <div className="mt-8 p-6 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
        <h2 className="text-xl font-semibold mb-4 text-black">{t('components.referralsPage.referralTerms', 'Referral Terms')}</h2>
        <p className="text-sm text-black">
          {t('components.referralsPage.referralTermsDescription', 'Please note that referral fees are only paid out when the referred user successfully hosts their wedding on Metzi and completes a payout. This ensures that our referral program rewards genuine, active users of our platform.')}
        </p>
      </div>
    </div>
  );
};

export default ReferralsPage;