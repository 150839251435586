import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CustomIcon from './CustomIcon';

const BankTransferCheckout = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [error, setError] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const checkTimeoutRef = useRef(null);

  const transferInstructions = location.state?.transferInstructions;
  const email = location.state?.email;

  const checkPayment = async () => {
    if (isChecking) return;
    setIsChecking(true);
    try {
      const response = await fetch(`${API_BASE_URL}/check-payment/${orderId}`);
      const data = await response.json();
      if (data.status === 'confirmed') {
        setPaymentReceived(true);
        toast.success(t('components.bankTransferCheckout.paymentConfirmed', { orderId }));
      } else {
        checkTimeoutRef.current = setTimeout(checkPayment, 30000);
      }
    } catch (error) {
      console.error('Error checking payment:', error);
      setError(t('components.bankTransferCheckout.checkPaymentError'));
    } finally {
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (!transferInstructions) {
      setError(t('components.bankTransferCheckout.instructionsNotFound'));
      return;
    }

    checkPayment();

    return () => {
      if (checkTimeoutRef.current) {
        clearTimeout(checkTimeoutRef.current);
      }
    };
  }, [transferInstructions]);

  if (error) {
    return (
      <div className="text-red-500 text-center mt-8">
        <p>{t('components.bankTransferCheckout.error', { error })}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200"
        >
          {t('components.bankTransferCheckout.refreshPage')}
        </button>
      </div>
    );
  }

  if (!transferInstructions) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow-sm rounded-lg p-6">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">{t('components.bankTransferCheckout.title')}</h1>
        <div className="bg-gray-50 p-4 rounded-md">
          {Object.entries(transferInstructions).map(([key, { value, label }]) => (
            <div key={key} className="mb-2 flex items-center">
              <p className="flex-grow">
                <span className="font-medium">{label}:</span> {value}
              </p>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(value);
                  toast.success(t('components.bankTransferCheckout.copied', { label }));
                }}
                className="ml-2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <CustomIcon d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </button>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <div className="bg-red-50 border-l-4 border-red-500 p-3 mb-4 rounded-r">
            <div className="flex items-center">
              <CustomIcon d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              <p className="ml-2 text-sm font-medium text-red-700">
                {t('components.bankTransferCheckout.referenceWarning')}
              </p>
            </div>
          </div>
          <p className="text-sm text-gray-600 mb-2">
            {t('components.bankTransferCheckout.confirmationEmail', { email })}
          </p>
        </div>
        <div className="flex items-center justify-center mt-6">
          {paymentReceived ? (
            <div className="text-center">
              <CustomIcon d="M5 13l4 4L19 7" />
              <p className="text-lg text-green-600 font-medium mt-4">
                {t('components.bankTransferCheckout.paymentReceived')}
              </p>
              <button
                onClick={() => navigate('/')}
                className="mt-4 px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200"
              >
                {t('components.bankTransferCheckout.returnHome')}
              </button>
            </div>
          ) : (
            <LoadingSpinner loadingText={isChecking ? t('components.bankTransferCheckout.checkingPayment') : t('components.bankTransferCheckout.waitingPayment')} />
          )}
        </div>
        {!paymentReceived && (
          <p className="text-sm text-gray-600 italic mt-4 text-center">
            {t('components.bankTransferCheckout.waitingMessage')}
          </p>
        )}
      </div>
    </div>
  );
};

export default BankTransferCheckout;