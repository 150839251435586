import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import GiftImageGallery from './GiftImageGallery';
import RegistrySettings from './RegistrySettings'
import Modal from './Modal';
import ExpandableDescription from './ExpandableDescription';
import GiftSummary from './GiftSummary';
import i18n from '../i18n';
import * as amplitude from '@amplitude/analytics-browser';

const SpecificRegistry = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [registry, setRegistry] = useState(null);
  const [gifts, setGifts] = useState([]);
  const [newGift, setNewGift] = useState({ name: '', description: '', price: '', quantity: '', image_urls: [] });
  const [editingGift, setEditingGift] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingGift, setIsAddingGift] = useState(false);
  const [isEditingGift, setIsEditingGift] = useState(false);
  const [showAddGiftModal, setShowAddGiftModal] = useState(false);
  const [showEditGiftModal, setShowEditGiftModal] = useState(false);
  const [activeTab, setActiveTab] = useState('gifts');
  const [isUpdatingWithAI, setIsUpdatingWithAI] = useState(false);
  const [aiDescription, setAiDescription] = useState('');
  const supabase = getSupabase();
  const [showAIModal, setShowAIModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [priceFilter, setPriceFilter] = useState('all');
  const [showActionsGroup, setShowActionsGroup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchRegistry(), fetchGifts()]);
        amplitude.track('Registry Viewed', { registryId: id });
      } catch (err) {
        setError(err.message);
        amplitude.track('Error', { type: 'Registry View Error', registryId: id, error: err.message });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const getAuthHeaders = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    return session ? { 'Authorization': `Bearer ${session.access_token}` } : {};
  };

  const fetchRegistry = async () => {
    const headers = await getAuthHeaders();
    const response = await fetch(`${API_BASE_URL}/registry/${id}`, { headers });
    if (!response.ok) throw new Error('Failed to fetch registry');
    const data = await response.json();
    setRegistry(data);
  };

  const fetchGifts = async () => {
    const headers = await getAuthHeaders();
    const response = await fetch(`${API_BASE_URL}/registry/${id}/gift`, { headers });
    if (!response.ok) throw new Error('Failed to fetch gifts');
    const data = await response.json();
    setGifts(data.map(item => item.gifts));
  };

  const handleEditGift = (gift) => {
    setEditingGift(gift);
    setShowEditGiftModal(true);
    amplitude.track('Edit Gift Clicked', { giftId: gift.id });
  };

  const addGift = async (e) => {
    e.preventDefault();
    setIsAddingGift(true);
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registry/${id}/gift`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newGift)
      });
      if (!response.ok) throw new Error('Failed to add gift');
      const addedGift = await response.json();
      setGifts(prevGifts => [...prevGifts, addedGift]);
      setNewGift({ name: '', description: '', price: '', quantity: '', image_urls: [] });
      toast.success(t('components.specificRegistry.giftAddedSuccess'));
      setShowAddGiftModal(false);
      amplitude.track('Gift Added', { registryId: id, giftId: addedGift.id });
    } catch (err) {
      toast.error(err.message);
      amplitude.track('Error', { type: 'Add Gift Error', registryId: id, error: err.message });
    } finally {
      setIsAddingGift(false);
    }
  };

  const editGift = async (e) => {
    e.preventDefault();
    setIsEditingGift(true);
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registry/${id}/gift/${editingGift.id}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editingGift)
      });
      if (!response.ok) throw new Error('Failed to edit gift');
      const updatedGift = await response.json();
      setGifts(prevGifts => prevGifts.map(gift => gift.id === updatedGift.id ? updatedGift : gift));
      toast.success(t('components.specificRegistry.giftEditedSuccess'));
      setShowEditGiftModal(false);
      amplitude.track('Gift Edited', { registryId: id, giftId: updatedGift.id });
    } catch (err) {
      toast.error(err.message);
      amplitude.track('Error', { type: 'Edit Gift Error', registryId: id, giftId: editingGift.id, error: err.message });
    } finally {
      setIsEditingGift(false);
    }
  };

  const removeGift = async (giftId) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registry/${id}/gift/${giftId}`, {
        method: 'DELETE',
        headers
      });
      if (!response.ok) throw new Error('Failed to remove gift');
      setGifts(prevGifts => prevGifts.filter(gift => gift.id !== giftId));
      toast.success(t('components.specificRegistry.giftRemovedSuccess'));
      amplitude.track('Gift Removed', { registryId: id, giftId: giftId });
    } catch (err) {
      toast.error(err.message);
      amplitude.track('Error', { type: 'Remove Gift Error', registryId: id, giftId: giftId, error: err.message });
    }
  };

  const handleUpdateRegistry = async (updatedRegistry) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registry/${id}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedRegistry)
      });
      if (!response.ok) throw new Error('Failed to update registry');
      setRegistry(updatedRegistry);
      toast.success(t('components.specificRegistry.registryUpdatedSuccess'));
      amplitude.track('Registry Updated', { registryId: id });
    } catch (err) {
      toast.error(err.message);
      amplitude.track('Error', { type: 'Update Registry Error', registryId: id, error: err.message });
    }
  };

  const handleUpdateRegistryWithAI = async () => {
    setIsUpdatingWithAI(true);
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registry/${id}/update`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ description: aiDescription })
      });
      if (!response.ok) throw new Error('Failed to update registry with AI');
      const updatedRegistry = await response.json();
      setRegistry(updatedRegistry);
      await fetchGifts();
      toast.success(t('components.specificRegistry.registryUpdatedWithAISuccess'));
      amplitude.track('Registry Updated with AI', { registryId: id });
    } catch (err) {
      toast.error(err.message);
      amplitude.track('Error', { type: 'Update Registry with AI Error', registryId: id, error: err.message });
    } finally {
      setIsUpdatingWithAI(false);
      setAiDescription('');
      setShowAIModal(false); // Close the modal after the API call is completed
    }
  };

  const priceRanges = useMemo(() => {
    if (gifts.length < 4) return []; // Need at least 4 gifts to calculate meaningful ranges

    const prices = gifts.map(gift => parseFloat(gift.price)).sort((a, b) => a - b);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const range = maxPrice - minPrice;

    // Calculate percentiles for better range distribution
    const p25 = prices[Math.floor(prices.length * 0.25)];
    const p75 = prices[Math.floor(prices.length * 0.75)];

    return [
      { label: 'Cheap', min: minPrice, max: p25 },
      { label: 'Medium', min: p25, max: p75 },
      { label: 'Expensive', min: p75, max: maxPrice }
    ];
  }, [gifts]);

  const giftSummary = useMemo(() => {
    return priceRanges.map(range => ({
      ...range,
      count: gifts.filter(gift => 
        parseFloat(gift.price) >= range.min && 
        parseFloat(gift.price) <= range.max
      ).length
    }));
  }, [gifts, priceRanges]);

  const filteredGifts = useMemo(() => {
    if (priceFilter === 'all') return gifts;
    const range = priceRanges.find(r => r.label.toLowerCase() === priceFilter);
    return gifts.filter(gift => 
      parseFloat(gift.price) >= range.min && 
      parseFloat(gift.price) <= range.max
    );
  }, [gifts, priceFilter, priceRanges]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }
  
  if (error) return <div className="text-red-500">{t('components.specificRegistry.error')}: {error}</div>;
  if (!registry) return <div className="text-gray-500">{t('components.specificRegistry.registryNotFound')}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-900">{registry.registry_name}</h2>
        </div>
        <div className="flex items-center space-x-4">
          <p className="text-sm text-gray-500">
            {t('components.specificRegistry.created')}: {new Date(registry.created_at).toLocaleDateString()} - {registry.registry_type}
          </p>

        </div>
      </div>

      

      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <button
              onClick={() => {
                setActiveTab('gifts');
                amplitude.track('Tab Changed', { registryId: id, tab: 'gifts' });
              }}
              className={`${
                activeTab === 'gifts'
                  ? 'border-gray-800 text-gray-800'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
              </svg>
              {t('components.specificRegistry.gifts')}
            </button>
            <button
              onClick={() => {
                setActiveTab('settings');
                amplitude.track('Tab Changed', { registryId: id, tab: 'settings' });
              }}
              className={`${
                activeTab === 'settings'
                  ? 'border-gray-800 text-gray-800'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 flex items-center`}
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              {t('components.specificRegistry.settings')}
            </button>
          </nav>
        </div>
      </div>

      {activeTab === 'gifts' && (
        <>
          <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <h3 className="text-2xl font-semibold mb-4 sm:mb-0">{t('components.specificRegistry.gifts')}</h3>
            <button 
              onClick={() => {
                setShowActionsGroup(!showActionsGroup);
                amplitude.track('Actions Group Toggled', { registryId: id, showActions: !showActionsGroup });
              }}
              className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
              {t('components.specificRegistry.seeActions')}
            </button>
          </div>

          {showActionsGroup && (
            <div className="mb-6 flex flex-wrap gap-2">
              <button 
                onClick={() => {
                  setShowShareModal(true);
                  amplitude.track('Share Modal Opened', { registryId: id });
                }}
                className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                </svg>
                {t('components.specificRegistry.share')}
              </button>
              <Link 
                to={`/gift-catalog/${id}`} 
                className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
                onClick={() => amplitude.track('Browse Gifts Clicked', { registryId: id })}
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                {t('components.specificRegistry.browseGifts')}
              </Link>
              <button
                onClick={() => {
                  setShowAIModal(true);
                  amplitude.track('AI Update Modal Opened', { registryId: id });
                }}
                className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd"></path>
                </svg>
                {t('components.specificRegistry.updateWithAI')}
              </button>
              <button 
                onClick={() => {
                  setShowAddGiftModal(true);
                  amplitude.track('Add Gift Modal Opened', { registryId: id });
                }} 
                className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                {t('components.specificRegistry.addGift')}
              </button>
            </div>
          )}

          <GiftSummary summary={giftSummary} onFilterChange={(filter) => {
            setPriceFilter(filter);
            amplitude.track('Price Filter Changed', { registryId: id, filter });
          }} currentFilter={priceFilter} />

          {showAddGiftModal && (
            <Modal onClose={() => {
              setShowAddGiftModal(false);
              amplitude.track('Add Gift Modal Closed', { registryId: id });
            }}>
              <AddGiftForm
                gift={newGift}
                setGift={setNewGift}
                onSubmit={(gift) => {
                  addGift(gift);
                  amplitude.track('Gift Added', { registryId: id });
                }}
                isLoading={isAddingGift}
                getAuthHeaders={getAuthHeaders}
              />
            </Modal>
          )}

          {showEditGiftModal && (
            <Modal onClose={() => {
              setShowEditGiftModal(false);
              amplitude.track('Edit Gift Modal Closed', { registryId: id });
            }}>
              <AddGiftForm
                gift={editingGift}
                setGift={setEditingGift}
                onSubmit={(gift) => {
                  editGift(gift);
                  amplitude.track('Gift Edited', { registryId: id, giftId: gift.id });
                }}
                isLoading={isEditingGift}
                getAuthHeaders={getAuthHeaders}
                isEditing={true}
              />
            </Modal>
          )}

          <GiftList 
            gifts={filteredGifts} 
            onRemove={(giftId) => {
              removeGift(giftId);
              amplitude.track('Gift Removed', { registryId: id, giftId });
            }} 
            onEdit={(gift) => {
              handleEditGift(gift);
              amplitude.track('Edit Gift Initiated', { registryId: id, giftId: gift.id });
            }}
          />
        </>
      )}

      {showAIModal && (
        <Modal onClose={() => {
          setShowAIModal(false);
          amplitude.track('AI Update Modal Closed', { registryId: id });
        }}>
          <div className="bg-white shadow-sm rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd"></path>
              </svg>
              {t('components.specificRegistry.updateWithAI')}
            </h3>
            <textarea
              value={aiDescription}
              onChange={(e) => setAiDescription(e.target.value)}
              placeholder={t('components.specificRegistry.enterAIDescription')}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 mb-4"
              rows="4"
            />
            <button
              onClick={() => {
                handleUpdateRegistryWithAI();
                amplitude.track('AI Update Initiated', { registryId: id });
              }}
              disabled={isUpdatingWithAI || !aiDescription.trim()}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd"></path>
              </svg>
              {isUpdatingWithAI ? t('components.specificRegistry.updating') : t('components.specificRegistry.updateWithAI')}
            </button>
          </div>
        </Modal>
      )}

      {activeTab === 'settings' && (
        <RegistrySettings
          registry={registry}
          onUpdate={handleUpdateRegistry}
          onDelete={async () => {
              try {
                const headers = await getAuthHeaders();
                const response = await fetch(`${API_BASE_URL}/registry/${registry.id}`, {
                  method: 'DELETE',
                  headers
                });
                if (!response.ok) throw new Error('Failed to delete registry');
                toast.success(t('components.specificRegistry.registryDeletedSuccess'));
                navigate('/registry');
              } catch (err) {
                toast.error(err.message);
              }
          }}
        />
      )}

    {showShareModal && (
        <ShareModal 
          onClose={() => setShowShareModal(false)} 
          registryId={registry.id}
        />
      )}
    </div>
  );
};


const GiftList = ({ gifts, onRemove, onEdit }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-8">
      {gifts.length === 0 ? (
        <p className="text-gray-600">{t('components.specificRegistry.noGifts')}</p>
      ) : (
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {gifts.map((gift) => (
            <GiftCard 
              key={gift.id} 
              gift={gift} 
              onRemove={onRemove} 
              onEdit={onEdit} 
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const GiftCard = ({ gift, onRemove, onEdit }) => {
  const { t } = useTranslation();
  return (
    <li className="bg-white shadow-sm rounded-lg p-4 transition-shadow duration-200 hover:shadow-md flex flex-col h-full">
      <div className="mb-4 flex-shrink-0">
        <GiftImageGallery images={gift.image_urls || []} />
      </div>
      <div className="flex-grow">
        <h4 className="text-lg font-medium text-gray-800 mb-2">{gift.name}</h4>
        <div className="text-sm text-gray-600 mb-2">
          <ExpandableDescription description={gift.description} maxLength={200} />
        </div>
      </div>
      <div className="mt-auto">
        <p className="text-xl font-bold text-gray-700 mb-4">
          {new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(gift.price)}
        </p>
        <div className="flex space-x-2">
          {/* Editing functionality commented out for now
          <button 
            onClick={() => onEdit(gift)} 
            className="flex-1 px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium"
          >
            {t('components.specificRegistry.edit')}
          </button>
          */}
          <button 
            onClick={() => onRemove(gift.id)} 
            className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 text-sm font-medium"
          >
            {t('components.specificRegistry.remove')}
          </button>
        </div>
      </div>
    </li>
  );
};

const AddGiftForm = ({ gift, setGift, onSubmit, isLoading, getAuthHeaders, isEditing = false }) => {
  const { t } = useTranslation();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = React.useRef(null);
  // const [amazonUrl, setAmazonUrl] = useState('');
  // const [isScraping, setIsScraping] = useState(false);
  // const [showAmazonInput, setShowAmazonInput] = useState(false);

  // Use a default empty gift object if gift prop is undefined
  const currentGift = gift || { name: '', description: '', price: '', quantity: '', image_urls: [] };

  const handleImageUpload = async (files) => {
    setIsUploading(true);
    const uploadPromises = Array.from(files).map(async (file) => {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await fetch(`${API_BASE_URL}/upload-image`, {
          method: 'POST',
          headers: await getAuthHeaders(),
          body: formData,
        });
        const data = await response.json();
        if (response.ok) {
          return data.imageUrl;
        } else {
          throw new Error(data.error || 'Failed to upload image');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error(`Failed to upload image: ${error.message}`);
        return null;
      }
    });

    const uploadedUrls = await Promise.all(uploadPromises);
    const validUrls = uploadedUrls.filter(url => url !== null);
    setUploadedImages(prevImages => [...prevImages, ...validUrls]);
    setGift(prevGift => ({ ...prevGift, image_urls: [...prevGift.image_urls || [], ...validUrls] }));
    setIsUploading(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleImageUpload(files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentGift.name.length > 100) {
      toast.error(t('components.specificRegistry.nameTooLong'));
      return;
    }
    onSubmit(e);
    setUploadedImages([]);  // Clear uploaded images after submission
  };

  const handleRemoveExistingImage = (indexToRemove) => {
    setGift(prevGift => ({
      ...prevGift,
      image_urls: prevGift.image_urls.filter((_, index) => index !== indexToRemove)
    }));
    setUploadedImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  };

  // const handleAmazonScrape = async (e) => {
  //   e.preventDefault();
  //   setIsScraping(true);
  //   try {
  //     const response = await fetch(`${API_BASE_URL}/scrape-amazon`, {
  //       method: 'POST',
  //       headers: {
  //         ...(await getAuthHeaders()),
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ url: amazonUrl }),
  //     });
  //     const data = await response.json();
  //     if (response.ok) {
  //       const newGift = {
  //         ...currentGift,
  //         name: (data.title || currentGift.name).slice(0, 100),
  //         description: data.description || currentGift.description,
  //         price: data.price || currentGift.price,
  //         image_urls: data.image_urls || currentGift.image_urls,
  //       };
  //       setGift(newGift);
  //       setUploadedImages(newGift.image_urls || []);
  //       toast.success(t('components.specificRegistry.amazonScrapingSuccess'));
  //       setShowAmazonInput(false);
  //     } else {
  //       throw new Error(data.error || 'Failed to scrape Amazon product');
  //     }
  //   } catch (error) {
  //     console.error('Error scraping Amazon product:', error);
  //     toast.error(`${t('components.specificRegistry.amazonScrapingError')}: ${error.message}`);
  //   } finally {
  //     setIsScraping(false);
  //   }
  // };

  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      <h3 className="text-xl font-semibold mb-4">
        {isEditing ? t('components.specificRegistry.editGift') : t('components.specificRegistry.addNewGift')}
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Amazon URL input and scrape button */}
        {/* <div className="mb-4">
          <button
            type="button"
            onClick={() => setShowAmazonInput(!showAmazonInput)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            {t('components.specificRegistry.addFromAmazon')}
          </button>
          {showAmazonInput && (
            <div className="mt-2 flex space-x-2">
              <input
                type="url"
                placeholder={t('components.specificRegistry.pasteAmazonUrl')}
                value={amazonUrl}
                onChange={(e) => setAmazonUrl(e.target.value)}
                className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
              />
              <button
                type="button"
                onClick={handleAmazonScrape}
                disabled={isScraping || !amazonUrl}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isScraping ? t('components.specificRegistry.scraping') : t('components.specificRegistry.scrape')}
              </button>
            </div>
          )}
        </div> */}
        <input
          type="text"
          placeholder={t('components.specificRegistry.giftName')}
          value={currentGift.name}
          onChange={(e) => setGift({...currentGift, name: e.target.value.slice(0, 100)})}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
          required
          maxLength={100}
        />
        <textarea
          placeholder={t('components.specificRegistry.description')}
          value={currentGift.description}
          onChange={(e) => setGift({...currentGift, description: e.target.value})}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
          required
        />
        <input
          type="number"
          placeholder={t('components.specificRegistry.price')}
          value={currentGift.price}
          onChange={(e) => setGift({...currentGift, price: e.target.value})}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
          required
        />
        {/* <input
          type="number"
          placeholder={t('components.specificRegistry.quantity')}
          value={currentGift.quantity}
          onChange={(e) => setGift({...currentGift, quantity: e.target.value})}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
          required
        /> */}
        
        {/* Existing Images Preview */}
        {(isEditing || uploadedImages.length > 0 || (currentGift.image_urls && currentGift.image_urls.length > 0)) && (
          <div className="mb-4">
            <h4 className="text-lg font-medium mb-2">{t('components.specificRegistry.existingImages')}</h4>
            <div className="grid grid-cols-3 gap-2">
              {(currentGift.image_urls || []).map((url, index) => (
                <div key={index} className="relative">
                  <img src={url} alt={`Existing ${index + 1}`} className="w-full h-24 object-cover rounded-md" />
                  <button
                    type="button"
                    onClick={() => handleRemoveExistingImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
                    title={t('components.specificRegistry.removeImage')}
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Image Upload Area */}
        <div 
          className={`border-2 border-dashed ${isDragging ? 'border-gray-400 bg-gray-50' : 'border-gray-300'} p-4 rounded-md transition-colors duration-200`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files)}
            className="hidden"
            id="image-upload"
            ref={fileInputRef}
          />
          <label htmlFor="image-upload" className="cursor-pointer block text-center">
            <div className="mb-2 text-gray-600">
              {isDragging ? t('components.specificRegistry.dropImagesHere') : t('components.specificRegistry.dragDropImages')}
            </div>
            <span className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200">
              {t('components.specificRegistry.selectImages')}
            </span>
          </label>
        </div>
        {isUploading && (
          <div className="text-center text-gray-600">
            <p>{t('components.specificRegistry.uploadingImages')}</p>
          </div>
        )}
        <button 
          type="submit" 
          className="w-full px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed" 
          disabled={isLoading || isUploading}
        >
          {isLoading ? t('components.specificRegistry.saving') : (isEditing ? t('components.specificRegistry.saveChanges') : t('components.specificRegistry.addGift'))}
        </button>
      </form>
    </div>
  );
};

const ShareModal = ({ onClose, registryId }) => {
  const { t } = useTranslation();
  const shareUrl = `${window.location.origin}/public/registry/${registryId}`;
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(shareUrl)}`);
        setQrCodeUrl(response.url);
      } catch (error) {
        console.error('Error generating QR code:', error);
      } finally {
        setIsLoading(false);
      }
    };
    generateQRCode();
  }, [shareUrl]);

  const copyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    toast.success(t('components.specificRegistry.linkCopied'));
  };

  const shareByEmail = () => {
    window.location.href = `mailto:?subject=${encodeURIComponent(t('components.specificRegistry.shareEmailSubject'))}&body=${encodeURIComponent(shareUrl)}`;
  };

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(t('components.specificRegistry.shareEmailSubject') + ': ' + shareUrl)}`;
    window.open(whatsappUrl, '_blank');
  };

  const downloadQRCode = async () => {
    try {
      const response = await fetch(qrCodeUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'registry-qr-code.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading QR code:', error);
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className="bg-white shadow-lg rounded-xl p-4 sm:p-8 w-full max-w-md mx-auto">
        <h3 className="text-xl sm:text-2xl font-semibold tracking-tight mb-4 sm:mb-6 text-gray-800">{t('components.specificRegistry.shareRegistry')}</h3>
        <div className="space-y-4 sm:space-y-6">
          <div className="relative">
            <input
              type="text"
              value={shareUrl}
              readOnly
              className="w-full px-3 py-2 sm:px-4 sm:py-3 pr-12 bg-gray-100 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            />
            <button
              onClick={copyLink}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 transition-colors duration-200 p-1 sm:p-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
            </button>
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
            <Link
              to={`/public/registry/${registryId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto px-4 py-2 sm:px-6 sm:py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
              </svg>
              {t('components.specificRegistry.preview')}
            </Link>
            <div className="flex space-x-2 mt-2 sm:mt-0">
              <button
                onClick={shareByEmail}
                className="p-2 sm:p-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-200"
                title={t('components.specificRegistry.shareByEmail')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </button>
              <button
                onClick={shareOnWhatsApp}
                className="p-2 sm:p-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-200"
                title={t('components.specificRegistry.shareOnWhatsApp')}
              >
                <svg className="h-4 w-4 sm:h-5 sm:w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M20.11 3.89C17.95 1.73 15.04 0.5 12 0.5C5.49 0.5 0.5 5.49 0.5 12C0.5 14.24 1.12 16.41 2.27 18.27L0.5 23.5L5.73 21.73C7.59 22.88 9.76 23.5 12 23.5C18.51 23.5 23.5 18.51 23.5 12C23.5 8.96 22.27 6.05 20.11 3.89ZM12 21.5C10.01 21.5 8.08 20.93 6.41 19.86L6.05 19.64L2.82 20.66L3.84 17.43L3.62 17.07C2.55 15.4 1.98 13.47 1.98 11.48C1.98 6.57 6.57 1.98 12.48 1.98C15.02 1.98 17.43 3.02 19.27 4.86C21.11 6.7 22.15 9.11 22.15 11.65C22.02 16.56 17.43 21.5 12 21.5ZM17.34 14.64C17.07 14.5 15.61 13.78 15.37 13.68C15.13 13.58 14.95 13.54 14.77 13.81C14.59 14.08 14.01 14.74 13.86 14.92C13.71 15.1 13.56 15.12 13.29 14.98C12.02 14.34 11.19 13.84 10.36 12.39C10.13 11.97 10.63 12 11.1 11.06C11.2 10.88 11.16 10.72 11.1 10.58C11.04 10.44 10.55 8.98 10.33 8.44C10.11 7.9 9.89 7.98 9.74 7.98C9.59 7.98 9.41 7.98 9.23 7.98C9.05 7.98 8.77 8.04 8.53 8.31C8.29 8.58 7.53 9.3 7.53 10.76C7.53 12.22 8.59 13.63 8.74 13.81C8.89 13.99 10.55 16.47 13.01 17.74C14.91 18.65 15.5 18.71 16.25 18.57C16.71 18.49 17.89 17.85 18.11 17.21C18.33 16.57 18.33 16.03 18.27 15.93C18.21 15.83 18.03 15.77 17.76 15.65L17.34 14.64Z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-4 sm:mt-6 flex flex-col items-center">
            <h4 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4">{t('components.specificRegistry.qrCode')}</h4>
            <div className="bg-white p-3 sm:p-4 rounded-lg shadow-md mb-3 sm:mb-4 w-36 h-36 sm:w-48 sm:h-48 flex items-center justify-center">
              {isLoading ? (
                <div className="animate-spin rounded-full h-8 w-8 sm:h-12 sm:w-12 border-b-2 border-gray-900"></div>
              ) : (
                <img src={qrCodeUrl} alt="QR Code" className="w-full h-full" />
              )}
            </div>
            <button
              onClick={downloadQRCode}
              className="inline-flex items-center justify-center px-4 py-2 sm:px-6 sm:py-3 border border-transparent text-sm sm:text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
              {t('components.specificRegistry.downloadQRCode')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SpecificRegistry;

