

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const GiftImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const { t } = useTranslation();

  const modalRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen) {
        if (event.key === 'ArrowLeft') {
          handlePrevImage();
        } else if (event.key === 'ArrowRight') {
          handleNextImage();
        } else if (event.key === 'Escape') {
          handleCloseModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);

  if (!images || images.length === 0) {
    return <div className="text-gray-500">{t('components.giftImageGallery.noImages')}</div>;
  }

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextImage();
    } else if (isRightSwipe) {
      handlePrevImage();
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div 
        className="relative w-full h-64 cursor-pointer" 
        onClick={handleImageClick}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <img 
          src={images[currentImageIndex]} 
          alt={t('components.specificRegistry.giftImageGallery.imageAlt', { index: currentImageIndex + 1 })}
          className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
        />
        {images.length > 1 && (
          <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full text-sm">
            {currentImageIndex + 1} / {images.length}
          </div>
        )}
      </div>

      {isModalOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          ref={modalRef}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <div className="max-w-4xl w-full mx-4">
            <div className="relative w-full h-[calc(100vh-8rem)]">
              <img 
                src={images[currentImageIndex]} 
                alt={t('components.giftImageGallery.imageAlt', { index: currentImageIndex + 1 })}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="absolute top-0 right-0 m-4">
              <button onClick={handleCloseModal} className="text-white text-2xl">&times;</button>
            </div>
            {images.length > 1 && (
              <>
                <button onClick={handlePrevImage} className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl">&lsaquo;</button>
                <button onClick={handleNextImage} className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl">&rsaquo;</button>
              </>
            )}
          </div>
        </div>
      )}

      {images.length > 1 && (
        <div className="flex justify-center space-x-2 mt-2 pb-2">
          {images.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full ${
                index === currentImageIndex ? 'bg-gray-800' : 'bg-gray-300'
              }`}
              onClick={() => setCurrentImageIndex(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GiftImageGallery;