import React from 'react';

const CustomIcon = ({ className }) => (
  <svg className={className} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1-7.5-16.5" strokeWidth="2" />
  </svg>
);

const LoadingSpinner = ({ loadingText = "Loading..." }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="animate-spin mb-4" style={{ animationDuration: '3s' }}>
        <CustomIcon className="w-24 h-24 text-gray-700" />
      </div>
      <p className="text-lg font-medium text-gray-700 animate-pulse" style={{ animationDuration: '2s' }}>{loadingText}</p>
    </div>
  );
};

export default LoadingSpinner;