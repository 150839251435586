import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getSupabase } from '../config/supabase';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip, Legend);

export function GuestBreakdown({ weddingId, guests: initialGuests }) {
  const [guests, setGuests] = useState(initialGuests || []);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { t } = useTranslation();
  const supabase = getSupabase();

  useEffect(() => {
    if (!initialGuests || initialGuests.length === 0) {
      fetchGuests();
    }
  }, [initialGuests]);

  const fetchGuests = async () => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .select('*')
        .eq('wedding_id', weddingId);

      if (error) throw error;
      setGuests(data);
    } catch (error) {
      console.error('Error fetching guests:', error);
      toast.error('Failed to fetch guests');
    }
  };

  const filteredGuests = selectedStatus
    ? guests.filter(guest => guest.rsvp_status === selectedStatus)
    : guests;

  const categoryBreakdown = filteredGuests.reduce((acc, guest) => {
    const category = guest.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = { total: 0 };
    }
    acc[category].total += 1;
    if (!acc[category][guest.rsvp_status]) {
      acc[category][guest.rsvp_status] = 0;
    }
    acc[category][guest.rsvp_status] += 1;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(categoryBreakdown),
    datasets: [
      {
        data: Object.values(categoryBreakdown).map(c => c.total),
        backgroundColor: [
          '#8ECAE6', '#219EBC', '#023047', '#FFB703', '#FB8500',
          '#9B2226', '#AE2012', '#BB3E03'
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 20,
          font: { size: 12 },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const categoryData = categoryBreakdown[label];
            const { total, ...statuses } = categoryData;
            const percentage = ((total / filteredGuests.length) * 100).toFixed(1);
            const statusLabels = Object.entries(statuses).map(([status, count]) => `${status}: ${count}`);
            return [`${label}: ${total} (${percentage}%)`, ...statusLabels];
          },
        },
      },
    },
  };

  const rsvpStatuses = [...new Set(guests.map(guest => guest.rsvp_status))];

  const handleStatusClick = (status) => {
    setSelectedStatus(status === selectedStatus ? null : status);
  };

  return (
    <div className="mt-8 bg-white p-6 rounded-lg shadow-sm">
      <h4 className="text-lg font-medium mb-6 text-gray-700">Guest Breakdown</h4>
      <div className="mb-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        <div 
          className={`bg-gray-50 p-4 rounded-lg text-center cursor-pointer ${selectedStatus === null ? 'ring-2 ring-blue-500' : ''}`}
          onClick={() => handleStatusClick(null)}
        >
          <p className="text-2xl font-semibold text-gray-800">{guests.length}</p>
          <p className="text-xs text-gray-600">Total Guests</p>
        </div>
        {rsvpStatuses.map(status => {
          const count = guests.filter(guest => guest.rsvp_status === status).length;
          return (
            <div 
              key={status} 
              className={`bg-gray-50 p-4 rounded-lg text-center cursor-pointer ${selectedStatus === status ? 'ring-2 ring-blue-500' : ''}`}
              onClick={() => handleStatusClick(status)}
            >
              <p className="text-2xl font-semibold text-gray-800">{count}</p>
              <p className="text-xs text-gray-600">{t(`components.rsvpList.${status}`)}</p>
            </div>
          );
        })}
      </div>
      <div style={{ height: '400px' }}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}