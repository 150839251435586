import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';

const RegistryOrders = () => {
  const { t } = useTranslation();
  const { id: registryId } = useParams();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const supabase = getSupabase();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setIsLoading(true);
    setError(null);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/orders?registry_id=${registryId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(t('registryOrders.fetchError'));
        }
        const data = await response.json();
        if (data && Array.isArray(data.orders)) {
          setOrders(data.orders);
        } else {
          console.error(t('registryOrders.unexpectedDataFormat'), data);
          setError(t('registryOrders.unexpectedDataFormatError'));
        }
      } catch (error) {
        console.error(t('registryOrders.fetchErrorLog'), error);
        setError(error.message);
        toast.error(t('registryOrders.fetchErrorToast'));
      }
    }
    setIsLoading(false);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'bg-yellow-200 text-yellow-800';
      case 'paid':
        return 'bg-green-200 text-green-800';
      case 'cancelled':
        return 'bg-red-200 text-red-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="text-center py-8 text-red-600">{t('registryOrders.error', { error })}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">{t('components.registryOrders.title')}</h2>
      {orders.length === 0 ? (
        <p className="text-center text-gray-500 italic">{t('components.registryOrders.noOrders')}</p>
      ) : (
        <div className="space-y-4">
          {orders.map((order) => (
            <div key={order.id} className="bg-gray-50 p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold text-gray-800">{t('components.registryOrders.orderNumber', { id: order.id })}</h3>
                <span className={`px-2 py-1 rounded-full text-sm font-medium ${getStatusColor(order.status)}`}>
                  {order.status}
                </span>
              </div>
              <div className="mb-2">
                <p className="text-sm text-gray-600">{t('components.registryOrders.totalAmount', { amount: order.total_amount.toFixed(2) })}</p>
                <p className="text-sm text-gray-600">{t('components.registryOrders.orderDate', { date: new Date(order.created_at).toLocaleString() })}</p>
              </div>
              <div className="mb-2">
                <h4 className="text-sm font-semibold text-gray-700 mb-1">{t('components.registryOrders.items')}:</h4>
                <ul className="list-disc list-inside text-sm text-gray-600">
                  {order.cart && order.cart.map((item, index) => (
                    <li key={index}>{t('components.registryOrders.itemDetails', { name: item.name, price: item.price.toFixed(2) })}</li>
                  ))}
                </ul>
              </div>
              <button
                onClick={() => toggleOrderExpansion(order.id)}
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
              >
                {expandedOrder === order.id ? t('components.registryOrders.hideDetails') : t('components.registryOrders.showDetails')}
              </button>
              {expandedOrder === order.id && (
                <div className="mt-4 bg-white p-4 rounded-md">
                  <h4 className="font-semibold mb-2">{t('components.registryOrders.fullOrderDetails')}:</h4>
                  <pre className="text-xs overflow-x-auto">
                    {JSON.stringify(order, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RegistryOrders;