import { createClient } from '@supabase/supabase-js';

export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || 'https://vnposeyctrrdyvicqscz.supabase.co'
export const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZucG9zZXljdHJyZHl2aWNxc2N6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI0NTExMTQsImV4cCI6MjAzODAyNzExNH0.1XrNATT0hreD_q0TkVP97wTENXpm0P9D6oav311Tx5s'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:5000';

if (!SUPABASE_URL || !SUPABASE_KEY) {
  throw new Error('Missing Supabase URL or Key. Please check your environment variables.');
}

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

export const getSupabase = () => supabase;