import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ModernMinimalistTemplate from '../components/WebsiteTemplate';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import Logo from './Logo';

const WeddingPage = () => {
  const [weddingData, setWeddingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorDogImage, setErrorDogImage] = useState(null);
  const { t } = useTranslation();
  
  useEffect(() => {
    const fetchWeddingData = async () => {
      const supabase = getSupabase();
      const link = window.location.pathname.split('/').pop();

      if (!link) {
        setError('No wedding link provided in the URL.');
        setLoading(false);
        return;
      }

      try {
        const { data: websiteInfo, error } = await supabase
          .from('website_information')
          .select('details')
          .eq('link', link)
          .single();

        if (error) throw error;

        if (!websiteInfo) {
          setError('Wedding not found');
          const dogResponse = await fetch('https://dog.ceo/api/breeds/image/random');
          const dogData = await dogResponse.json();
          setErrorDogImage(dogData.message);
          setLoading(false);
          return;
        }

        const parsedWebsiteInfo = JSON.parse(websiteInfo.details);
        document.title = parsedWebsiteInfo.weddingName;
        setWeddingData(parsedWebsiteInfo);
        setLoading(false);
        
      } catch (err) {
        console.error('Error fetching wedding data:', err);
        setError('Wedding not found');
        const dogResponse = await fetch('https://dog.ceo/api/breeds/image/random');
        const dogData = await dogResponse.json();
        setErrorDogImage(dogData.message);
        setLoading(false);
      }
    };

    fetchWeddingData();
  }, []);
   

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error || !weddingData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <div className="text-center mb-8">
          <div className="flex justify-center mb-6 transform scale-150">
            <Logo />
          </div>
          <h1 className="text-4xl font-bold text-gray-800 mb-4">{t('components.landingPage.oops')}</h1>
          <p className="text-xl text-gray-600 mb-8">{t('components.landingPage.weddingNotFound')}</p>
        </div>
        {errorDogImage && (
          <img src={errorDogImage} alt="Error Dog" className="w-64 h-64 object-cover rounded-lg shadow-md mb-8" />
        )}
        <Link
          to="/"
          className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
        >
          {t('components.landingPage.goHome')}
        </Link>
      </div>
    );
  }

  return <ModernMinimalistTemplate data={weddingData} />;
};

export default WeddingPage;