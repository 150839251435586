import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const Logo = ({ isWhite = true }) => (
    <div className="flex items-center justify-center md:justify-start">
      <svg className={`w-8 h-8 ${isWhite ? 'text-white' : 'text-gray-700'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1-7.5-16.5" strokeWidth="2" />
      </svg>
      <div className="flex items-center ml-2">
        <span className={`text-xl font-semibold ${isWhite ? 'text-white' : 'text-gray-800'}`}>{t('app.title')}</span>
        <span className={`ml-2 px-2 py-1 text-xs font-semibold ${isWhite ? 'text-gray-200 bg-gray-700' : 'text-gray-600 bg-gray-200'} rounded-full`}>Beta</span>
      </div>
    </div>
  );

  return (
    <footer className="bg-gradient-to-b from-gray-800 to-gray-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pb-16">
          <div className="flex flex-col items-center md:items-start space-y-4">
            <Link to="/" className="flex items-center">
              <Logo isWhite={true} />
            </Link>
            <nav className="mt-4">
              <ul className="flex space-x-4 justify-center md:justify-start">
                <li>
                  <a href="https://www.instagram.com/usametzi/" className="text-gray-300 hover:text-white transition-colors duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/usametzi/" className="text-gray-300 hover:text-white transition-colors duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="flex flex-col items-center md:items-start space-y-4">
            <h2 className="text-xl font-semibold">{t('app.footer.quickLinks')}</h2>
            <nav>
              <ul className="space-y-2 text-center md:text-left">
                <li>
                  <Link to="/about" className="text-gray-300 hover:text-white transition-colors duration-200">
                    {t('app.footer.about')}
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" className="text-gray-300 hover:text-white transition-colors duration-200">
                    {t('app.footer.pricing', 'Pricing')}
                  </Link>
                </li>
                <li>
                  <a href="https://wa.me/16504415812" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors duration-200 flex items-center justify-center md:justify-start">
                    {t('app.footer.contact')}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="flex flex-col items-center md:items-start space-y-4">
            <h2 className="text-xl font-semibold">{t('app.footer.legal')}</h2>
            <nav>
              <ul className="space-y-2 text-center md:text-left">
                <li>
                  <Link to="/privacy" className="text-gray-300 hover:text-white transition-colors duration-200">
                    {t('app.footer.privacy')}
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-300 hover:text-white transition-colors duration-200">
                    {t('app.footer.terms')}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="text-sm text-gray-300 text-center">
          <p>&copy; {new Date().getFullYear()} Metzi</p>
          <p className="mt-2 text-xs md:text-sm">{t('app.footer.description')}</p>
          <a href="https://julianserra.xyz" className="hidden">Julian Serra</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
