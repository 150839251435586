import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';

const AdminGiftManagement = () => {
  const { t } = useTranslation();
  const [gifts, setGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingGift, setEditingGift] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [priceFilter, setPriceFilter] = useState({ min: '', max: '' });
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const supabase = getSupabase();

  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    fetchGifts();
  }, [searchTerm, priceFilter, sortBy, sortOrder]);

  const getAuthHeaders = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    return session ? { 'Authorization': `Bearer ${session.access_token}` } : {};
  };

  const fetchGifts = async (pageNumber = 1) => {
    if (isLoading) return;
    
    try {
      setIsLoading(true);
      const headers = await getAuthHeaders();
      const url = new URL(`${API_BASE_URL}/gifts`);
      url.searchParams.append('page', pageNumber);
      url.searchParams.append('limit', ITEMS_PER_PAGE);
      url.searchParams.append('search', searchTerm);
      url.searchParams.append('minPrice', priceFilter.min);
      url.searchParams.append('maxPrice', priceFilter.max);
      url.searchParams.append('sortBy', sortBy);
      url.searchParams.append('sortOrder', sortOrder);
      
      const response = await fetch(url, { headers });
      if (!response.ok) throw new Error('Failed to fetch gifts');
      const data = await response.json();
      setGifts(prevGifts => pageNumber === 1 ? data.gifts : [...prevGifts, ...data.gifts]);
      setHasMore(data.has_more);
      setPage(pageNumber);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditGift = (gift) => {
    setEditingGift({ ...gift });
  };

  const handleSaveGift = async () => {
    try {
      setIsLoading(true);
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/gifts/${editingGift.id}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editingGift)
      });
      if (!response.ok) throw new Error('Failed to update gift');
      toast.success(t('admin.giftUpdated'));
      fetchGifts(1);
      setEditingGift(null);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGift = async (giftId) => {
    if (window.confirm(t('admin.confirmDelete'))) {
      try {
        setIsLoading(true);
        const headers = await getAuthHeaders();
        const response = await fetch(`${API_BASE_URL}/gifts/${giftId}`, {
          method: 'DELETE',
          headers
        });
        if (!response.ok) throw new Error('Failed to delete gift');
        toast.success(t('admin.giftDeleted'));
        fetchGifts(1);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      fetchGifts(page + 1);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePriceFilter = (e) => {
    setPriceFilter({ ...priceFilter, [e.target.name]: e.target.value });
    setPage(1);
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
    setPage(1);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Gift Management</h2>
      
      <div className="mb-4 flex flex-wrap items-center space-x-4">
        <input
          type="text"
          placeholder="Search gifts..."
          value={searchTerm}
          onChange={handleSearch}
          className="px-3 py-2 border border-gray-300 rounded-md"
        />
        <input
          type="number"
          placeholder="Min price"
          name="min"
          value={priceFilter.min}
          onChange={handlePriceFilter}
          className="px-3 py-2 border border-gray-300 rounded-md"
        />
        <input
          type="number"
          placeholder="Max price"
          name="max"
          value={priceFilter.max}
          onChange={handlePriceFilter}
          className="px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('name')}>
                Name {sortBy === 'name' && (sortOrder === 'asc' ? '▲' : '▼')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('price')}>
                Price {sortBy === 'price' && (sortOrder === 'asc' ? '▲' : '▼')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {gifts.map((gift) => (
              <tr key={gift.id}>
                <td className="px-6 py-4 whitespace-nowrap">{gift.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">${gift.price}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button 
                    onClick={() => handleEditGift(gift)}
                    className="text-indigo-600 hover:text-indigo-900 mr-2"
                  >
                    Edit
                  </button>
                  <button 
                    onClick={() => handleDeleteGift(gift.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && hasMore && (
        <div className="mt-6 text-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium"
          >
            Load More
          </button>
        </div>
      )}
      {editingGift && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Edit Gift</h3>
            <input
              type="text"
              value={editingGift.name}
              onChange={(e) => setEditingGift({ ...editingGift, name: e.target.value })}
              className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Name"
            />
            <input
              type="number"
              value={editingGift.price}
              onChange={(e) => setEditingGift({ ...editingGift, price: parseFloat(e.target.value) })}
              className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Price"
            />
            <textarea
              value={editingGift.description}
              onChange={(e) => setEditingGift({ ...editingGift, description: e.target.value })}
              className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Description"
            />
            <div className="mt-4">
              <button 
                onClick={handleSaveGift} 
                className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save
              </button>
              <button 
                onClick={() => setEditingGift(null)} 
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminGiftManagement;