import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSupabase } from '../config/supabase';
import { RSVPList } from './RSVPList';
import { Trash2, Edit2 } from 'lucide-react';
import { Select } from './Select'; // Assume we have a custom Select component

export function InviteGuests({ weddingId, onGuestAdded }) {
  const [guest, setGuest] = useState({ full_name: '', email: '', cellphone: '', category: '' });
  const [invitedGuests, setInvitedGuests] = useState([]);
  const [editingGuest, setEditingGuest] = useState(null);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const { t } = useTranslation();
  const supabase = getSupabase();
  const formRef = useRef(null);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddGuestForm, setShowAddGuestForm] = useState(false);
  const [activeCategory, setActiveCategory] = useState('All');
  const [eventType, setEventType] = useState('reception');
  const [eventTypes, setEventTypes] = useState(['reception', 'civil_wedding', 'bridal_shower']);

  useEffect(() => {
    fetchInvitedGuests();
  }, [weddingId, eventType]); // Add eventType as a dependency

  const fetchInvitedGuests = async () => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .select('*')
        .eq('wedding_id', weddingId)
        .eq('event_type', eventType);

      if (error) throw error;
      setInvitedGuests(data);
      updateCategories(data);
    } catch (error) {
      console.error('Error fetching invited guests:', error);
      toast.error(t('components.inviteGuests.fetchError', 'Failed to fetch guests'));
    }
  };

  const updateCategories = (guests) => {
    const uniqueCategories = [...new Set(guests.map(guest => guest.category).filter(Boolean))];
    setCategories(prevCategories => [...new Set(['Bride', 'Groom', 'All', ...uniqueCategories])]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGuest(prevGuest => ({ ...prevGuest, [name]: value }));
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    if (value === 'add_new') {
      setShowAddCategory(true);
    } else {
      setGuest(prevGuest => ({ ...prevGuest, category: value }));
    }
  };

  const handleAddCategory = () => {
    if (newCategory.trim() !== '' && !categories.includes(newCategory.trim())) {
      setCategories(prevCategories => [...new Set([...prevCategories, newCategory.trim()])]);
      setNewCategory('');
      setShowAddCategory(false);
    }
  };

  const inviteGuest = async (e) => {
    e.preventDefault();
    
    if (!guest.full_name || !guest.email || !guest.category) {
      toast.error(t('components.inviteGuests.noValidGuests', 'Please fill in all required fields'));
      return;
    }

    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .insert({
          ...guest,
          wedding_id: weddingId,
          event_type: eventType,
          rsvp_status: 'to_be_invited',
          invitation_sent_date: new Date().toISOString(),
        });

      if (error) throw error;

      toast.success(t('components.inviteGuests.inviteSuccess', 'Guest invited successfully'));
      if (data && Array.isArray(data)) {
        onGuestAdded(data[0]);
      }
      setGuest({ full_name: '', email: '', cellphone: '', category: '' });
      fetchInvitedGuests();
    } catch (error) {
      console.error('Error inviting guest:', error);
      toast.error(t('components.inviteGuests.inviteError', 'Failed to invite guest'));
    }
  };

  const cancelEditing = () => {
    setEditingGuest(null);
    setGuest({ full_name: '', email: '', cellphone: '', category: '' });
  };

  const updateGuest = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('wedding_rsvps')
        .update(guest)
        .eq('id', editingGuest.id);

      if (error) throw error;

      toast.success(t('components.inviteGuests.updateSuccess', 'Guest updated successfully'));
      setEditingGuest(null);
      setGuest({ full_name: '', email: '', cellphone: '', category: '' });
      fetchInvitedGuests();
    } catch (error) {
      console.error('Error updating guest:', error);
      toast.error(t('components.inviteGuests.updateError', 'Failed to update guest'));
    }
  };

  const getCategoryStats = (category) => {
    let categoryGuests;
    if (category === 'Others') {
      categoryGuests = invitedGuests.filter(g => g.category === category || !g.category);
    } else if (category === 'All') {
      categoryGuests = invitedGuests;
    } else {
      categoryGuests = invitedGuests.filter(g => g.category === category);
    }
    const invited = categoryGuests.filter(g => g.rsvp_status === 'invited').length;
    const confirmed = categoryGuests.filter(g => g.rsvp_status === 'confirmed').length;
    const declined = categoryGuests.filter(g => g.rsvp_status === 'declined').length;
    return { total: categoryGuests.length, invited, confirmed, declined };
  };

  const filteredGuests = useMemo(() => {
    if (activeCategory === 'All') return invitedGuests;
    return invitedGuests.filter(guest => 
      activeCategory === 'Others' 
        ? guest.category === 'Others' || !guest.category
        : guest.category === activeCategory
    );
  }, [invitedGuests, activeCategory]);

  const categoryTotals = useMemo(() => {
    return categories.reduce((acc, category) => {
      const stats = getCategoryStats(category);
      acc[category] = stats.total;
      return acc;
    }, {});
  }, [categories, invitedGuests]);

  const sortedCategories = useMemo(() => 
    ['All', ...new Set(categories.filter(c => c !== 'All').sort((a, b) => categoryTotals[b] - categoryTotals[a])), 'Others'],
    [categories, categoryTotals]
  );

  return (
    <div className="mt-4 px-2 sm:px-4 md:px-6 lg:px-8">
      <div className="mt-8 flex justify-between items-center">
        <h4 className="text-lg font-semibold">{t('components.inviteGuests.invitedGuestsList', 'Invited Guests List')}</h4>
        <div className="flex items-center">
          {/* <Select
            options={eventTypes.map(type => ({ value: type, label: type.replace('_', ' ') }))}
            value={eventType}
            onChange={(value) => {
              setEventType(value);
              fetchInvitedGuests(); // Fetch guests for the new event type
            }}
            className="mr-4"
          /> */}
          <button
            onClick={() => setShowAddGuestForm(!showAddGuestForm)}
            className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
          >
            {showAddGuestForm ? t('components.inviteGuests.hideForm', 'Hide Form') : t('components.inviteGuests.showForm', 'Add Guest')}
          </button>
        </div>
      </div>

      {showAddGuestForm && (
        <form ref={formRef} onSubmit={editingGuest ? updateGuest : inviteGuest} className="space-y-4 mt-4">
          <div className="border p-4 rounded-md">
            <div className="mb-3">
              <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                {t('components.inviteGuests.fullName')}
              </label>
              <input
                type="text"
                name="full_name"
                id="full_name"
                value={guest.full_name}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('components.inviteGuests.email')}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={guest.email}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="cellphone" className="block text-sm font-medium text-gray-700">
                {t('components.inviteGuests.cellphone')}
              </label>
              <input
                type="tel"
                name="cellphone"
                id="cellphone"
                value={guest.cellphone}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                {t('components.inviteGuests.category', 'Category')}
              </label>
              <select
                name="category"
                id="category"
                value={guest.category}
                onChange={handleCategoryChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
                required
              >
                <option value="">{t('components.inviteGuests.selectCategory', 'Select a category')}</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
                <option value="add_new">{t('components.inviteGuests.addNewCategory', 'Add new category')}</option>
              </select>
            </div>
            {showAddCategory && (
              <div className="mb-3">
                <label htmlFor="newCategory" className="block text-sm font-medium text-gray-700">
                  {t('components.inviteGuests.newCategory', 'New Category')}
                </label>
                <div className="flex">
                  <input
                    type="text"
                    id="newCategory"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
                    placeholder={t('components.inviteGuests.newCategoryPlaceholder', 'Enter new category')}
                  />
                  <button
                    type="button"
                    onClick={handleAddCategory}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                  >
                    {t('components.inviteGuests.addCategoryButton', 'Add')}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col sm:flex-row justify-between">
            <button
              type="submit"
              className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
            >
              {editingGuest ? t('components.inviteGuests.updateButton') : t('components.inviteGuests.inviteButton')}
            </button>
            {editingGuest && (
              <button
                type="button"
                onClick={cancelEditing}
                className="w-full sm:w-auto py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
              >
                {t('components.inviteGuests.cancelButton')}
              </button>
            )}
          </div>
        </form>
      )}

      <div className="mt-4">
        <div className="mb-4">
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {sortedCategories.map((category) => {
                const stats = getCategoryStats(category);
                return (
                  <button
                    key={category}
                    onClick={() => setActiveCategory(category)}
                    className={`${
                      activeCategory === category
                        ? 'bg-gray-100 text-gray-700'
                        : 'text-gray-500 hover:text-gray-700'
                    } px-3 py-2 font-medium text-sm rounded-md`}
                  >
                    {`${category} (${stats.total})`}
                  </button>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{activeCategory}</h3>
          </div>
          <RSVPList 
            rsvpData={filteredGuests}
            categories={categories}
            key={`${activeCategory}-${filteredGuests.length}`} // Update this line
          />
        </div>
        {invitedGuests.length === 0 && (
          <p className="text-sm text-gray-500">{t('components.inviteGuests.noInvitedGuests', 'No invited guests yet')}</p>
        )}
      </div>
    </div>
  );
}