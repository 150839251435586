import React from 'react';
import { useTranslation } from 'react-i18next';

const AppearanceConfig = ({ websiteData, handleInputChange }) => {
  const { t } = useTranslation();

  const fontOptions = [
    'Montserrat', 'Roboto', 'Open Sans', 'Lato', 'Poppins', 'Playfair Display', 'Merriweather'
  ];

  const colorSchemes = [
    { name: 'Classic', primary: '#333333', secondary: '#ffffff', accent: '#f0f0f0', background: '#ffffff' },
    { name: 'Rustic', primary: '#5d4037', secondary: '#d7ccc8', accent: '#a1887f', background: '#efebe9' },
    { name: 'Romantic', primary: '#d81b60', secondary: '#fce4ec', accent: '#f8bbd0', background: '#ffffff' },
    { name: 'Modern', primary: '#212121', secondary: '#fafafa', accent: '#bdbdbd', background: '#ffffff' },
    { name: 'Nature', primary: '#2e7d32', secondary: '#f1f8e9', accent: '#c8e6c9', background: '#ffffff' },
  ];

  const handleCustomColorChange = (colorType, value) => {
    handleInputChange({
      target: {
        name: 'colors',
        value: {
          ...websiteData.colors,
          [colorType]: value,
          name: 'Custom'
        }
      }
    });
  };

  return (
    <div className="space-y-4">
      {/* Font Selection */}
      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Font Selection</h3>
        <select
          name="main"
          value={websiteData.fonts.main}
          onChange={(e) => handleInputChange(e, 'fonts')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          {fontOptions.map((font) => (
            <option key={font} value={font}>{font}</option>
          ))}
        </select>
      </div>

      {/* Color Scheme Selection */}
      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Color Scheme</h3>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {colorSchemes.map((scheme) => (
            <button
              key={scheme.name}
              onClick={() => {
                handleInputChange({ target: { name: 'colors', value: scheme } });
              }}
              className={`p-2 rounded-md ${
                websiteData.colors.name === scheme.name ? 'ring-2 ring-gray-500' : ''
              }`}
              style={{
                backgroundColor: scheme.background,
                color: scheme.primary,
                border: `1px solid ${scheme.accent}`,
              }}
            >
              {scheme.name}
            </button>
          ))}
        </div>

        {/* Custom Color Scheme */}
        <div className="mt-4">
          <h4 className="text-md font-medium mb-2">Custom Color Scheme</h4>
          <div className="grid grid-cols-2 gap-4">
            {['primary', 'secondary', 'accent', 'background'].map((colorType) => (
              <div key={colorType}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {colorType.charAt(0).toUpperCase() + colorType.slice(1)}
                </label>
                <input
                  type="color"
                  value={websiteData.colors[colorType]}
                  onChange={(e) => handleCustomColorChange(colorType, e.target.value)}
                  className="w-full h-10 p-1 rounded-md border border-gray-300"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppearanceConfig;