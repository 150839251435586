import React from 'react'
import { useTranslation } from 'react-i18next'

const Logo = ({ isWhite = false }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <svg className={`w-8 h-8 ${isWhite ? 'text-white' : 'text-gray-700'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1-7.5-16.5" strokeWidth="2" />
      </svg>
      <div className="flex items-center ml-2">
        <span className={`text-xl font-semibold ${isWhite ? 'text-white' : 'text-gray-800'}`}>{t('app.title')}</span>
        <span className={`ml-2 px-2 py-1 text-xs font-semibold ${isWhite ? 'text-gray-200 bg-gray-700' : 'text-gray-600 bg-gray-200'} rounded-full`}>Beta</span>
      </div>
    </div>
  );
};

export default Logo; // Export the Logo component