import React from 'react';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-3xl mx-auto py-8">
      <h1 className="text-3xl font-bold mb-6">{t('components.terms.title')}</h1>
      <p className="mb-4">{t('components.terms.intro')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.useOfServices')}</h2>
      <p className="mb-4">{t('components.terms.useOfServicesDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.intellectualProperty')}</h2>
      <p className="mb-4">{t('components.terms.intellectualPropertyDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.userContent')}</h2>
      <p className="mb-4">{t('components.terms.userContentDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.thirdPartyLinks')}</h2>
      <p className="mb-4">{t('components.terms.thirdPartyLinksDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.disclaimerOfWarranties')}</h2>
      <p className="mb-4">{t('components.terms.disclaimerOfWarrantiesDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.limitationOfLiability')}</h2>
      <p className="mb-4">{t('components.terms.limitationOfLiabilityDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.indemnification')}</h2>
      <p className="mb-4">{t('components.terms.indemnificationDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.terminationOfServices')}</h2>
      <p className="mb-4">{t('components.terms.terminationOfServicesDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.governingLaw')}</h2>
      <p className="mb-4">{t('components.terms.governingLawDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.changesTerms')}</h2>
      <p className="mb-4">{t('components.terms.changesTermsDescription')}</p>

      <h2 className="text-xl font-bold mb-2">{t('components.terms.contact')}</h2>
      <p className="mb-4">{t('components.terms.contactDescription')}</p>
    </div>
  );
};

export default Terms;