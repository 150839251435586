import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, getSupabase } from '../config/supabase';
import ModernMinimalistTemplate from './WebsiteTemplate';
import WebsiteConfig from './WebsiteConfig';
import AppearanceConfig from './WebsiteAppearanceConfig';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import * as amplitude from '@amplitude/analytics-browser';


const WebsiteBuilder = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('config');
  const [websiteData, setWebsiteData] = useState({
    coupleName: { partner1: '', partner2: '' },
    heroSubtitle: 'Are tying the knot!',
    weddingDate: '',
    weddingTime: '4:30 PM',
    heroImage: 'https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
    eventDetails: {
      ceremony: { date: '', time: '4:30 PM', venue: 'Church Venue', address: 'CDMX' },
      reception: { date: '', time: '6:00 PM', venue: 'Dance Venue', address: 'Naucalpan' },
    },
    story: { 
      title: 'Our Story', 
      content: 'Our love story begins...', 
      photos: [
        'https://images.unsplash.com/photo-1522673607200-164d1b6ce486?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80',
        'https://images.unsplash.com/photo-1529634597503-139d3726fed5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1769&q=80',
        'https://images.unsplash.com/photo-1519741497674-611481863552?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80'
      ]
    },
    registry: { message: 'We appreciate your generosity.', registries: [] },
    contactEmail: '',
    colors: { primary: '#333333', secondary: '#ffffff', background: '#ffffff', accent: '#f8f8f8' },
    fonts: { main: 'Montserrat' },
    qa: [
      { question: 'What is the dress code?', answer: 'The dress code is semi-formal.' },
      { question: 'Can I bring a plus one?', answer: 'Please refer to your invitation for details about guests.' },
      { question: 'Will there be parking available?', answer: 'Yes, there will be ample parking at the venue.' }
    ],
    showRsvp: true
  });
  const supabase = getSupabase();
  const [websiteLink, setWebsiteLink] = useState('');
  const [weddingId, setWeddingId] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchWeddingDetails();
  }, []);

  const fetchWeddingDetails = async () => {
    setIsLoading(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        // Fetch from wedding_details endpoint
        const response = await fetch(`${API_BASE_URL}/wedding-details`, {
          headers: { 'Authorization': `Bearer ${session.access_token}` }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch wedding details');
        }
        const data = await response.json();

        // Fetch from Supabase directly
        let { data: websiteInfo, error } = await supabase
          .from('website_information')
          .select('details, link')
          .eq('wedding_information_id', data.id)
          .single();

        if (error) {
          if (error.code === 'PGRST116') {
            // No rows returned, initialize websiteInfo with empty details
            websiteInfo = { details: '{}', link: '' };
          } else {
            console.error('Error fetching website information:', error);
            throw error;
          }
        }

        const parsedWebsiteInfo = websiteInfo ? JSON.parse(websiteInfo.details) : {};

        setWebsiteData(prevData => ({
          ...prevData,
          ...parsedWebsiteInfo,
          weddingId: data.id,
          coupleName: { 
            partner1: data.person1_name, 
            partner2: data.person2_name 
          },
          weddingDate: data.wedding_date,
          weddingName: data.wedding_name,
          eventDetails: {
            ...prevData.eventDetails,
            ...parsedWebsiteInfo.eventDetails,
            ceremony: {
              ...prevData.eventDetails.ceremony,
              ...parsedWebsiteInfo.eventDetails?.ceremony,
              date: data.wedding_date
            },
            reception: {
              ...prevData.eventDetails.reception,
              ...parsedWebsiteInfo.eventDetails?.reception,
              date: data.wedding_date
            }
          }
        }));
        setWeddingId(data.id);
        setWebsiteLink(websiteInfo.link || '');
      } catch (error) {
        console.error('Error fetching wedding details:', error);
        toast.error(t('components.weddingSettings.fetchError'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const saveWebsiteData = async () => {
    setIsSaving(true);
    try {
      const { data, error } = await supabase
        .from('website_information')
        .upsert({ 
          wedding_information_id: weddingId,
          details: JSON.stringify(websiteData),
          link: websiteLink
        }, {
          onConflict: 'wedding_information_id',
          update: ['details', 'link']
        });

      if (error) {
        console.error('Error saving website data:', error);
        toast.error(t('components.websiteBuilder.saveError'));
      } else {
        console.log('Website data saved successfully');
        toast.success(t('components.websiteBuilder.saveSuccess'));
      }
    } catch (error) {
      console.error('Unexpected error saving website data:', error);
      toast.error(t('components.websiteBuilder.unexpectedError'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e, section, index, field) => {
    const { name, value } = e.target;
    setWebsiteData((prevData) => {
      if (section === 'qa') {
        const newQa = [...prevData.qa];
        newQa[index] = { ...newQa[index], [field]: value };
        return { ...prevData, qa: newQa };
      } else if (section && index !== undefined) {
        return {
          ...prevData,
          [section]: prevData[section].map((item, i) =>
            i === index ? { ...item, [field || name]: value } : item
          ),
        };
      } else if (section) {
        return {
          ...prevData,
          [section]: {
            ...prevData[section],
            [name]: value,
          },
        };
      } else {
        return { ...prevData, [name]: value };
      }
    });
  };

  const handleAddQA = () => {
    setWebsiteData((prevData) => ({
      ...prevData,
      qa: [...(prevData.qa || []), { question: '', answer: '' }],
    }));
  };

  const handleRemoveQA = (index) => {
    setWebsiteData((prevData) => ({
      ...prevData,
      qa: prevData.qa.filter((_, i) => i !== index),
    }));
  };

  const handleRegistryChange = (index, field, value) => {
    setWebsiteData((prevData) => {
      const newRegistries = [...prevData.registry.registries];
      newRegistries[index] = { ...newRegistries[index], [field]: value };
      return {
        ...prevData,
        registry: {
          ...prevData.registry,
          registries: newRegistries,
        },
      };
    });
  };

  const handleAddRegistry = () => {
    setWebsiteData((prevData) => ({
      ...prevData,
      registry: {
        ...prevData.registry,
        registries: [...prevData.registry.registries, { name: '', url: '' }],
      },
    }));
  };

  const handleRemoveRegistry = (index) => {
    setWebsiteData((prevData) => ({
      ...prevData,
      registry: {
        ...prevData.registry,
        registries: prevData.registry.registries.filter((_, i) => i !== index),
      },
    }));
  };

  const handleShareLink = () => {
    const linkToShare = `${window.location.origin}/wedding/${websiteLink}`;
    navigator.clipboard.writeText(linkToShare);
    toast.success('Link copied to clipboard!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const tabs = [
    { id: 'config', label: t('components.websiteBuilder.configure'), icon: 'M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z' },
    { id: 'appearance', label: t('components.websiteBuilder.appearance'), icon: 'M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01' },
    { id: 'share', label: t('components.websiteBuilder.share'), icon: 'M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z' },
    { id: 'preview', label: t('components.websiteBuilder.preview'), icon: 'M15 12a3 3 0 11-6 0 3 3 0 016 0z M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z' },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">{t('components.websiteBuilder.title')}</h2>
      
      <div className="mb-6 overflow-x-auto">
        <div className="border-b border-gray-200 whitespace-nowrap">
          <nav className="-mb-px flex">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`${
                  activeTab === tab.id
                    ? 'border-gray-800 text-gray-800'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center mr-8`}
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={tab.icon} />
                </svg>
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="overflow-y-auto max-h-[calc(100vh-200px)] -webkit-overflow-scrolling-touch">
        {isLoading ? (
          <div className="flex items-center justify-center min-h-screen">
            <LoadingSpinner />
          </div>
        ) : activeTab === 'preview' ? (
          <div className="border p-4 rounded">
              <ModernMinimalistTemplate data={websiteData} />       
          </div>
        ) : activeTab === 'appearance' ? (
            <AppearanceConfig
              websiteData={websiteData}
              handleInputChange={handleInputChange}
            />
          ) : activeTab === 'share' ? (
          <div className="bg-white shadow-sm rounded-lg p-4 sm:p-6">
            <h3 className="text-xl font-semibold mb-4">{t('components.websiteBuilder.yourWebsiteLink')}</h3>
            <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
              <div className="w-full sm:flex-grow flex items-center bg-gray-100 border border-gray-300 rounded-md">
                <span className="px-2 py-2 bg-gray-200 text-gray-600 rounded-l-md hidden sm:inline">{window.location.origin}/wedding/</span>
                <input
                  type="text"
                  value={websiteLink}
                  onChange={(e) => setWebsiteLink(e.target.value)}
                  className="w-full sm:flex-grow p-2 bg-gray-100 focus:outline-none"
                />
              </div>
              <button
                onClick={handleShareLink}
                className="w-full sm:w-auto p-2 text-gray-600 hover:text-gray-800 focus:outline-none border border-gray-300 rounded-md"
                title={t('components.websiteBuilder.copyLink')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
              </button>
            </div>
            <a
              href={`${window.location.origin}/wedding/${websiteLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full sm:inline-block sm:w-auto text-center text-blue-600 hover:text-blue-800 border border-blue-600 rounded-md px-4 py-2"
            >
              {t('components.websiteBuilder.visitWebsite')}
            </a>
          </div>
        ) : (
            <WebsiteConfig
            websiteData={websiteData}
            handleInputChange={handleInputChange}
            setWebsiteData={setWebsiteData}
            handleRegistryChange={handleRegistryChange}
            handleAddRegistry={handleAddRegistry}
            handleRemoveRegistry={handleRemoveRegistry}
            handleAddQA={handleAddQA}
            handleRemoveQA={handleRemoveQA}
          />
        )}
      </div>

      <button
        onClick={saveWebsiteData}
        className="w-full mt-6 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
        disabled={isSaving}
      >
        {isSaving ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {t('components.websiteBuilder.saving')}
          </span>
        ) : (
          t('components.websiteBuilder.saveChanges')
        )}
      </button>
    </div>
  );
};

export default WebsiteBuilder;