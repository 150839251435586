import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import CustomIcon from './CustomIcon';

const ReceivedGifts = () => {
  const { t } = useTranslation();
  const { id: registryId } = useParams();
  const [gifts, setGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGift, setSelectedGift] = useState(null);
  const [thankYouLetter, setThankYouLetter] = useState('');
  const [isGeneratingLetter, setIsGeneratingLetter] = useState(false);
  const supabase = getSupabase();

  useEffect(() => {
    fetchGifts();
  }, []);

  const fetchGifts = async () => {
    setIsLoading(true);
    setError(null);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/orders/paid?registry_id=${registryId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(t('components.receivedGifts.fetchError'));
        }
        const data = await response.json();
        if (data && Array.isArray(data.orders)) {
          const sortedGifts = data.orders.sort((a, b) => {
            if (a.thanked === 'not_written' && b.thanked !== 'not_written') return -1;
            if (b.thanked === 'not_written' && a.thanked !== 'not_written') return 1;
            return new Date(b.created_at) - new Date(a.created_at);
          });
          setGifts(sortedGifts);
        } else {
          console.error(t('components.receivedGifts.unexpectedDataFormat'), data);
          setError(t('components.receivedGifts.unexpectedDataFormatError'));
        }
      } catch (error) {
        console.error(t('components.receivedGifts.fetchErrorLog'), error);
        setError(error.message);
        toast.error(t('components.receivedGifts.fetchErrorToast'));
      }
    }
    setIsLoading(false);
  };

  const getPillLabel = (thankedStatus) => {
    switch (thankedStatus) {
      case "written":
        return t('components.receivedGifts.thankedWritten');
      case "hand_written":
        return t('components.receivedGifts.thankedHandWritten');
      default:
        return t('components.receivedGifts.unthanked');
    }
  };

  const openThankYouModal = (gift) => {
    setSelectedGift(gift);
    setThankYouLetter(gift.thank_you_letter || '');
    setModalOpen(true);
  };

  const closeThankYouModal = () => {
    setSelectedGift(null);
    setThankYouLetter('');
    setModalOpen(false);
  };

  const submitThankYou = async (e) => {
    e.preventDefault();
    const { data: { session } } = await supabase.auth.getSession();
    if (session && selectedGift) {
      try {
        const response = await fetch(`${API_BASE_URL}/orders/${selectedGift.id}/thank`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            thank_you_letter: thankYouLetter,
            thanked: "written"
          })
        });
        if (!response.ok) {
          throw new Error(t('components.receivedGifts.thankYouError'));
        }
        toast.success(t('components.receivedGifts.thankYouSuccess'));
        fetchGifts();
        closeThankYouModal();
      } catch (error) {
        console.error(t('components.receivedGifts.thankYouErrorLog'), error);
        toast.error(t('components.receivedGifts.thankYouErrorToast'));
      }
    }
  };

  const generateThankYouLetter = async () => {
    setIsGeneratingLetter(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session && selectedGift) {
      try {
        const response = await fetch(`${API_BASE_URL}/generate-thank-you/${selectedGift.id}/${i18n.language}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(t('components.receivedGifts.generateLetterError'));
        }
        const data = await response.json();
        setThankYouLetter(data.thank_you_letter);
      } catch (error) {
        console.error(t('components.receivedGifts.generateLetterErrorLog'), error);
        toast.error(t('components.receivedGifts.generateLetterErrorToast'));
      } finally {
        setIsGeneratingLetter(false);
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="text-center py-8 text-red-600">{t('components.receivedGifts.error', { error })}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col mb-6 space-y-4">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800">{t('components.receivedGifts.title')}</h2>
        <Link to={`/registry/${registryId}/letters`} className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 w-full justify-center">
          <CustomIcon d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          <span className="ml-2 text-sm">{t('components.receivedGifts.handwrittenLetterManagement')}</span>
        </Link>
      </div>
      {gifts.length === 0 ? (
        <p className="text-center text-gray-500 italic">{t('components.receivedGifts.noGifts')}</p>
      ) : (
        <div className="space-y-4">
          {gifts.map((gift) => (
            <div key={gift.id} className={`bg-white p-4 sm:p-6 rounded-md shadow-sm ${gift.thanked !== 'not_written'} relative`}>
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4">
                <h3 className="text-base sm:text-lg font-semibold text-gray-900">{t('components.receivedGifts.from', { name: gift.name || gift.email || t('components.receivedGifts.anonymousGiver') })}</h3>
                <div className={`mt-2 sm:mt-0 px-2 py-1 rounded-full text-xs font-medium ${
                  gift.thanked === 'written' ? 'bg-green-100 text-green-800' :
                  gift.thanked === 'hand_written' ? 'bg-blue-100 text-blue-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {getPillLabel(gift.thanked)}
                </div>
              </div>
              <p className="text-xs sm:text-sm text-gray-600 mb-4">{t('components.receivedGifts.date', { date: new Date(gift.created_at).toLocaleString() })}</p>
              <div className="mb-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">{t('components.receivedGifts.gifts')}:</h4>
                <ul className="list-disc list-inside text-xs sm:text-sm text-gray-600">
                  {gift.cart && gift.cart.map((item, index) => (
                    <li key={index}>{t('components.receivedGifts.giftDetails', { name: item.name, quantity: item.quantity })}</li>
                  ))}
                </ul>
              </div>
              {gift.message && (
                <div className="mt-4 p-3 sm:p-4 bg-gray-50 rounded-md">
                  <h4 className="text-xs sm:text-sm font-medium text-gray-700 mb-2">{t('components.receivedGifts.message')}:</h4>
                  <p className="text-xs sm:text-sm text-gray-600 italic">"{gift.message}"</p>
                </div>
              )}
              {(gift.thanked === 'not_written' || gift.thanked === null) && (
                <button
                  onClick={() => openThankYouModal(gift)}
                  className="mt-4 px-4 py-2 bg-gray-800 text-white text-sm rounded-md hover:bg-gray-700 transition-colors duration-200 w-full"
                >
                  {t('components.receivedGifts.sayThankYou')}
                </button>
              )}
              {gift.thanked !== 'not_written' && gift.thank_you_letter && (
                <div className="mt-4 p-3 sm:p-4 bg-gray-50 rounded-md">
                  <h4 className="text-xs sm:text-sm font-medium text-gray-700 mb-2">{t('components.receivedGifts.thankYouLetter')}:</h4>
                  <p className="text-xs sm:text-sm text-gray-600">{gift.thank_you_letter}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {modalOpen && selectedGift && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-xl max-h-[90vh] overflow-y-auto">
            <h3 className="text-lg sm:text-xl font-semibold mb-4">{t('components.receivedGifts.writeThankYou')}</h3>
            <div className="mb-4">
              <p className="text-xs sm:text-sm text-gray-700"><strong>{t('components.receivedGifts.from', { name: selectedGift.name || selectedGift.email || t('components.receivedGifts.anonymousGiver') })}</strong></p>
              <p className="text-xs sm:text-sm text-gray-700 mt-2"><strong>{t('components.receivedGifts.gifts')}:</strong></p>
              <ul className="list-disc list-inside text-xs sm:text-sm text-gray-600 ml-4">
                {selectedGift.cart && selectedGift.cart.map((item, index) => (
                  <li key={index}>{t('components.receivedGifts.giftDetails', { name: item.name, quantity: item.quantity })}</li>
                ))}
              </ul>
            </div>
            <form onSubmit={submitThankYou}>
              <div className="relative">
                <textarea
                  value={thankYouLetter}
                  onChange={(e) => setThankYouLetter(e.target.value)}
                  className="w-full px-3 py-2 text-sm text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                  rows="4"
                  placeholder={t('components.receivedGifts.thankYouPlaceholder')}
                ></textarea>
                <button
                  type="button"
                  onClick={generateThankYouLetter}
                  disabled={isGeneratingLetter}
                  className="mt-2 p-2 text-gray-500 hover:text-gray-700 transition-colors duration-200 disabled:opacity-50 flex items-center"
                  title={t('components.receivedGifts.generateAI')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd" />
                  </svg>
                  <span className="text-xs sm:text-sm">{t('components.receivedGifts.generateAI')}</span>
                </button>
              </div>
              <div className="mt-4 flex flex-col space-y-2">
                <button
                  type="button"
                  onClick={closeThankYouModal}
                  className="w-full px-4 py-2 text-sm text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
                >
                  {t('components.receivedGifts.cancel')}
                </button>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-sm bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
                >
                  {t('components.receivedGifts.send')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceivedGifts;