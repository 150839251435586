import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getSupabase } from '../config/supabase';

const OAuthCallback = ({ setSession }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const supabase = getSupabase();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const referralCode = params.get('referral_code');

        // Get the session
        const { data, error } = await supabase.auth.getSession();
        if (error) throw error;

        if (data.session) {
          setSession(data.session);

          // If there's a referral code, update the user's metadata
          if (referralCode) {
            const { error: updateError } = await supabase.auth.updateUser({
              data: { referral_code: referralCode }
            });
            if (updateError) throw updateError;
          }

          // Redirect to home page or dashboard
          navigate('/home');
        }
      } catch (error) {
        console.error('Error in OAuth callback:', error);
        setError(error.message);
      }
    };

    handleCallback();
  }, [location, navigate, setSession, supabase.auth]);

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-8 bg-white shadow-lg rounded-xl">
          <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Oops!</h2>
            <p className="mt-2 text-sm text-gray-600">An error occurred during sign-up</p>
          </div>
          <div className="mt-8 text-center">
            <p className="text-red-600">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white shadow-lg rounded-xl">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Welcome!</h2>
          <p className="mt-2 text-sm text-gray-600">We're processing your sign-up</p>
        </div>
        <div className="mt-8 flex justify-center">
          <svg className="animate-spin h-10 w-10 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default OAuthCallback;