import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';

const AdminPayoutManagement = () => {
  const { t } = useTranslation();
  const [payouts, setPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingPayout, setEditingPayout] = useState(null);
  const [payoutInfo, setPayoutInfo] = useState({});
  const [selectedPayoutInfo, setSelectedPayoutInfo] = useState(null);
  const supabase = getSupabase();

  useEffect(() => {
    fetchPayouts();
  }, []);

  const fetchPayouts = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('payouts')
        .select('*, users(full_name)')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPayouts(data);

      // Fetch payout info for each payout
      const payoutInfoPromises = data.map(payout => 
        supabase.from('payout_information').select('*').eq('id', payout.payout_information_id).single()
      );
      const payoutInfoResults = await Promise.all(payoutInfoPromises);
      const payoutInfoMap = {};
      payoutInfoResults.forEach((result, index) => {
        if (!result.error) {
          payoutInfoMap[data[index].id] = result.data;
        }
      });
      setPayoutInfo(payoutInfoMap);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPayout = (payout) => {
    setEditingPayout({ ...payout });
  };

  const handleSavePayout = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('payouts')
        .update({
          amount: editingPayout.amount,
          fee: editingPayout.fee,
          status: editingPayout.status
        })
        .eq('id', editingPayout.id);

      if (error) throw error;
      toast.success(t('admin.payoutUpdated'));
      fetchPayouts();
      setEditingPayout(null);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayoutAction = async (payoutId, action) => {
    setIsLoading(true);
    try {
      const newStatus = action === 'complete' ? 'completed' : 'failed';
      const { data, error } = await supabase
        .from('payouts')
        .update({ status: newStatus })
        .eq('id', payoutId);

      if (error) throw error;
      toast.success(t(`admin.payout${action.charAt(0).toUpperCase() + action.slice(1)}ed`));
      fetchPayouts();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewPayoutInfo = (payoutId) => {
    setSelectedPayoutInfo(payoutInfo[payoutId]);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Payout Management</h2>
      
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fee</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payout Info</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {payouts.map((payout) => (
              <tr key={payout.id}>
                <td className="px-6 py-4 whitespace-nowrap">{payout.users.full_name}</td>
                <td className="px-6 py-4 whitespace-nowrap">${payout.amount}</td>
                <td className="px-6 py-4 whitespace-nowrap">${payout.fee}</td>
                <td className="px-6 py-4 whitespace-nowrap">{payout.status}</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(payout.created_at).toLocaleString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleViewPayoutInfo(payout.id)}
                    className="text-blue-600 hover:text-blue-900"
                  >
                    View Payout Info
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button 
                    onClick={() => handleEditPayout(payout)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                    Edit Payout
                  </button>
                  {payout.status === 'pending' && (
                    <>
                      <button 
                        onClick={() => handlePayoutAction(payout.id, 'complete')}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        Mark as Paid
                      </button>
                      <button 
                        onClick={() => handlePayoutAction(payout.id, 'fail')}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Mark as Failed
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isLoading && <LoadingSpinner />}
      {editingPayout && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Edit Payout</h3>
            <input
              type="number"
              value={editingPayout.amount}
              onChange={(e) => setEditingPayout({ ...editingPayout, amount: parseFloat(e.target.value) })}
              className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Amount"
            />
            <input
              type="number"
              value={editingPayout.fee}
              onChange={(e) => setEditingPayout({ ...editingPayout, fee: parseFloat(e.target.value) })}
              className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Fee"
            />
            <select
              value={editingPayout.status}
              onChange={(e) => setEditingPayout({ ...editingPayout, status: e.target.value })}
              className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
            <div className="mt-4">
              <button 
                onClick={handleSavePayout} 
                className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save
              </button>
              <button 
                onClick={() => setEditingPayout(null)} 
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedPayoutInfo && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Payout Information</h3>
            <div className="space-y-2 max-h-96 overflow-y-auto">
              {Object.entries(selectedPayoutInfo).map(([key, value]) => (
                <div key={key} className="mb-2">
                  <span className="font-medium text-gray-700">{key}:</span>
                  <div className="ml-2 mt-1 p-2 bg-gray-100 rounded-md overflow-x-auto">
                    {typeof value === 'object' ? (
                      <pre className="text-sm">{JSON.stringify(value, null, 2)}</pre>
                    ) : (
                      <span>{value}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <button 
                onClick={() => setSelectedPayoutInfo(null)} 
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
                 
export default AdminPayoutManagement;