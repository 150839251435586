import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import WeddingDetailsModal from './WeddingDetailsModal';
import CustomIcon from './CustomIcon';
import { Church, Gift, Globe, CreditCard, Heart, Mail } from 'lucide-react';
import { handleSignup } from '../utils/utils';
import * as amplitude from '@amplitude/analytics-browser';

const HomePage = () => {
  const { t } = useTranslation();
  const [weddingDetails, setWeddingDetails] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [registries, setRegistries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalShownThisSession, setModalShownThisSession] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});
  const [onboardingSteps, setOnboardingSteps] = useState([
    { id: 1, title: t('components.homePage.addWeddingInfo'), completed: false, link: '/profile?tab=weddingSettings', icon: <Church size={24} /> },
    { id: 2, title: t('components.homePage.createRegistry'), completed: false, link: '/registry?create=true', icon: <Gift size={24} /> },
    { id: 3, title: t('components.homePage.buildWebsite'), completed: false, link: '/website-builder', icon: <Globe size={24} /> },
    { id: 4, title: t('components.homePage.inviteGuests'), completed: false, link: '/guests', icon: <Mail size={24} /> },
    { id: 5, title: t('components.homePage.setPayoutInfo'), completed: false, link: '/user/balance?tab=payoutInfo', icon: <CreditCard size={24} /> },
    { id: 6, title: t('components.homePage.shareAndGetMarried'), completed: false, link: '', icon: <Heart size={24} /> },
  ]);
  const [onboardingLoaded, setOnboardingLoaded] = useState(false);
  const supabase = getSupabase();
  const navigate = useNavigate();

 
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      // HANDLE USER CREATION
      const { user } = session;
      if (!user.user_metadata.user_table_created) {
        await handleSignup(session.access_token)
        await supabase.auth.updateUser({
          data: { user_table_created: true }
        });
      }
    }
    if (session) {
      const [weddingData, registriesData, payoutInfoData] = await Promise.all([
        supabase
          .from('wedding_information')
          .select(`
            id,
            wedding_name,
            user_id,
            wedding_date,
            wedding_location,
            image_url,
            users (
              full_name
            ),
            website_information (
              link
            ),
            wedding_rsvps (
            id
            )
          `)
          .eq('user_id', session.user.id)
          .single(),
        supabase
          .from('registries')
          .select('id, registry_name')
          .eq('user_id', session.user.id), 
        supabase
          .from('payout_information')
          .select('id, payout_method, currency, deleted')
          .eq('user_id', session.user.id)
      ]);


      if (weddingData.error || registriesData.error || (payoutInfoData.error && payoutInfoData.error.code !== 'PGRST116')) {
        console.error('Error fetching data:', weddingData.error || registriesData.error || payoutInfoData.error);
        setIsLoading(false);
        setOnboardingLoaded(true);
        if(weddingData.error && weddingData.error.code === 'PGRST116'){
          setShowEditModal(true)
        }
        amplitude.track('Error', { 
          type: weddingData.error ? 'Wedding Data Fetch Error' : registriesData.error ? 'Registries Data Fetch Error' : 'Payout Info Data Fetch Error',
          details: weddingData.error || registriesData.error || payoutInfoData.error
        });
        return;
      }
      
     
      const { data } = weddingData;

      if (data) {
        console.log(data)
        setWeddingDetails({
          id: data.wedding_id,
          wedding_name: data.wedding_name,
          wedding_date: data.wedding_date,
          wedding_location: data.wedding_location,
          image_url: data.image_url,
          website_link: data.website_information?.link
        });

        setProfileData({
          full_name: data.users?.user_full_name
        });
      }

      setRegistries(registriesData.data || []);

      // Update onboarding steps
      updateOnboardingSteps(data, registriesData.data, payoutInfoData.data);

      setIsLoading(false);
      amplitude.track('Home Page Data Loaded', {
        hasWeddingDetails: !!data,
        registriesCount: registriesData.data?.length || 0,
        hasPayoutInfo: payoutInfoData.data?.length > 0
      });
    }
  }, [supabase]);

  const updateOnboardingSteps = (data, registriesData, payoutInfoData) => {
    setOnboardingSteps(prevSteps => {
      const updatedSteps = prevSteps.map(step => {
        if (step.id === 1) return { ...step, completed: !!data?.wedding_name };
        if (step.id === 2) return { ...step, completed: registriesData && registriesData.length > 0 };
        if (step.id === 3) return { ...step, completed: !!data?.website_information?.link };
        if (step.id === 4) return { ...step, completed: data?.wedding_rsvps && data.wedding_rsvps.length > 0 };
        if (step.id === 5) return { ...step, completed: payoutInfoData && payoutInfoData.length > 0 };
        if (step.id === 6) return { ...step, completed: false, link: `/wedding/${data?.website_information?.link || ''}` };
        return step;
      });
      setOnboardingLoaded(true);
      amplitude.track('Onboarding Steps Updated', {
        completedSteps: updatedSteps.filter(step => step.completed).length
      });
      return updatedSteps;
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (weddingDetails && weddingDetails.wedding_date) {
      const initialTimeLeft = calculateTimeLeft(weddingDetails.wedding_date);
      setTimeLeft(initialTimeLeft);

      const timer = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
          const newTimeLeft = { ...prevTimeLeft };
          if (newTimeLeft.seconds > 0) {
            newTimeLeft.seconds--;
          } else {
            newTimeLeft.seconds = 59;
            if (newTimeLeft.minutes > 0) {
              newTimeLeft.minutes--;
            } else {
              newTimeLeft.minutes = 59;
              if (newTimeLeft.hours > 0) {
                newTimeLeft.hours--;
              } else {
                newTimeLeft.hours = 23;
                if (newTimeLeft.days > 0) {
                  newTimeLeft.days--;
                }
              }
            }
          }
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [weddingDetails]);


  const calculateTimeLeft = useCallback((weddingDate) => {
    const difference = +new Date(weddingDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }, []);

  const handleEditDetails = useCallback(() => {
    setShowEditModal(true);
    amplitude.track('Edit Wedding Details Clicked');
  }, []);

  const handleSaveDetails = useCallback(async (updatedDetails) => {
    setWeddingDetails(updatedDetails);
    setShowEditModal(false);
    setOnboardingSteps(prevSteps => prevSteps.map(step => 
      step.id === 1 ? { ...step, completed: true } : step
    ));
    amplitude.track('Wedding Details Saved', {
      hasWeddingName: !!updatedDetails.wedding_name,
      hasWeddingDate: !!updatedDetails.wedding_date,
      hasWeddingLocation: !!updatedDetails.wedding_location
    });
  }, []);

  const renderCountdown = useMemo(() => {
    if (!weddingDetails || !weddingDetails.wedding_date) return null;
    return (
      <div className="bg-white shadow-sm rounded-md px-4 md:px-6 py-3 md:py-4">
        <div className="flex space-x-3 md:space-x-6">
          {Object.entries(timeLeft).map(([interval, value]) => (
            <div key={interval} className="text-center">
              <span className="text-xl md:text-3xl font-bold">{value}</span>
              <p className="text-xs md:text-sm uppercase">{interval}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }, [weddingDetails, timeLeft]);

  const renderOnboardingChecklist = () => {
    if (!onboardingLoaded) return null;

    const completedSteps = onboardingSteps.filter(step => step.completed).length;
    const progress = (completedSteps / onboardingSteps.length) * 100;

    // Get the first 3 incomplete steps, or all steps if none are completed
    const relevantSteps = completedSteps === 0 
      ? onboardingSteps.slice(0, 3) 
      : onboardingSteps.filter(step => !step.completed).slice(0, 3);

    return (
      <div className="bg-white shadow-sm rounded-lg p-4 sm:p-6 mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{t('components.homePage.weddingJourney')}</h2>
        <div className="mb-4 sm:mb-6 bg-gray-200 rounded-full h-2">
          <div className="bg-gray-800 h-2 rounded-full transition-all duration-500 ease-in-out" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="hidden sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 sm:gap-4">
          {onboardingSteps.map((step) => (
            <Link 
              key={step.id}
              to={step.link}
              className={`flex flex-col items-center justify-between p-4 rounded-lg transition-all duration-300 ${
                step.completed ? 'bg-gray-100 text-gray-800' : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
              }`}
              onClick={() => amplitude.track('Onboarding Step Clicked', { stepId: step.id, stepTitle: step.title })}
            >
              <div className="flex flex-col items-center flex-grow">
                <div className="text-3xl mb-2">{step.icon}</div>
                <h3 className="text-sm font-medium text-center">{step.title}</h3>
              </div>
              {step.completed && (
                <CustomIcon d="M5 13l4 4L19 7" className="w-6 h-6 text-green-500 mt-2" />
              )}
            </Link>
          ))}
        </div>
        <div className="sm:hidden grid grid-cols-1 gap-3">
          {relevantSteps.map((step) => (
            <Link 
              key={step.id}
              to={step.link}
              className="flex flex-row items-center justify-between p-3 rounded-lg bg-gray-50 text-gray-600 hover:bg-gray-100 transition-all duration-300"
              onClick={() => amplitude.track('Mobile Onboarding Step Clicked', { stepId: step.id, stepTitle: step.title })}
            >
              <div className="flex items-center flex-grow">
                <div className="text-2xl mr-3">{step.icon}</div>
                <h3 className="text-sm font-medium">{step.title}</h3>
              </div>
              <CustomIcon d="M9 5l7 7-7 7" className="w-5 h-5 text-gray-400" />
            </Link>
          ))}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }
  
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {weddingDetails && weddingDetails.wedding_name ? (
        <div className="mb-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-center md:text-left mb-4 md:mb-0">
              <h1 className="text-3xl md:text-4xl font-semibold text-gray-800">{weddingDetails.wedding_name}</h1>
              <p className="mt-2 text-base md:text-lg text-gray-500">
                {weddingDetails.wedding_date && `${weddingDetails.wedding_date} • `}
                {weddingDetails.wedding_location}
              </p>
            </div>
            {renderCountdown}
          </div>
        </div>
      ) : (
        <div className="mb-8 text-center">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-800">{t('components.homePage.welcome')}</h1>
          <button
            onClick={handleEditDetails}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            {t('components.homePage.addWeddingInfo')}
          </button>
        </div>
      )}

      {renderOnboardingChecklist()}

      <div className="flex flex-col md:flex-row gap-8">
        {weddingDetails && weddingDetails.image_url && (
          <div className="md:w-2/5">
            <img 
              src={weddingDetails.image_url} 
              alt="Wedding" 
              className="w-full h-80 object-cover rounded-lg shadow-md"
              loading="lazy"
            />
          </div>
        )}

        <div className={`bg-white overflow-hidden shadow-sm rounded-md ${weddingDetails && weddingDetails.image_url ? 'md:w-3/5' : 'w-full'} flex flex-col h-full`}>
          <div className="px-4 py-5 sm:p-6 flex-grow">
            <h2 className="text-lg font-medium text-gray-900 mb-4">{t('components.homePage.recentRegistries')}</h2>
            {registries ? (
              registries.length > 0 ? (
                <ul className="divide-y divide-gray-200">
                  {registries.map(registry => (
                    <li key={registry.id} className="py-4 flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-900">{registry.registry_name || registry.registry_type}</span>
                      <Link to={`/registry/${registry.id}`} className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200">
                        {t('components.homePage.view')}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="text-center">
                  <p className="text-gray-600 italic mb-4">{t('components.homePage.noRegistries')}</p>
                  <Link to="/registry?create=true" className="inline-block px-6 py-3 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200">
                    {t('components.registry.buildFirstRegistry')}
                  </Link>
                </div>
              )
            ) : (
              <p className="text-gray-600 italic">Failed to load registries data</p>
            )}
          </div>
          <div className="bg-gray-50 px-4 py-4 sm:px-6 mt-auto">
            <Link to="/registry" className="text-sm font-medium text-gray-800 hover:text-gray-700 transition-colors duration-200 underline hover:no-underline">
              {t('components.homePage.viewAllRegistries')} &rarr;
            </Link>
          </div>
        </div>
      </div>

      {showEditModal && (
        <WeddingDetailsModal
          weddingDetails={weddingDetails}
          onSave={handleSaveDetails}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
};

export default React.memo(HomePage);