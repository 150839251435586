import React, { useState } from 'react';
import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';



export default function RsvpTemplate({ data, colors, styles }) {
  const [searchForm, setSearchForm] = useState({ search: '' });
  const [rsvpForm, setRsvpForm] = useState({
    email: '',
    cellphone: '',
    full_name: '',
    number_of_guests: 1,
    dietary_restrictions: '',
    additional_notes: '',
    rsvp_status: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [invitationFound, setInvitationFound] = useState(false);
  const [possibleResults, setPossibleResults] = useState([]);
  const [showManualEntry, setShowManualEntry] = useState(false);
  const supabase = getSupabase();
  const { t } = useTranslation();

  const handleSearchChange = (e) => {
    setSearchForm({ ...searchForm, [e.target.name]: e.target.value });
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { data: invitationData, error } = await supabase
        .from('wedding_rsvps')
        .select('*')
        .or(`email.ilike.%${searchForm.search}%,full_name.ilike.%${searchForm.search}%,cellphone.ilike.%${searchForm.search}%`)
        .eq('wedding_id', data.weddingId);

      if (error) throw error;

      if (invitationData && invitationData.length > 0) {
        if (invitationData.length === 1) {
          setInvitationFound(true);
          setRsvpForm({
            ...rsvpForm,
            email: invitationData[0].email,
            cellphone: invitationData[0].cellphone,
            full_name: invitationData[0].full_name,
            number_of_guests: invitationData[0].number_of_guests
          });
        } else {
          setPossibleResults(invitationData);
        }
      } else {
        toast.info('No exact match found. You can select from possible matches or enter your information manually.');
      }
    } catch (error) {
      console.error('Error searching for invitation:', error);
      toast.error('Failed to search for invitation. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectInvitation = (invitation) => {
    setInvitationFound(true);
    setRsvpForm({
      ...rsvpForm,
      email: invitation.email,
      cellphone: invitation.cellphone,
      full_name: invitation.full_name,
      number_of_guests: invitation.number_of_guests
    });
    setPossibleResults([]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRsvpForm(prev => ({ ...prev, [name]: value }));
  };

  const handleRsvpSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { data: rsvpData, error: rsvpError } = await supabase
        .from('wedding_rsvps')
        .upsert({
          wedding_id: data.weddingId,
          email: rsvpForm.email,
          cellphone: rsvpForm.cellphone,
          full_name: rsvpForm.full_name,
          rsvp_status: rsvpForm.rsvp_status,
          number_of_guests: rsvpForm.number_of_guests,
          dietary_restrictions: rsvpForm.dietary_restrictions,
          additional_notes: rsvpForm.additional_notes,
          rsvp_date: new Date().toISOString()
        }, {
          onConflict: ['wedding_id', 'email'],
          returning: 'minimal'
        });

      if (rsvpError) throw rsvpError;

      toast.success('RSVP submitted successfully!');
      setRsvpForm({
        email: '',
        cellphone: '',
        full_name: '',
        rsvp_status: '',
        number_of_guests: 1,
        dietary_restrictions: '',
        additional_notes: ''
      });
      setInvitationFound(false);
      setShowManualEntry(false);
      setPossibleResults([]);
    } catch (error) {
      console.error('Error submitting RSVP:', error);
      toast.error('Failed to submit RSVP. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="rsvp" style={{...styles.section, backgroundColor: colors.background}}>
      <div style={styles.rsvpContent}>
        <h2 style={{...styles.sectionTitle, color: colors.primary}}>{t('components.rsvpTemplate.rsvp')}</h2>
        {!invitationFound && !showManualEntry ? (
          <>
            <form style={{...styles.rsvpForm, maxWidth: '400px', margin: '0 auto'}} onSubmit={handleSearchSubmit}>
              <input
                type="text"
                name="search"
                value={searchForm.search}
                onChange={handleSearchChange}
                placeholder={t('components.rsvpTemplate.enterNameEmailPhone')}
                required
                style={{...styles.input, marginBottom: '1rem'}}
              />
              <button type="submit" style={{...styles.submitButton, backgroundColor: colors.primary, color: colors.secondary, width: '100%'}} disabled={isSubmitting}>
                {isSubmitting ? t('components.rsvpTemplate.searching') : t('components.rsvpTemplate.findMyInvitation')}
              </button>
            </form>
            {possibleResults.length > 0 && (
              <div className="mt-8 bg-white p-6 rounded-lg shadow-lg border border-gray-200 max-h-80 overflow-y-auto">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">{t('components.rsvpTemplate.possibleMatches')}</h3>
                <ul className="space-y-4">
                  {possibleResults.map((result, index) => (
                    <li key={index} className="border-b border-gray-100 pb-4 last:border-b-0">
                      <button 
                        onClick={() => handleSelectInvitation(result)}
                        className="group block hover:bg-gray-50 transition-colors duration-200 rounded-md p-3 w-full text-left"
                      >
                        <h4 className="text-lg font-medium text-gray-800 group-hover:text-gray-900 transition-colors duration-200">
                          {result.full_name}
                        </h4>
                        <p className="mt-1 text-sm text-gray-600 group-hover:text-gray-700 transition-colors duration-200">
                          {result.email}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <button 
              onClick={() => setShowManualEntry(true)} 
              style={{...styles.submitButton, backgroundColor: colors.secondary, color: colors.primary, width: '100%', marginTop: '1rem'}}
            >
              {t('components.rsvpTemplate.enterManually')}
            </button>
          </>
        ) : (
          <form style={{...styles.rsvpForm, maxWidth: '400px', margin: '0 auto'}} onSubmit={handleRsvpSubmit}>
            <input
              type="text"
              name="full_name"
              value={rsvpForm.full_name}
              onChange={handleInputChange}
              placeholder={t('components.rsvpTemplate.fullName')}
              required
              style={{...styles.input, marginBottom: '1rem'}}
            />
            <input
              type="tel"
              name="cellphone"
              value={rsvpForm.cellphone}
              onChange={handleInputChange}
              placeholder={t('components.rsvpTemplate.cellphone')}
              style={{...styles.input, marginBottom: '1rem'}}
            />
            <input
              type="email"
              name="email"
              value={rsvpForm.email}
              onChange={handleInputChange}
              placeholder={t('components.rsvpTemplate.email')}
              required
              style={{...styles.input, marginBottom: '1rem'}}
            />
            <div style={{marginBottom: '1rem'}}>
              <label style={{...styles.label, color: colors.primary, display: 'block', marginBottom: '0.5rem'}}>{t('components.rsvpTemplate.willYouBeAttending')}</label>
              <div>
                <label style={{marginRight: '1rem'}}>
                  <input
                    type="radio"
                    name="rsvp_status"
                    value="rsvp_yes"
                    checked={rsvpForm.rsvp_status === 'rsvp_yes'}
                    onChange={handleInputChange}
                    style={{marginRight: '0.5rem'}}
                  />
                  {t('components.rsvpTemplate.yes')}
                </label>
                <label>
                  <input
                    type="radio"
                    name="rsvp_status"
                    value="rsvp_no"
                    checked={rsvpForm.rsvp_status === 'rsvp_no'}
                    onChange={handleInputChange}
                    style={{marginRight: '0.5rem'}}
                  />
                  {t('components.rsvpTemplate.no')}
                </label>
              </div>
            </div>
            <textarea
              name="additional_notes"
              value={rsvpForm.additional_notes}
              onChange={handleInputChange}
              placeholder={t('components.rsvpTemplate.additionalNotes')}
              style={{...styles.textarea, marginBottom: '1rem'}}
            />
            <details style={{marginBottom: '1rem', color: colors.primary}}>
              <summary style={{cursor: 'pointer', fontWeight: 'bold'}}>{t('components.rsvpTemplate.additionalDetails')}</summary>
              <div style={{marginTop: '1rem'}}>
                <label htmlFor="number_of_guests" style={{...styles.label, color: colors.primary, display: 'block', marginBottom: '0.5rem'}}>{t('components.rsvpTemplate.numberOfWeddingGuests')}</label>
                <input
                  id="number_of_guests"
                  type="number"
                  name="number_of_guests"
                  value={rsvpForm.number_of_guests}
                  onChange={handleInputChange}
                  min="1"
                  required
                  style={{...styles.input, marginBottom: '1rem'}}
                />
                <textarea
                  name="dietary_restrictions"
                  value={rsvpForm.dietary_restrictions}
                  onChange={handleInputChange}
                  placeholder={t('components.rsvpTemplate.dietaryRestrictions')}
                  style={{...styles.textarea, marginBottom: '1rem'}}
                />
              </div>
            </details>
            <button type="submit" style={{...styles.submitButton, backgroundColor: colors.primary, color: colors.secondary, width: '100%'}} disabled={isSubmitting}>
              {isSubmitting ? t('components.rsvpTemplate.submitting') : t('components.rsvpTemplate.submitRSVP')}
            </button>
            {(invitationFound || showManualEntry) && (
              <button 
                onClick={() => {
                  setInvitationFound(false);
                  setShowManualEntry(false);
                  setPossibleResults([]);
                  setRsvpForm({
                    email: '',
                    cellphone: '',
                    full_name: '',
                    rsvp_status: '',
                    number_of_guests: 1,
                    dietary_restrictions: '',
                    additional_notes: ''
                  });
                }} 
                style={{...styles.submitButton, backgroundColor: colors.secondary, color: colors.primary, width: '100%', marginTop: '1rem'}}
              >
                {t('components.rsvpTemplate.backToSearch')}
              </button>
            )}
          </form>
        )}
      </div>
    </section>
  );
}