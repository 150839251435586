import React, { useState, useEffect } from 'react';
import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export function RSVPList({rsvpData, categories}) {
  const [guests, setGuests] = useState(rsvpData || []);
  const [filter, setFilter] = useState('all');
  const { t } = useTranslation();
  const [expandedGuest, setExpandedGuest] = useState(null);
  const supabase = getSupabase();

  const toggleGuestDetails = (guestId) => {
    setExpandedGuest(expandedGuest === guestId ? null : guestId);
  };

  const filteredGuests = guests.filter(guest => {
    if (filter === 'all') return true;
    return guest.rsvp_status === filter;
  });

  const guestCounts = {
    all: guests.length,
    invited: guests.filter(guest => guest.rsvp_status === 'invited').length,
    rsvp_yes: guests.filter(guest => guest.rsvp_status === 'rsvp_yes').length,
    rsvp_no: guests.filter(guest => guest.rsvp_status === 'rsvp_no').length,
    confirmed: guests.filter(guest => guest.rsvp_status === 'confirmed').length,
    to_be_invited: guests.filter(guest => guest.rsvp_status === 'to_be_invited').length,
  };

  const updateGuestStatus = async (guestId, newStatus) => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .update({ rsvp_status: newStatus })
        .eq('id', guestId);

      if (error) throw error;

      setGuests(guests.map(guest => 
        guest.id === guestId ? { ...guest, rsvp_status: newStatus } : guest
      ));

      toast.success(t('components.rsvpList.statusUpdateSuccess'));
    } catch (error) {
      console.error('Error updating guest status:', error);
      toast.error(t('components.rsvpList.statusUpdateError'));
    }
  };

  const updateGuestCategory = async (guestId, newCategory) => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .update({ category: newCategory })
        .eq('id', guestId);

      if (error) throw error;

      setGuests(guests.map(guest => 
        guest.id === guestId ? { ...guest, category: newCategory } : guest
      ));

      toast.success(t('components.rsvpList.categoryUpdateSuccess'));
    } catch (error) {
      console.error('Error updating guest category:', error);
      toast.error(t('components.rsvpList.categoryUpdateError'));
    }
  };

  return (
    <div className="mt-4 px-2 sm:px-4 md:px-6 lg:px-8">
      <div className="mb-4 flex flex-wrap gap-2 justify-center">
        {['all', 'to_be_invited', 'invited', 'rsvp_yes', 'rsvp_no', 'confirmed'].map((status) => (
          <button
            key={status}
            onClick={() => setFilter(status)}
            className={`px-2 sm:px-3 py-1 rounded-md text-xs sm:text-sm font-medium transition-colors duration-200 ${
              filter === status
                ? 'bg-gray-800 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
            }`}
          >
            {t(`components.rsvpList.${status}`)} ({guestCounts[status]})
          </button>
        ))}
      </div>
      <ul className="space-y-4 mb-4">
        {filteredGuests.map((guest) => (
          <li key={guest.id} className="border p-2 sm:p-3 rounded shadow-sm hover:shadow-md transition-shadow duration-200">
            <div 
              className="flex flex-col sm:flex-row justify-between items-start sm:items-center cursor-pointer" 
              onClick={() => toggleGuestDetails(guest.id)}
            >
              <div className="flex-grow mb-2 sm:mb-0">
                <strong className="text-sm sm:text-base block">{guest.full_name}</strong>
                <span className="block text-xs text-gray-600">{guest.email}</span>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <div className={`px-2 py-1 rounded-full text-xs ${
                  guest.rsvp_status === 'invited' ? 'bg-blue-100 text-blue-800' :
                  guest.rsvp_status === 'rsvp_yes' ? 'bg-green-100 text-green-800' : 
                  guest.rsvp_status === 'rsvp_no' ? 'bg-red-100 text-red-800' : 
                  guest.rsvp_status === 'to_be_invited' ? 'bg-gray-100 text-gray-800' :
                  guest.rsvp_status === 'confirmed' ? 'bg-purple-100 text-purple-800' :
                  'bg-gray-100 text-gray-800'
                }`}>
                  {guest.rsvp_status === 'invited' ? t('components.rsvpList.invited') :
                   guest.rsvp_status === 'rsvp_yes' ? t('components.rsvpList.attending') : 
                   guest.rsvp_status === 'rsvp_no' ? t('components.rsvpList.notAttending') : 
                   guest.rsvp_status === 'to_be_invited' ? t('components.rsvpList.toBeInvited') : 
                   guest.rsvp_status === 'confirmed' ? t('components.rsvpList.confirmed') : 
                   t('components.rsvpList.noStatus', "No Status")}
                </div>
                {categories && categories.length > 0 && (
                  <select
                    value={guest.category || ''}
                    onChange={(e) => {
                      e.stopPropagation();
                      updateGuestCategory(guest.id, e.target.value);
                    }}
                    className="px-2 py-1 border rounded text-xs"
                  >
                    <option value="">{t('components.rsvpList.selectCategory')}</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            {expandedGuest === guest.id && (
              <div className="mt-3 text-xs sm:text-sm text-gray-700">
                <p><strong>{t('components.rsvpList.phone')}:</strong> {guest.cellphone || t('components.rsvpList.notProvided')}</p>
                <p><strong>{t('components.rsvpList.numberOfGuests')}:</strong> {guest.number_of_guests || 1}</p>
                <p><strong>{t('components.rsvpList.dietaryRestrictions')}:</strong> {guest.dietary_restrictions || t('components.rsvpList.none')}</p>
                <p><strong>{t('components.rsvpList.additionalNotes')}:</strong> {guest.additional_notes || t('components.rsvpList.none')}</p>
                <p><strong>{t('components.rsvpList.rsvpDate')}:</strong> {new Date(guest.rsvp_date).toLocaleDateString()}</p>
                <div className="mt-2 flex flex-wrap gap-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'to_be_invited');
                    }}
                    className="px-2 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markToBeInvited')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'invited');
                    }}
                    className="px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markInvited')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'rsvp_yes');
                    }}
                    className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markAttending')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'rsvp_no');
                    }}
                    className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markNotAttending')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'confirmed');
                    }}
                    className="px-2 py-1 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markConfirmed')}
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}