import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify';
import BankTransferCheckout from './BankTransferCheckout';
import { useTranslation } from 'react-i18next';
import CustomIcon from './CustomIcon';

const CheckoutPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [pendingOrder, setPendingOrder] = useState(null);
  const [name, setName] = useState(() => localStorage.getItem('checkoutName') || '');
  const [email, setEmail] = useState(() => localStorage.getItem('checkoutEmail') || '');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [bankTransferInstructions, setBankTransferInstructions] = useState(null);
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [error, setError] = useState(null);
  const [bankTransferData, setBankTransferData] = useState(null);
  const [giftMessage, setGiftMessage] = useState(() => localStorage.getItem('checkoutGiftMessage') || '');
  const [isGeneratingMessage, setIsGeneratingMessage] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const paymentIcons = {
    card: {
      action: 'redirect',
      label: t('components.checkoutPage.creditCard'),
      icon: "M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
    },
    bank_transfer: {
      action: 'showInstructions',
      label: t('components.checkoutPage.bankTransfer'),
      icon: "M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z",
      pill: t('components.checkoutPage.cheaper'),
    }
  };

  useEffect(() => {
    fetchPendingOrder();
    fetchPaymentMethods();
  }, [id]);

  useEffect(() => {
    localStorage.setItem('checkoutName', name);
  }, [name]);

  useEffect(() => {
    localStorage.setItem('checkoutEmail', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('checkoutGiftMessage', giftMessage);
  }, [giftMessage]);

  const generateGiftMessage = async () => {
    setIsGeneratingMessage(true);
    try {
      const response = await fetch(`${API_BASE_URL}/public/generate-gift-message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderId: pendingOrder.id }),
      });

      if (!response.ok) throw new Error('Failed to generate gift message');

      const data = await response.json();
      setGiftMessage(data.message);
      toast.success('Gift message generated!');
    } catch (err) {
      toast.error('Failed to generate gift message. Please try again.');
    } finally {
      setIsGeneratingMessage(false);
    }
  };

  const fetchPendingOrder = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/public/orders/${id}`);
      if (!response.ok) throw new Error('Failed to fetch pending order');
      const data = await response.json();
      setPendingOrder(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchPaymentMethods = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/public/payment-methods/${id}`);
      if (!response.ok) throw new Error('Failed to fetch payment methods');
      const data = await response.json();
      const activeMethods = data.payment_methods.filter(method => method.status === 'active');
      setPaymentMethods(activeMethods);
      if (activeMethods.length > 0) {
        setPaymentMethod(activeMethods[0].method_type);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = t('components.checkoutPage.nameRequired');
    }
    if (!email.trim()) {
      errors.email = t('components.checkoutPage.emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = t('components.checkoutPage.invalidEmail');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCheckout = async () => {
    if (!pendingOrder) {
      setError('No pending order found');
      return;
    }
    
    if (!validateForm()) {
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/public/checkout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          orderId: pendingOrder.id,
          name,
          email, 
          paymentMethod,
          giftMessage,
        }),
      });

      if (!response.ok) throw new Error('Checkout failed');

      const data = await response.json();

      if (paymentIcons[paymentMethod].action === 'redirect' && data.url) {
        window.location.href = data.url;
      } else if (paymentIcons[paymentMethod].action === 'showInstructions' && data.transferInstructions) {
        navigate(`/bank-transfer/${data.orderId}`, { 
          state: { 
            transferInstructions: data.transferInstructions,
            email: email
          } 
        });
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return <div className="text-red-500 text-center mt-8">Error: {error}</div>;
  }

  if (!pendingOrder) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">{t('components.checkoutPage.title')}</h1>
      
      <div className="mb-8 bg-gray-100 p-4 rounded-md">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">{t('components.checkoutPage.orderSummary')}</h2>
        {pendingOrder.cart.map((item) => (
          <div key={item.id} className="flex justify-between items-center mb-2">
            <span>{item.name} x {item.quantity}</span>
            <span>${(item.price * item.quantity).toFixed(2)}</span>
          </div>
        ))}
        <div className="border-t border-gray-300 mt-4 pt-4 flex justify-between items-center font-semibold">
          <span>{t('components.checkoutPage.total')}</span>
          <span>${pendingOrder.total_amount}</span>
        </div>
      </div>

      <div className="mb-6">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">{t('components.checkoutPage.name')}</label>
        <input
          id="name"
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={`w-full p-3 text-gray-800 border ${formErrors.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
        />
        {formErrors.name && <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>}
      </div>

      <div className="mb-6">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">{t('components.checkoutPage.email')}</label>
        <input
          id="email"
          type="email"
          placeholder="you@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`w-full p-3 text-gray-800 border ${formErrors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
        />
        {formErrors.email && <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>}
      </div>

      <div className="mb-6">
        <label htmlFor="giftMessage" className="block text-sm font-medium text-gray-700 mb-2">{t('components.checkoutPage.giftMessage')}</label>
        <div className="flex items-center space-x-2">
          <textarea
            id="giftMessage"
            placeholder={t('components.checkoutPage.giftMessagePlaceholder')}
            value={giftMessage}
            onChange={(e) => setGiftMessage(e.target.value)}
            className="w-full p-3 text-gray-800 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="3"
          />
        </div>
      </div>

      {paymentMethods.length > 0 && (
        <div className="mb-6">
          <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700 mb-2">{t('components.checkoutPage.paymentMethod')}</label>
          <div className="grid grid-cols-1 gap-4">
            {paymentMethods.map((method) => (
              <button
                key={method.method_type}
                onClick={() => setPaymentMethod(method.method_type)}
                className={`p-3 border rounded-md flex items-center justify-center ${paymentMethod === method.method_type ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
              >
                <CustomIcon d={paymentIcons[method.method_type].icon} />
                <span className="ml-2">{paymentIcons[method.method_type].label}</span>
                {paymentIcons[method.method_type].pill && (
                  <span className="ml-2 px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full">
                    {paymentIcons[method.method_type].pill}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      )}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <button
        onClick={handleCheckout}
        disabled={isLoading}
        className="w-full bg-gray-800 text-white px-6 py-3 rounded-md text-sm font-medium hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {t('components.checkoutPage.processing')}
          </span>
        ) : (
          <>
            <CustomIcon d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            <span className="ml-2">{t('components.checkoutPage.completePurchase')}</span>
          </>
        )}
      </button>
      <p className="text-sm text-gray-600 mt-4 text-center">{t('components.checkoutPage.securePayment')}</p>

      {bankTransferData && (
        <BankTransferCheckout 
          orderId={bankTransferData.orderId}
          transferInstructions={bankTransferData.transferInstructions}
        />
      )}
    </div>
  );
};

export default CheckoutPage;