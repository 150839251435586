import { getSupabase, API_BASE_URL } from '../config/supabase';
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { Eye } from 'lucide-react';



// Registry component
const Registry = () => {
  const { t } = useTranslation();
  const [registries, setRegistries] = useState([]);
  const [registryName, setRegistryName] = useState('');
  const [registryType, setRegistryType] = useState('Wedding');
  const [description, setDescription] = useState('');
  const [currency, setCurrency] = useState('MXN');
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showGenerateForm, setShowGenerateForm] = useState(false);
  const supabase = getSupabase();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRegistries();
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('create') !== null) {
      setShowGenerateForm(true);
    }
  }, [location]);

  const fetchRegistries = async () => {
    setIsLoading(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/registry`, {
          headers: {
            'Authorization': `Bearer ${session.access_token}`
          }
        });
        const data = await response.json();
        setRegistries(data);
      } catch (error) {
        console.error('Error fetching registries:', error);
        toast.error(t('components.registry.fetchError'));
      }
    }
    setIsLoading(false);
  };

  const generateAIRegistry = async (e) => {
    e.preventDefault();
    setIsGenerating(true);
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/registry/generate`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            registry_name: registryName,
            registry_type: registryType,
            description: description,
            currency: currency,
            include_basic_registry: document.getElementById('includeBasicRegistry').checked
          })
        });
        if (response.ok) {
          const data = await response.json();
          toast.success(t('components.registry.generateSuccess'));
          fetchRegistries();
          setRegistryName('');
          setRegistryType('Wedding');
          setDescription('');
          setCurrency('MXN');
          setShowGenerateForm(false);
          // Redirect to the created registry
          navigate(`/registry/${data.id}`);
        } else {
          toast.error(t('components.registry.generateError'));
        }
      } catch (error) {
        console.error('Error generating AI registry:', error);
        toast.error(t('components.registry.generateErrorUnknown'));
      }
    }
    setIsGenerating(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-0">{t('components.registry.yourRegistries')}</h2>
        <button
          onClick={() => setShowGenerateForm(true)}
          className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
          title={t('components.registry.generateNewRegistry')}
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span className="text-sm font-medium">{t('components.registry.generateNewRegistry')}</span>
        </button>
      </div>

      {showGenerateForm && createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-xl max-h-[90vh] overflow-y-auto relative">
            <button
              onClick={() => setShowGenerateForm(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="bg-white shadow-sm rounded-lg p-4 sm:p-6">
              <h3 className="text-lg sm:text-xl font-semibold mb-4">{t('components.registry.generateNewRegistry')}</h3>
              <form onSubmit={generateAIRegistry} className="space-y-4">
                <div>
                  <label htmlFor="registryName" className="block text-sm font-medium text-gray-700">
                    {t('components.registrySettings.name')}
                  </label>
                  <input
                    type="text"
                    id="registryName"
                    value={registryName}
                    onChange={(e) => setRegistryName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                    placeholder="Juan & Sofia"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="registryType" className="block text-sm font-medium text-gray-700">
                    {t('components.registrySettings.type')}
                  </label>
                  <select
                    id="registryType"
                    value={registryType}
                    onChange={(e) => setRegistryType(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                    required
                  >
                    <option value="Wedding">Wedding</option>
                    <option value="Baby Shower" disabled>Baby Shower (coming soon)</option>
                    <option value="Birthday" disabled>Birthday (coming soon)</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    {t('components.registry.registryDescription')}
                  </label>
                  <textarea
                    id="description"
                    placeholder={t('components.registry.registryDescriptionPlaceholder')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 h-24 resize-none"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                    {t('components.registrySettings.currency')}
                  </label>
                  <select
                    id="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                    required
                  >
                    <option value="MXN">MXN</option>
                    <option value="USD" disabled>USD (coming soon)</option>
                    <option value="BRL" disabled>BRL (coming soon)</option>
                  </select>
                </div>
                <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <div className="flex items-center mb-2 sm:mb-0">
                      <input
                        type="checkbox"
                        id="includeBasicRegistry"
                        className="h-5 w-5 text-gray-800 focus:ring-gray-500 border-gray-300 rounded"
                      />
                      <label htmlFor="includeBasicRegistry" className="ml-3 block text-sm font-medium text-gray-900">
                        {t('components.registry.includeBasicRegistry', 'Include pre-selected gift selection')}
                      </label>
                    </div>
                    <span className="bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded-full self-start sm:self-auto">
                      {t('common.recommended', "Recommended")}
                    </span>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">
                    {t('components.registry.includeBasicRegistryDescription', 'This will add a curated list of popular gifts to your registry, giving you a head start.')}
                  </p>
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                  disabled={isGenerating}
                >
                  {isGenerating ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                      {t('common.generating')}
                    </>
                  ) : (
                    t('components.registry.generate')
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>,
        document.body
      )}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {isLoading ? (
          <div className="col-span-full flex justify-center items-center mt-40">
            <LoadingSpinner />
          </div>
        ) : registries.length === 0 ? (
          <div className="col-span-full flex flex-col items-center justify-center space-y-4 mt-40">
            <p className="text-center text-gray-500 italic">{t('components.registry.noRegistriesFound')}</p>
            <button
              onClick={() => setShowGenerateForm(true)}
              className="px-6 py-3 bg-gray-800 text-white text-lg font-semibold rounded-md hover:bg-gray-700 transition-colors duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-1"
            >
              {t('components.registry.buildFirstRegistry')}
            </button>
          </div>
        ) : (
          registries && registries.length > 0 ? (
            registries.map((registry) => (
              <div key={registry.id} className="bg-white rounded-md shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden flex flex-col h-full">
                <div className="p-5 flex flex-col flex-grow">
                  <div className="flex justify-between items-center mb-3">
                    <h3 className="text-xl font-semibold text-gray-900">{registry.registry_name || registry.registry_type}</h3>
                    <span className="text-xs text-gray-500">{new Date(registry.created_at).toLocaleDateString()}</span>
                  </div>
                  <p className="text-sm text-gray-600 mb-4">{registry.registry_type}</p>
                  <div className="flex flex-col space-y-2 mt-auto">
                    <Link 
                      to={`/registry/${registry.id}`} 
                      className="px-4 py-2 bg-gray-800 text-white text-sm rounded-md hover:bg-gray-700 transition-colors duration-200 text-center"
                    >
                      {t('components.registry.edit')}
                    </Link>
                    <Link 
                      to={`/registry/${registry.id}/gifts`} 
                      className="px-4 py-2 bg-gray-800 text-white text-sm rounded-md hover:bg-gray-700 transition-colors duration-200 text-center"
                    >
                      {t('components.registry.manageGifts')}
                    </Link>
                    <Link 
                      to={`/registry/${registry.id}/orders`} 
                      className="px-4 py-2 bg-gray-100 text-gray-600 text-sm rounded-md hover:bg-gray-200 transition-colors duration-200 text-center"
                    >
                      {t('components.registry.viewOrders')}
                    </Link>
                    <Link
                      to={`/public/registry/${registry.id}`}
                      className="px-4 py-2 bg-gray-100 text-gray-600 text-sm rounded-md hover:bg-gray-200 transition-colors duration-200 text-center flex items-center justify-center"
                    >
                      <Eye size={18} className="mr-2" />
                      {t('components.registry.view')}
                      <span className="sr-only">{t('components.registry.preview')}</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-500">
              {t('components.registry.failedToLoadRegistries')}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Registry;