

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from './Modal'; // Assuming you have a Modal component

const RegistrySettings = ({ registry, onUpdate, onDelete }) => {
  const { t } = useTranslation();
  const [registryName, setRegistryName] = useState(registry.registry_name || '');
  const [registryType, setRegistryType] = useState(registry.registry_type || '');
  const [currency, setCurrency] = useState(registry.currency || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate({
      ...registry,
      registry_name: registryName,
      registry_type: registryType,
      currency,
    });
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      <h3 className="text-xl font-semibold mb-4">{t('components.registrySettings.title')}</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="registryName" className="block text-sm font-medium text-gray-700">
            {t('components.registrySettings.name')}
          </label>
          <input
            type="text"
            id="registryName"
            value={registryName}
            onChange={(e) => setRegistryName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
            required
          />
        </div>
        <div>
          <label htmlFor="registryType" className="block text-sm font-medium text-gray-700">
            {t('components.registrySettings.type')}
          </label>
          <select
            id="registryType"
            value={registryType}
            onChange={(e) => setRegistryType(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
            required
          >
            <option value="Wedding">Wedding</option>
            <option value="Baby Shower" disabled>Baby Shower (coming soon)</option>
            <option value="Birthday" disabled>Birthday (coming soon)</option>
          </select>
        </div>
        <div>
          <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
            {t('components.registrySettings.currency')}
          </label>
          <select
            id="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
            required
          >
            <option value="MXN">MXN</option>
            <option value="USD" disabled>USD (coming soon)</option>
            <option value="BRL" disabled>BRL (coming soon)</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('components.registrySettings.createdDate')}
          </label>
          <p className="mt-1 text-sm text-gray-500">
            {new Date(registry.created_at).toLocaleDateString()}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4">
          <button
            type="submit"
            className="w-full sm:w-auto px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium"
          >
            {t('components.registrySettings.saveChanges')}
          </button>
          <button
            type="button"
            onClick={handleDeleteClick}
            className="w-full sm:w-auto px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 text-sm font-medium"
          >
            {t('components.registrySettings.deleteRegistry')}
          </button>
        </div>
      </form>

      {showDeleteConfirmation && (
        <Modal onClose={() => setShowDeleteConfirmation(false)}>
          <div className="p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              {t('components.registrySettings.confirmDelete')}
            </h3>
            <div className="mt-5 sm:mt-4 flex flex-col-reverse sm:flex-row-reverse space-y-4 space-y-reverse sm:space-y-0 sm:space-x-4 sm:space-x-reverse">
              <button
                type="button"
                className="w-full sm:w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                onClick={handleConfirmDelete}
              >
                {t('components.registrySettings.delete')}
              </button>
              <button
                type="button"
                className="w-full sm:w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                {t('components.registrySettings.cancel')}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RegistrySettings;