import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExpandableDescription = ({ description, maxLength = 200 }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  if (description.length <= maxLength) {
    return <p>{description}</p>;
  }

  return (
    <p>
      {isExpanded ? description : `${description.slice(0, maxLength)}...`}
      <button
        className="text-gray-800 hover:underline focus:outline-none ml-1"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? t('components.expandableDescription.seeLess') : t('components.expandableDescription.seeMore')}
      </button>
    </p>
  );
};

export default ExpandableDescription;