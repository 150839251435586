import React from 'react';
import { useTranslation } from 'react-i18next';

const GiftSummary = ({ summary, onFilterChange, currentFilter }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-6 bg-white shadow-sm rounded-lg p-4">
      <h4 className="text-lg font-semibold mb-2">{t('components.giftSummary.title')}</h4>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => onFilterChange('all')}
          className={`px-3 py-1 rounded-md text-xs font-medium transition-colors duration-200 ${
            currentFilter === 'all'
              ? 'bg-gray-800 text-white'
              : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
          }`}
        >
          {t('components.giftSummary.allGifts')} ({summary.reduce((acc, range) => acc + range.count, 0)})
        </button>
        {summary.map((range) => (
          <button
            key={range.label}
            onClick={() => onFilterChange(range.label.toLowerCase())}
            className={`px-3 py-1 rounded-md text-xs font-medium transition-colors duration-200 ${
              currentFilter === range.label.toLowerCase()
                ? 'bg-gray-800 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
            }`}
          >
            {t(`components.giftSummary.${range.label.toLowerCase()}`)} (${range.min.toFixed(2)} - ${range.max.toFixed(2)}) ({range.count})
          </button>
        ))}
      </div>
    </div>
  );
};

export default GiftSummary;