import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Gift, CreditCard, DollarSign, Check, X, Percent } from 'lucide-react';

const PricingPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState('metzi');

  const features = [
    { name: t('components.pricingPage.lowWithdrawalFee', 'Low Withdrawal Fee'), metzi: true, zepika: false },
    { name: t('components.pricingPage.noCashFundFees', 'No Cash Fund Fees'), metzi: true, zepika: false },
    { name: t('components.pricingPage.giftRegistry', 'Gift Registry'), metzi: true, zepika: true },
    { name: t('components.pricingPage.weddingWebsite', 'Wedding Website'), metzi: true, zepika: true },
    { name: t('components.pricingPage.greatUserExperience', 'A Great User Experience'), metzi: true, zepika: false },
    { name: t('components.pricingPage.immediateTransfers', 'Immediate Transfers'), metzi: true, zepika: false },
    // { name: t('components.pricingPage.guestManagement', 'Guest Management'), metzi: true, zepika: true },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-20 md:py-32 bg-gradient-to-b from-gray-50 to-white">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl mb-4 text-gray-900">
                {t('components.pricingPage.howWeEarnTitle', 'How Metzi Makes Money')}
              </h1>
              <p className="text-xl text-gray-600 leading-relaxed">
                {t('components.pricingPage.howWeEarnSubtitle', 'Transparent pricing for your perfect wedding experience')}
              </p>
            </div>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              {[
                {
                  title: t('components.pricingPage.giftSales', 'Gift Sales'),
                  description: t('components.pricingPage.giftSalesDesc', 'We offer a curated selection of gifts and experiences from top brands. Like any retailer, we earn a tiny margin on these sales to cover our costs.'),
                  icon: Gift
                },
                {
                  title: t('components.pricingPage.cardPayments', 'Card Payments'),
                  description: (
                    <div>
                      <p>{t('components.pricingPage.cashFundsDesc', 'We use Stripe as our payment processor. Their fee breakdown:')}</p>
                      <ul className="list-disc list-inside mt-2 text-left">
                        <li>3.6% of transaction</li>
                        <li>+ 3 pesos</li>
                        <li>+ applicable taxes</li>
                      </ul>
                      <p className="mt-2">{t('components.pricingPage.noProfit', 'We do not profit from these fees.')}</p>
                    </div>
                  ),
                  icon: CreditCard
                },
                {
                  title: t('components.pricingPage.withdrawalFee', 'Withdrawal Fee'),
                  description: t('components.pricingPage.withdrawalFeeDesc', 'We charge a 1% fee or a 5,000 peso flat charge when you withdraw your money, whichever is lower.'),
                  icon: DollarSign
                },
                {
                  title: t('components.pricingPage.interestEarned', 'Interest Earned'),
                  description: t('components.pricingPage.interestEarnedDesc', 'We earn interest on the funds held on our platform before they are withdrawn. This helps us cover operational costs and improve our services.'),
                  icon: Percent
                },
              ].map((item, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 flex flex-col relative h-full">
                  {index === 1 && (
                    <div className="absolute top-2 right-2 bg-purple-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                      <a href="https://stripe.com/en-mx/pricing" target="_blank" rel="noopener noreferrer" className="text-white hover:underline">
                        stripe
                      </a>
                    </div>
                  )}
                  <div className="rounded-full bg-gray-100 w-12 h-12 flex items-center justify-center mb-4">
                    <item.icon className="w-6 h-6 text-gray-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
                  <div className="text-gray-600 flex-grow">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-20 md:py-32 bg-gray-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-4 text-gray-900">
                {t('components.pricingPage.priceComparison', 'Price & Feature Comparison')}
              </h2>
              <p className="text-xl text-gray-600 leading-relaxed">
                {t('components.pricingPage.priceComparisonDesc', 'Fed up with the lackluster options out there, we rolled up our sleeves and built Metzi. See how our pricing and features compare to our competitors')}
              </p>
            </div>
            <div className="md:hidden mb-6">
              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => setActiveTab('metzi')}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${
                    activeTab === 'metzi' ? 'bg-gray-800 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  Metzi
                </button>
                <button
                  onClick={() => setActiveTab('zepika')}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${
                    activeTab === 'zepika' ? 'bg-gray-800 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  Zepika
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="hidden md:grid md:grid-cols-3 bg-gray-800 text-white">
                  <div className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                    {t('components.pricingPage.feature', 'Feature')}
                  </div>
                  <div className="px-6 py-3 text-center text-sm font-semibold uppercase tracking-wider">Metzi</div>
                  <div className="px-6 py-3 text-center text-sm font-semibold uppercase tracking-wider">Zepika</div>
                </div>
                <div className="divide-y divide-gray-200">
                  <div className="grid md:grid-cols-3 hover:bg-gray-50">
                    <div className="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900 flex items-center justify-between md:justify-start">
                      <span>{t('components.pricingPage.withdrawalFee', 'Withdrawal Fee')}</span>
                      <span className="md:hidden ml-2">
                        {activeTab === 'metzi' ? '1% or 5,000 pesos' : '7,000 pesos or 3%'}
                      </span>
                    </div>
                    <div className="px-6 py-4 whitespace-normal text-sm text-center text-gray-500 hidden md:block">
                      1% or 5,000 pesos
                    </div>
                    <div className="px-6 py-4 whitespace-normal text-sm text-center text-gray-500 hidden md:block">
                      7,000 pesos or 3%
                    </div>
                  </div>
                  {features.map((feature, index) => (
                    <div key={index} className="grid md:grid-cols-3 hover:bg-gray-50">
                      <div className="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900 flex items-center justify-between md:justify-start">
                        <span>{feature.name}</span>
                        <span className="md:hidden ml-2">
                          {activeTab === 'metzi' ? (feature.metzi ? <Check size={16} /> : <X size={16} />) : (feature.zepika ? <Check size={16} /> : <X size={16} />)}
                        </span>
                      </div>
                      <div className="px-6 py-4 whitespace-normal text-sm text-center text-gray-500 hidden md:block">
                        {feature.metzi ? <Check size={16} className="mx-auto" /> : <X size={16} className="mx-auto" />}
                      </div>
                      <div className="px-6 py-4 whitespace-normal text-sm text-center text-gray-500 hidden md:block">
                        {feature.zepika ? <Check size={16} className="mx-auto" /> : <X size={16} className="mx-auto" />}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-20 md:py-32 bg-gray-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-4 text-gray-900">
                {t('components.pricingPage.ourCommitment', 'Our Commitment to You')}
              </h2>
              <p className="text-xl text-gray-600 leading-relaxed">
                {t('components.pricingPage.ourCommitmentDesc', 'At Metzi, we\'re dedicated to providing a seamless and enjoyable wedding planning experience.')}
              </p>
            </div>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {[
                {
                  title: t('components.pricingPage.transparency', 'Transparency'),
                  description: t('components.pricingPage.transparencyDesc', 'We believe in being open about how we operate and earn revenue.'),
                },
                {
                  title: t('components.pricingPage.fairPricing', 'Fair Pricing'),
                  description: t('components.pricingPage.fairPricingDesc', 'Our goal is to offer competitive prices on gifts and reasonable fees for services.'),
                },
                {
                  title: t('components.pricingPage.qualityService', 'Quality Service'),
                  description: t('components.pricingPage.qualityServiceDesc', 'We\'re committed to providing excellent customer service and a high-quality platform.'),
                },
              ].map((item, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-20 md:py-32 bg-gray-800 text-white">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-4">
              {t('components.pricingPage.readyToStart', 'Ready to Start Your Wedding Journey?')}
            </h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              {t('components.pricingPage.readyToStartDesc', 'Join Metzi today and experience the joy of stress-free wedding planning.')}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/signup"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-colors duration-200"
              >
                {t('components.pricingPage.getStarted', 'Get Started')}
              </Link>
              <Link
                to="/about"
                className="inline-flex items-center justify-center px-6 py-3 border border-white text-base font-medium rounded-md text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-colors duration-200"
              >
                {t('components.pricingPage.learnMore', 'Learn More')}
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PricingPage;