import React, { useState, useEffect, useRef, useCallback} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import GiftImageGallery from './GiftImageGallery';
import ExpandableDescription from './ExpandableDescription';
import i18n from '../i18n';


const GuestRegistryView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [registry, setRegistry] = useState(null);
  const [gifts, setGifts] = useState([]);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [error, setError] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [weddingDetails, setWeddingDetails] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});
  const [showFullImage, setShowFullImage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchRegistry(), fetchGifts(), fetchWeddingDetails()]);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    if (weddingDetails && weddingDetails.wedding_date) {
      const initialTimeLeft = calculateTimeLeft(weddingDetails.wedding_date);
      setTimeLeft(initialTimeLeft);

      const timer = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
          const newTimeLeft = { ...prevTimeLeft };
          if (newTimeLeft.seconds > 0) {
            newTimeLeft.seconds--;
          } else {
            newTimeLeft.seconds = 59;
            if (newTimeLeft.minutes > 0) {
              newTimeLeft.minutes--;
            } else {
              newTimeLeft.minutes = 59;
              if (newTimeLeft.hours > 0) {
                newTimeLeft.hours--;
              } else {
                newTimeLeft.hours = 23;
                if (newTimeLeft.days > 0) {
                  newTimeLeft.days--;
                }
              }
            }
          }
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [weddingDetails]);

  const fetchRegistry = async () => {
    const response = await fetch(`${API_BASE_URL}/public/registry/${id}`);
    if (!response.ok) throw new Error(t('guestRegistryView.fetchRegistryError'));
    const data = await response.json();
    setRegistry(data);
  };

  const fetchGifts = async () => {
    const response = await fetch(`${API_BASE_URL}/public/registry/${id}/gifts`);
    if (!response.ok) throw new Error(t('guestRegistryView.fetchGiftsError'));
    const data = await response.json();
    setGifts(data.map(item => item.gifts));
  };

  const fetchWeddingDetails = async () => {
    const response = await fetch(`${API_BASE_URL}/public/wedding-details/${id}`);
    if (!response.ok) throw new Error(t('guestRegistryView.fetchWeddingDetailsError'));
    const data = await response.json();
    if (data && data.wedding_name && data.wedding_date) {
      setWeddingDetails(data);
    } else {
      console.log('Wedding details not available or incomplete');
    }
  };

  const calculateTimeLeft = (weddingDate) => {
    const difference = +new Date(weddingDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const addToCart = useCallback((gift) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item.id === gift.id);
      let newCart;

      if (existingItem) {
        newCart = prevCart.map(item => 
          item.id === gift.id 
            ? { ...item, quantity: item.quantity + 1 } 
            : item
        );
      } else {
        newCart = [...prevCart, { ...gift, quantity: 1 }];
      }
      
      // Custom classy toast
      toast.success(
        <div className="flex items-center">
          <div>
            <p className="font-semibold text-gray-800">{gift.name}</p>
            <p className="text-sm text-gray-600">{t('components.guestRegistryView.addedToCart')}</p>
          </div>
        </div>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "bg-white text-gray-800 rounded-lg shadow-md border border-gray-200",
          bodyClassName: "text-sm",
          toastId: gift.id,
          style: {
            marginTop: '10px', // Adjust this value based on your app's header height
          },
        }
      );

      return newCart;
    });
  }, [t]);

  const getTotalPrice = (cartItems) => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const getTotalItems = (cartItems) => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const removeFromCart = (giftId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== giftId));
  };

  const updateCartItemQuantity = (giftId, newQuantity) => {
    setCart(prevCart => {
      return newQuantity === 0
        ? prevCart.filter(item => item.id !== giftId)
        : prevCart.map(item => 
            item.id === giftId 
              ? { ...item, quantity: newQuantity } 
              : item
          );
    });
  };

  const handleCheckout = async () => {
    if (cart.length === 0) {
      setError(t('guestRegistryView.emptyCartError'));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/public/orders`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          registry_id: id,
          cart: cart,
          status: 'pending',
          total_amount: getTotalPrice(cart),
        }),
      });

      if (!response.ok) throw new Error(t('guestRegistryView.createOrderError'));
      const data = await response.json();
      navigate(`/checkout/${data.id}`);
    } catch (err) {
      setError(err.message);
    }
  };

  if (error) {
    return <div className="text-red-500 text-center mt-8">{t('guestRegistryView.error', { error })}</div>;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );  
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 relative">
      {weddingDetails && weddingDetails.wedding_name && weddingDetails.wedding_date ? (
        <div className="mb-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
              {weddingDetails.image_url && (
                <div className="mb-4 md:mb-0 md:mr-6 cursor-pointer" onClick={() => setShowFullImage(true)}>
                  <img 
                    src={weddingDetails.image_url} 
                    alt="Wedding" 
                    className="w-72 h-56 md:w-40 md:h-32 object-cover rounded-lg shadow-md"
                  />
                </div>
              )}
              <div className="text-center md:text-left">
                <h1 className="text-3xl md:text-4xl font-semibold text-gray-800 font-serif">{weddingDetails.wedding_name}</h1>
                <p className="mt-2 text-base md:text-lg text-gray-500 font-serif">
                  {weddingDetails.wedding_date && `${weddingDetails.wedding_date} • `}
                  {weddingDetails.wedding_location}
                </p>
              </div>
            </div>
            {weddingDetails.wedding_date && (
              <div className="bg-white shadow-sm rounded-md px-4 md:px-6 py-3 md:py-4 mt-4 md:mt-0">
                <div className="flex space-x-3 md:space-x-6">
                  {Object.keys(timeLeft).map((interval) => (
                    <div key={interval} className="text-center">
                      <span className="text-xl md:text-3xl font-bold">{timeLeft[interval]}</span>
                      <p className="text-xs md:text-sm uppercase">{interval}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <h1 className="text-4xl font-serif text-gray-800 mb-2 text-center">{t('components.guestRegistryView.registryTitle', { type: registry.registry_type })}</h1>
          <p className="text-sm text-gray-500 mb-12 text-center italic">{t('components.guestRegistryView.createdOn', { date: new Date(registry.created_at).toLocaleDateString() })}</p>
        </>
      )}

      {/* Floating Cart Component */}
      <div 
        className="fixed bottom-6 right-6 z-50 bg-gray-800 text-white rounded-full p-4 shadow-lg cursor-pointer hover:bg-gray-700 transition-colors duration-200 flex items-center"
        onClick={() => setIsCartOpen(true)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
        <span className="font-bold">{getTotalItems(cart)}</span>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {gifts.map((gift) => (
          <div key={gift.id} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 overflow-hidden flex flex-col">
            <GiftImageGallery images={gift.image_urls || []} />
            <div className="p-6 flex flex-col flex-grow">
              <h3 className="text-xl font-serif text-gray-800 mb-2">{gift.name}</h3>
              <div className="text-sm text-gray-600 mb-4 flex-grow overflow-hidden">
                <ExpandableDescription description={gift.description} maxLength={100} />
              </div>
              <div className="flex justify-between items-center mb-4">
                <p className="text-lg font-semibold text-gray-800">{new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(gift.price)}</p>
              </div>
              <button 
                className="w-full px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200 mt-auto"
                onClick={() => addToCart(gift)}
              >
                {t('components.guestRegistryView.addToCart')}
              </button>
            </div>
          </div>
        ))}
      </div>

      {isCartOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full flex items-center justify-center z-50" onClick={() => setIsCartOpen(false)}>
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full" onClick={e => e.stopPropagation()}>
            <h3 className="text-3xl font-serif text-gray-800 mb-6 text-center">{t('components.guestRegistryView.yourCart')}</h3>
            {cart.length === 0 ? (
              <p className="text-gray-600 text-center">{t('components.guestRegistryView.emptyCart')}</p>
            ) : (
              <>
                {cart.map((item) => (
                  <div key={item.id} className="flex justify-between items-center mb-4 pb-4 border-b border-gray-200">
                    <div>
                      <p className="font-medium">{item.name}</p>
                      <p className="text-sm text-gray-600">{t('components.guestRegistryView.itemPrice', { price: item.price, quantity: item.quantity })}</p>
                    </div>
                    <div className="flex items-center">
                      <button onClick={() => updateCartItemQuantity(item.id, item.quantity - 1)} className="text-gray-500 hover:text-gray-700 px-2">-</button>
                      <span className="mx-2">{item.quantity}</span>
                      <button onClick={() => updateCartItemQuantity(item.id, item.quantity + 1)} className="text-gray-500 hover:text-gray-700 px-2">+</button>
                      <button onClick={() => removeFromCart(item.id)} className="ml-4 text-red-500 hover:text-red-700">{t('components.guestRegistryView.remove')}</button>
                    </div>
                  </div>
                ))}
                <p className="text-xl font-semibold text-gray-800 mb-6 text-center">{t('components.guestRegistryView.total', { total: getTotalPrice(cart) })}</p>
                <button
                  className="w-full px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200"
                  onClick={handleCheckout}
                >
                  {t('components.guestRegistryView.proceedToCheckout')}
                </button>
              </>
            )}
            <button
              className="mt-4 w-full px-4 py-2 rounded-md text-sm font-medium text-gray-800 bg-gray-200 hover:bg-gray-300 transition-colors duration-200"
              onClick={() => setIsCartOpen(false)}
            >
              {t('components.guestRegistryView.continueShopping')}
            </button>
          </div>
        </div>
      )}

      {showFullImage && weddingDetails && weddingDetails.image_url && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={() => setShowFullImage(false)}>
          <div className="max-w-4xl max-h-full p-4">
            <img 
              src={weddingDetails.image_url} 
              alt="Wedding" 
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestRegistryView;