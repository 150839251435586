import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PayoutInfo from './PayoutInfo';
import i18n from '../i18n';
import { Wallet, CreditCard } from 'lucide-react';
import LoadingAnimation from './LoadingAnimation';

const UserBalance = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    balance: 0,
    availableBalance: 0,
    orders: [],
    payouts: [],
    balanceHistory: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false);
  const [payoutAmount, setPayoutAmount] = useState('');
  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState('');
  const [payoutMethods, setPayoutMethods] = useState([]);
  const [activeTab, setActiveTab] = useState('balance');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const supabase = getSupabase();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const userDataResponse = await fetchUserData();
        setUserData(userDataResponse);
      } catch (error) {
        console.error(t('components.userBalance.fetchErrorLog'), error);
        setError(error.message);
        toast.error(t('components.userBalance.fetchErrorToast'));
      }
      setIsLoading(false);
    };

    fetchData();

    // Set active tab based on URL
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab === 'payoutInfo') {
      setActiveTab('payoutInfo');
    }
  }, [location]);

  const fetchUserData = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) throw new Error(t('components.userBalance.noSession'));

    const response = await fetch(`${API_BASE_URL}/user/balance`, {
      headers: { 'Authorization': `Bearer ${session.access_token}` }
    });
    if (!response.ok) throw new Error(t('components.userBalance.fetchError'));

    const data = await response.json();
    if (!data) throw new Error(t('components.userBalance.unexpectedDataFormat'));

    const balanceHistory = generateBalanceHistory(data.orders, data.payouts);
    return { ...data, balanceHistory };
  };

  const fetchPayoutMethods = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) throw new Error(t('components.userBalance.noSession'));

    const response = await fetch(`${API_BASE_URL}/payout-info`, {
      headers: { 'Authorization': `Bearer ${session.access_token}` }
    });
    if (!response.ok) throw new Error(t('components.userBalance.fetchPayoutMethodsError'));

    return await response.json();
  };

  const generateBalanceHistory = (orders, payouts) => {
    const completedAndPendingPayouts = payouts.filter(payout => 
      payout.status === 'completed' || payout.status === 'pending'
    );

    const allTransactions = [
      ...orders.map(order => ({ ...order, type: 'order' })),
      ...completedAndPendingPayouts.map(payout => ({ ...payout, type: 'payout', amount: -payout.amount }))
    ].sort((a, b) => new Date(a.date) - new Date(b.date));

    let runningBalance = 0;
    return allTransactions.map(transaction => {
      runningBalance += transaction.amount;
      return {
        date: new Date(transaction.date).toLocaleDateString(),
        balance: runningBalance,
        amount: transaction.amount,
        type: transaction.type
      };
    });
  };

  const initiatePayout = async () => {
    try {
      const methods = await fetchPayoutMethods();
      setPayoutMethods(methods);
      setIsPayoutModalOpen(true);
    } catch (error) {
      console.error(t('components.userBalance.fetchPayoutMethodsErrorLog'), error);
      toast.error(t('components.userBalance.fetchPayoutMethodsErrorToast'));
    }
  };

  const handlePayoutSubmit = async (e) => {
    e.preventDefault();
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      toast.error(t('components.userBalance.noSession'));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/payouts/initiate`, {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: parseFloat(payoutAmount),
          payout_information_id: selectedPayoutMethod
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t('components.userBalance.payoutInitiationError'));
      }

      toast.success(t('components.userBalance.payoutInitiatedSuccess'));
      setIsPayoutModalOpen(false);
      const updatedUserData = await fetchUserData();
      setUserData(updatedUserData);
    } catch (error) {
      console.error(t('components.userBalance.payoutInitiationErrorLog'), error);
      toast.error(error.message || t('components.userBalance.payoutInitiationErrorToast'));
    }
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  if (error) return <div className="text-center py-8 text-red-600">{t('components.userBalance.error', { error })}</div>;

  const { balance, availableBalance, orders, payouts, balanceHistory } = userData;

  const tabs = [
    { id: 'balance', label: t('components.userBalance.balanceTab'), icon: <Wallet className="h-5 w-5 mr-2" /> },
    { id: 'payoutInfo', label: t('components.userBalance.payoutInfoTab'), icon: <CreditCard className="h-5 w-5 mr-2" /> },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">{t('components.userBalance.title')}</h2>
      
      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabChange(tab.id)}
                className={`${
                  activeTab === tab.id
                    ? 'border-gray-800 text-gray-800'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm mr-8 flex items-center`}
              >
                {tab.icon}
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {activeTab === 'balance' && (
        <>
          <div className="bg-white shadow-sm rounded-lg p-4 sm:p-6 mb-4 sm:mb-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className="mb-4 sm:mb-0">
                {isLoading ? (
                  <>
                    <h3 className="text-base sm:text-lg font-medium text-gray-600 mb-1">{t('components.userBalance.totalBalance')}</h3>
                    <div className="h-8 bg-gray-200 rounded w-32 mb-2"></div>
                    <h3 className="text-sm font-medium text-gray-500 mt-2 mb-1">{t('components.userBalance.availableBalance')}</h3>
                    <div className="h-6 bg-gray-200 rounded w-24"></div>
                  </>
                ) : (
                  <>
                    <h3 className="text-base sm:text-lg font-medium text-gray-600 mb-1">{t('components.userBalance.totalBalance')}</h3>
                    <p className="text-2xl sm:text-3xl font-bold text-gray-800">
                      {new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(balance)}
                    </p>
                    <h3 className="text-sm font-medium text-gray-500 mt-2 mb-1">{t('components.userBalance.availableBalance')}</h3>
                    <p className="text-base sm:text-lg font-medium text-gray-600">
                      {new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(availableBalance)}
                    </p>
                  </>
                )}
              </div>
              <button
                onClick={initiatePayout}
                className="w-full sm:w-auto px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
                disabled={isLoading}
              >
                {t('components.userBalance.initiatePayout')}
              </button>
            </div>
          </div>
          <div className="bg-white shadow-sm rounded-lg p-4 sm:p-6 mb-4 sm:mb-6">
            <h3 className="text-lg sm:text-xl font-medium text-gray-800 mb-4">{t('components.userBalance.balanceHistory')}</h3>
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={balanceHistory}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis 
                    dataKey="date" 
                    stroke="#888888"
                    tick={{fill: '#888888', fontSize: 12}}
                  />
                  <YAxis 
                    stroke="#888888"
                    tick={{fill: '#888888', fontSize: 12}}
                  />
                  <Tooltip 
                    contentStyle={{backgroundColor: '#fff', border: 'none', boxShadow: '0 2px 8px rgba(0,0,0,0.15)'}}
                    labelStyle={{color: '#888888'}}
                    formatter={(value) => Number(value).toFixed(2)}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="balance" 
                    stroke="#6366f1" 
                    strokeWidth={3}
                    dot={false}
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          
          <div className="bg-white shadow-sm rounded-lg p-4 sm:p-6 mb-4 sm:mb-6">
            <h3 className="text-lg sm:text-xl font-medium text-gray-800 mb-4">{t('components.userBalance.recentActivity')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="text-base font-medium text-gray-700 mb-2">{t('components.userBalance.latestPayouts')}</h4>
                {isLoading ? (
                  <LoadingAnimation />
                ) : (
                  payouts.slice(-5).reverse().map((payout, index) => (
                    <div key={index} className="flex justify-between items-center py-2 border-b border-gray-200 last:border-b-0 cursor-pointer hover:bg-gray-50" onClick={() => handleTransactionClick(payout)}>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-600">{new Date(payout.date).toLocaleDateString(i18n.language === 'es' ? 'es-MX' : i18n.language)}</span>
                        <span className={`text-xs px-2 py-0.5 rounded-full ${
                          payout.status === 'completed' ? 'bg-green-100 text-green-800' :
                          payout.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {t(`components.userBalance.payoutStatus.${payout.status}`)}
                        </span>
                      </div>
                      <span className="text-sm font-medium text-red-600">-{new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(payout.amount)}</span>
                    </div>
                  ))
                )}
              </div>
              <div>
                <h4 className="text-base font-medium text-gray-700 mb-2">{t('components.userBalance.latestOrders')}</h4>
                {isLoading ? (
                  <LoadingAnimation />
                ) : (
                  orders.slice(-5).reverse().map((order, index) => (
                    <div key={index} className="flex justify-between items-center py-2 border-b border-gray-200 last:border-b-0 cursor-pointer hover:bg-gray-50" onClick={() => handleTransactionClick(order)}>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-600">{new Date(order.date).toLocaleDateString(i18n.language === 'es' ? 'es-MX' : i18n.language)}</span>
                      </div>
                      <span className="text-sm font-medium text-green-600">+{new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(order.amount)}</span>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {activeTab === 'payoutInfo' && (
        <PayoutInfo />
      )}
      
      {isPayoutModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">{t('components.userBalance.initiatePayoutTitle')}</h3>
            <form onSubmit={handlePayoutSubmit}>
              <div className="mb-4">
                <label htmlFor="payoutAmount" className="block text-sm font-medium text-gray-700 mb-2">
                  {t('components.userBalance.payoutAmount')}
                </label>
                <input
                  type="number"
                  id="payoutAmount"
                  value={payoutAmount}
                  onChange={(e) => setPayoutAmount(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                  required
                  placeholder={new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'MXN' }).format(0)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="payoutMethod" className="block text-sm font-medium text-gray-700 mb-2">
                  {t('components.userBalance.payoutMethod')}
                </label>
                <select
                  id="payoutMethod"
                  value={selectedPayoutMethod}
                  onChange={(e) => setSelectedPayoutMethod(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                  required
                >
                  <option value="">{t('components.userBalance.selectPayoutMethod')}</option>
                  {payoutMethods.map((method) => (
                    <option key={method.id} value={method.id}>
                      {method.details.beneficiary} - {method.details.clabe}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setIsPayoutModalOpen(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  {t('components.userBalance.cancel')}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
                >
                  {t('components.userBalance.confirmPayout')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {selectedTransaction && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
            <h3 className="text-lg font-medium text-gray-900 mb-4">{t('components.userBalance.transactionDetails')}</h3>
            <pre className="bg-gray-100 p-4 rounded-md overflow-auto text-sm">
              {JSON.stringify(selectedTransaction, null, 2)}
            </pre>
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setSelectedTransaction(null)}
                className="px-4 py-2 bg-gray-100 text-gray-800 rounded-md hover:bg-gray-200 transition-colors duration-200 text-sm"
              >
                {t('components.userBalance.close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UserBalance;