import React from 'react';
import { useTranslation } from 'react-i18next';

const ComingSoonModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        <div className="flex flex-col items-center">
          <svg className="w-16 h-16 text-gray-800 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h2 className="text-2xl font-bold mb-4 text-center">{t('components.comingSoon.title')}</h2>
          <p className="mb-6 text-center">{t('components.comingSoon.message')}</p>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
        >
          {t('components.comingSoon.close')}
        </button>
      </div>
    </div>
  );
};

export default ComingSoonModal;