import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import GiftImageGallery from './GiftImageGallery';
import ExpandableDescription from './ExpandableDescription';
import i18n from '../i18n';


const RegistrySelectionModal = ({ registries, onSelect, onClose }) => {
    const { t } = useTranslation();
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">{t('components.giftCatalog.selectRegistry')}</h3>
          <div className="space-y-2 mb-4">
            {registries.map((registry) => (
              <button
                key={registry.id}
                onClick={() => onSelect(registry.id)}
                className="w-full text-left px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors duration-200"
              >
                {registry.registry_type}
              </button>
            ))}
          </div>
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
          >
            {t('components.giftCatalog.cancel')}
          </button>
        </div>
      </div>
    );
  };

const GiftCatalog = () => {
  const { t } = useTranslation();
  const [gifts, setGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showRegistryModal, setShowRegistryModal] = useState(false);
  const [selectedGiftId, setSelectedGiftId] = useState(null);
  const [registries, setRegistries] = useState([]);
  const [searchPrompt, setSearchPrompt] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isCategoryChanging, setIsCategoryChanging] = useState(false);
  const supabase = getSupabase();
  const { id: registryId } = useParams();
  const navigate = useNavigate();

  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    fetchCategories();
    fetchGifts();
    if (!registryId) {
      fetchRegistries();
    }
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setIsCategoryChanging(true);
      fetchSubcategories(selectedCategory);
      setSelectedSubcategory('');
      fetchGifts(1).finally(() => setIsCategoryChanging(false));
    } else {
      setSubcategories([]);
      fetchGifts(1);
    }
  }, [selectedCategory]);

  useEffect(() => {
    setIsCategoryChanging(true);
    fetchGifts(1).finally(() => setIsCategoryChanging(false));
  }, [selectedSubcategory]);

  const getAuthHeaders = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    return session ? { 'Authorization': `Bearer ${session.access_token}` } : {};
  };

  const fetchCategories = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/gifts/categories`, { headers });
      if (!response.ok) throw new Error('Failed to fetch categories');
      const data = await response.json();
      setCategories(data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/gifts/subcategories/${categoryId}`, { headers });
      if (!response.ok) throw new Error('Failed to fetch subcategories');
      const data = await response.json();
      setSubcategories(data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchGifts = async (pageNumber = 1) => {
    if (isLoading || isSearchMode) return;
    
    try {
      setIsLoading(true);
      const headers = await getAuthHeaders();
      const url = new URL(`${API_BASE_URL}/gifts`);
      url.searchParams.append('page', pageNumber);
      url.searchParams.append('limit', ITEMS_PER_PAGE);
      if (selectedCategory) url.searchParams.append('category', selectedCategory);
      if (selectedSubcategory) url.searchParams.append('subcategory', selectedSubcategory);
      
      const response = await fetch(url, { headers });
      if (!response.ok) throw new Error('Failed to fetch gifts');
      const data = await response.json();
      setGifts(prevGifts => {
        if (!Array.isArray(prevGifts)) prevGifts = [];
        return pageNumber === 1 ? data.gifts : [...prevGifts, ...data.gifts];
      });
      setHasMore(data.has_more);
      setPage(pageNumber);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRegistries = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registries`, { headers });
      if (!response.ok) throw new Error('Failed to fetch registries');
      const data = await response.json();
      setRegistries(data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addToRegistry = async (giftId, targetRegistryId) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/registry/${targetRegistryId}/assign-gift`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ gift_id: giftId })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add gift to registry');
      }
      toast.success(t('components.giftCatalog.addedToRegistry'));
    } catch (err) {
      return err.message;
    }
  };

  const handleAddToRegistry = async (giftId) => {
    if (registryId) {
      const error = await addToRegistry(giftId, registryId);
      if (error) {
        toast.error(error);
      }
    } else {
      setSelectedGiftId(giftId);
      setShowRegistryModal(true);
    }
  };

  const handleSelectRegistry = async (selectedRegistryId) => {
    const error = await addToRegistry(selectedGiftId, selectedRegistryId);
    if (error) {
      toast.error(error);
    }
    setShowRegistryModal(false);
    setSelectedGiftId(null);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearching(true);
    setIsSearchMode(true);
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_BASE_URL}/search_gifts`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ prompt: searchPrompt })
      });
      if (!response.ok) throw new Error('Failed to search gifts');
      const data = await response.json();
      setGifts(data.results);
      setHasMore(false);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setIsSearching(false);
    }
  };

  const handleLoadMore = () => {
    if (!isSearchMode && hasMore) {
      fetchGifts(page + 1);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setIsSearchMode(false);
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
    setIsSearchMode(false);
  };

  if (error) return <div className="text-red-500">{t('components.giftCatalog.error')}: {error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-900 mb-4 sm:mb-0">{t('components.giftCatalog.title')}</h2>
        {registryId && (
          <button
            onClick={() => navigate(`/registry/${registryId}`)}
            className="w-full sm:w-auto px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium flex items-center justify-center"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            {t('components.giftCatalog.backToRegistry')}
          </button>
        )}
      </div>
      <div className="mb-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="w-full sm:w-1/3 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
          disabled={isCategoryChanging}
        >
          <option value="">{t('components.giftCatalog.allCategories')}</option>
          {categories.map((category) => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
        {selectedCategory && (
          <select
            value={selectedSubcategory}
            onChange={handleSubcategoryChange}
            className="w-full sm:w-1/3 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
            disabled={isCategoryChanging}
          >
            <option value="">{t('components.giftCatalog.allSubcategories')}</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory} value={subcategory}>{subcategory}</option>
            ))}
          </select>
        )}
        <form onSubmit={handleSearch} className="w-full sm:w-1/3">
          <div className="relative">
            <input
              type="text"
              value={searchPrompt}
              onChange={(e) => setSearchPrompt(e.target.value)}
              placeholder={t('components.giftCatalog.searchPlaceholder')}
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
              disabled={isSearching}
            />
            <button
              type="submit"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
              aria-label={t('components.giftCatalog.search')}
              disabled={isSearching}
            >
              {isSearching ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              )}
            </button>
          </div>
        </form>
      </div>
      {(isLoading || isCategoryChanging) && (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && !isCategoryChanging && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {!Array.isArray(gifts) || gifts.length === 0 ? (
            <p className="text-center col-span-full">{t('components.giftCatalog.noGiftsFound')}</p>
          ) : (
            gifts.map((gift) => (
              <GiftCard key={gift.id} gift={gift} onAddToRegistry={handleAddToRegistry} />
            ))
          )}
        </div>
      )}
      {!isLoading && !isCategoryChanging && hasMore && (
        <div className="mt-6 text-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium"
          >
            {t('components.giftCatalog.loadMore')}
          </button>
        </div>
      )}
      {!isLoading && !isCategoryChanging && !hasMore && gifts.length > 0 && (
        <p className="mt-6 text-center text-gray-600">{t('components.giftCatalog.noMoreGifts')}</p>
      )}
      {showRegistryModal && (
        <RegistrySelectionModal
          registries={registries}
          onSelect={handleSelectRegistry}
          onClose={() => setShowRegistryModal(false)}
        />
      )}
    </div>
  );
};

const GiftCard = ({ gift, onAddToRegistry }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow-sm rounded-lg p-4 transition-shadow duration-200 hover:shadow-md flex flex-col h-full">
      <div className="mb-4 flex-grow">
        <GiftImageGallery images={gift.image_urls || []} />
      </div>
      <h4 className="text-lg font-medium text-gray-800 mb-2 line-clamp-2 overflow-hidden">{gift.name}</h4>
      <div className="text-sm text-gray-600 mb-2 flex-grow">
        <ExpandableDescription description={gift.description} maxLength={200} />
      </div>
      <p className="text-xl font-bold text-gray-700 mb-4">
        {new Intl.NumberFormat(i18n.language === 'es' ? 'es-MX' : i18n.language, { style: 'currency', currency: 'USD' }).format(gift.price)}
      </p>
      <div className="mt-auto">
        <button 
          onClick={() => onAddToRegistry(gift.id)} 
          className="w-full px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200 text-sm font-medium"
        >
          {t('components.giftCatalog.addToRegistry')}
        </button>
      </div>
    </div>
  );
};

export default GiftCatalog;