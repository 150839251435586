import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';

const CheckoutSuccessPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [stripeInfo, setStripeInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrderAndConfirmPayment();
  }, [id]);

  const fetchOrderAndConfirmPayment = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      
      if (!sessionId) {
        throw new Error('Session ID not found in URL');
      }

      const response = await fetch(`${API_BASE_URL}/public/checkout/confirm-payment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderId: id, sessionId: sessionId }),
      });
      if (!response.ok) throw new Error('Failed to confirm payment');
      const data = await response.json();
      setOrder(data.order[0]); // Access the first (and only) order in the array
      setStripeInfo(data.stripeInfo);
    } catch (err) {
      setError(err.message);
    }
  };

  if (error) {
    return <div className="text-red-500 text-center mt-8">Error: {error}</div>;
  }

  if (!order || !stripeInfo) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-xl">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-semibold text-gray-800 mt-4">{t('components.checkoutSuccessPage.thankYou')}</h1>
        <p className="text-gray-600 mt-2">{t('components.checkoutSuccessPage.orderConfirmed')}</p>
      </div>

      <div className="mb-8 bg-gray-100 p-4 rounded-md">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">{t('components.checkoutSuccessPage.giftGiven')}</h2>
        {order.cart && order.cart.map((item) => (
          <div key={item.id} className="mb-2">
            <span>{item.name} (Quantity: {item.quantity})</span>
          </div>
        ))}
      </div>

      {order.message && (
        <div className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">{t('components.checkoutSuccessPage.giftMessage')}</h2>
          <p className="bg-gray-100 p-4 rounded-md italic">{order.message}</p>
        </div>
      )}
      <p className="text-center text-gray-600">{t('components.checkoutSuccessPage.emailConfirmation')}</p>
      <p className="text-center text-gray-600 mt-2">{t('components.checkoutSuccessPage.additionalThanks')}</p>
    </div>
  );
};

export default CheckoutSuccessPage;