
import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Calendar, Users, MessageSquare, MapPin, Heart } from 'lucide-react'
import { getSupabase, API_BASE_URL } from '../config/supabase';
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';


const WeddingDetailsModal = ({ weddingDetails, onSave, onClose }) => {
  const [step, setStep] = useState(0)
  const [updatedDetails, setUpdatedDetails] = useState({ ...weddingDetails })
  const supabase = getSupabase();
  const { t } = useTranslation();

  useEffect(() => {
    setStep(weddingDetails && weddingDetails.wedding_name ? 1 : 0)
  }, [weddingDetails])

  const handleInputChange = (e) => {
    setUpdatedDetails({ ...updatedDetails, [e.target.name]: e.target.value })
  }

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1)
    } else {
      handleSubmit()
    }
  }

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const handleSubmit = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/wedding-details`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatedDetails)
        });
        if (response.ok) {
          const data = await response.json();
          onSave(data);
          onClose();
          toast.success('Wedding details updated successfully');
        } else {
          const errorData = await response.json();
          console.error('Failed to update wedding details:', errorData.error);
          toast.error(`Failed to update wedding details: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error updating wedding details:', error);
        toast.error(`Error updating wedding details: ${error.message}`);
      }
    } else {
      toast.error('No active session. Please log in.');
    }
  };

  const Logo = () => (
    <div className="flex items-center justify-center">
      <svg className="w-8 h-8 sm:w-10 sm:h-10 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1-7.5-16.5" strokeWidth="2" />
      </svg>
      <div className="flex items-center ml-2">
        <span className="text-xl sm:text-2xl font-semibold text-gray-800">Metzi</span>
        <span className="ml-2 px-2 py-1 text-xs font-semibold text-gray-600 bg-gray-200 rounded-full">Beta</span>
      </div>
    </div>
  )

  const steps = [
    {
      title: t('components.weddingDetailsModal.welcomeTitle'),
      icon: Logo,
      content: (
        <div className="text-center">
          <p className="mt-4 text-base sm:text-lg text-gray-600">
            {t('components.weddingDetailsModal.welcomeMessage')}
          </p>
          <p className="mt-4 text-base sm:text-lg text-gray-600">
            {t('components.weddingDetailsModal.createBeautifulExperience')}
          </p>
        </div>
      ),
    },
    {
      title: t('components.weddingDetailsModal.weddingDateTitle'),
      icon: Calendar,
      content: (
        <div className="space-y-4">
          <label htmlFor="wedding_date" className="block text-sm font-medium text-gray-700">{t('components.weddingDetailsModal.weddingDate')}</label>
          <input
            type="date"
            id="wedding_date"
            name="wedding_date"
            value={updatedDetails.wedding_date || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
          />
        </div>
      ),
    },
    {
      title: t('components.weddingDetailsModal.coupleNamesTitle'),
      icon: Users,
      content: (
        <div className="space-y-4">
          <div>
            <label htmlFor="person1_name" className="block text-sm font-medium text-gray-700">{t('components.weddingDetailsModal.person1Name')}</label>
            <input
              type="text"
              id="person1_name"
              name="person1_name"
              value={updatedDetails.person1_name || ''}
              onChange={handleInputChange}
              placeholder={t('components.weddingDetailsModal.person1NamePlaceholder')}
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="person2_name" className="block text-sm font-medium text-gray-700">{t('components.weddingDetailsModal.person2Name')}</label>
            <input
              type="text"
              id="person2_name"
              name="person2_name"
              value={updatedDetails.person2_name || ''}
              onChange={handleInputChange}
              placeholder={t('components.weddingDetailsModal.person2NamePlaceholder')}
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
        </div>
      ),
    },
    {
      title: t('components.weddingDetailsModal.weddingNameTitle'),
      icon: MessageSquare,
      content: (
        <div className="space-y-4">
          <label htmlFor="wedding_name" className="block text-sm font-medium text-gray-700">{t('components.weddingDetailsModal.weddingDisplayName')}</label>
          <input
            type="text"
            id="wedding_name"
            name="wedding_name"
            value={updatedDetails.wedding_name || ''}
            onChange={handleInputChange}
            placeholder={t('components.weddingDetailsModal.weddingDisplayNamePlaceholder')}
            className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
          />
        </div>
      ),
    },
    {
      title: t('components.weddingDetailsModal.weddingLocationTitle'),
      icon: MapPin,
      content: (
        <div className="space-y-4">
          <label htmlFor="wedding_location" className="block text-sm font-medium text-gray-700">{t('components.weddingDetailsModal.weddingLocation')}</label>
          <input
            type="text"
            id="wedding_location"
            name="wedding_location"
            value={updatedDetails.wedding_location || ''}
            onChange={handleInputChange}
            placeholder={t('components.weddingDetailsModal.weddingLocationPlaceholder')}
            className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
          />
        </div>
      ),
    },
  ]

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center p-4">
      <div className="relative p-4 sm:p-6 w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl bg-white rounded-lg shadow-xl">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">
          {steps[step].title}
        </h2>
        <AnimatePresence mode="wait">
          <motion.div
            key={step}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="h-64 sm:h-80 overflow-y-auto"
          >
            <div className="flex justify-center mb-4 sm:mb-6">
              {React.createElement(steps[step].icon, { className: "w-12 h-12 sm:w-16 sm:h-16 text-gray-800" })}
            </div>
            {steps[step].content}
          </motion.div>
        </AnimatePresence>
        <div className="mt-6 sm:mt-8 flex justify-between">
          <button
            onClick={step === 0 ? onClose : handleBack}
            className={`px-3 py-2 sm:px-4 sm:py-2 rounded-md text-sm font-medium transition-colors duration-150 ${
              step === 0
                ? 'bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50'
            }`}
          >
            {step === 0 ? t('components.weddingDetailsModal.close') : t('components.weddingDetailsModal.back')}
          </button>
          <button
            onClick={handleNext}
            className="px-3 py-2 sm:px-4 sm:py-2 bg-gray-800 text-white rounded-md text-sm font-medium hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors duration-150"
          >
            {step === steps.length - 1 ? t('components.weddingDetailsModal.finish') : t('components.weddingDetailsModal.next')}
          </button>
        </div>
        <div className="mt-6 sm:mt-8 flex justify-between space-x-1 sm:space-x-2">
          {steps.map((_, index) => (
            <motion.div
              key={index}
              className={`h-1 sm:h-2 rounded-full flex-grow ${
                index <= step ? 'bg-gray-800' : 'bg-gray-200'
              }`}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: index <= step ? 1 : 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WeddingDetailsModal