import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';


// Updated PayoutInfo component (remove the outer div and title)
const PayoutInfo = () => {
  const { t } = useTranslation();
  const [payoutInfo, setPayoutInfo] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [clabe, setClabe] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [bank, setBank] = useState('');
  const [reference, setReference] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const supabase = getSupabase();

  useEffect(() => {
    fetchPayoutInfo();
  }, []);

  const fetchPayoutInfo = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No session found');

      const response = await fetch(`${API_BASE_URL}/payout-info`, {
        headers: { 'Authorization': `Bearer ${session.access_token}` }
      });
      if (!response.ok) throw new Error('Failed to fetch payout information');

      const data = await response.json();
      setPayoutInfo(data);
    } catch (err) {
      setError(err.message);
      toast.error(t('components.payoutInfo.fetchError'));
    } finally {
      setIsLoading(false);
    }
  };

  const addPayoutInfo = async (e) => {
    e.preventDefault();
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No session found');

      const payoutDetails = { clabe, beneficiary, bank, reference };
      const response = await fetch(`${API_BASE_URL}/payout-info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ payout_method: 'bank_account', details: payoutDetails })
      });
      if (!response.ok) throw new Error('Failed to add payout information');

      toast.success(t('components.payoutInfo.addSuccess'));
      fetchPayoutInfo();
      setIsAdding(false);
      setClabe('');
      setBeneficiary('');
      setBank('');
      setReference('');
    } catch (err) {
      toast.error(err.message);
    }
  };

  const deletePayoutInfo = async (id) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No session found');

      const response = await fetch(`${API_BASE_URL}/payout-info/${id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${session.access_token}` }
      });
      if (!response.ok) throw new Error('Failed to delete payout information');

      toast.success(t('components.payoutInfo.deleteSuccess'));
      fetchPayoutInfo();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'verified':
        return 'bg-green-100 text-green-800';
      case 'not_verified':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (isLoading) return (
    <div className="flex items-center justify-center min-h-screen">
      <LoadingSpinner />
    </div>
  );
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">      
      {payoutInfo.length === 0 ? (
        <div className="text-center p-6 bg-gray-100 rounded-lg">
          <p className="text-gray-600 mb-4">{t('components.payoutInfo.noInfo')}</p>
          <div className="space-y-4">
            <button
              onClick={() => setIsAdding(true)}
              className="w-full px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition duration-200"
            >
              {t('components.payoutInfo.addManually')}
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          {payoutInfo.map((info) => (
            <div key={info.id} className="bg-white p-4 sm:p-6 rounded-lg shadow-md relative">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                {Object.entries(info.details).map(([key, value]) => (
                  <div key={key}>
                    <p className="text-sm font-semibold text-gray-600">{t(`components.payoutInfo.${key}`)}</p>
                    <p className="text-base sm:text-lg text-gray-800">{value || '-'}</p>
                  </div>
                ))}
                <div>
                  <p className="text-sm font-semibold text-gray-600">{t('components.payoutInfo.currency')}</p>
                  <p className="text-base sm:text-lg text-gray-800">{info.currency}</p>
                </div>
                <div>
                  <p className="text-sm font-semibold text-gray-600">{t('components.payoutInfo.createdAt')}</p>
                  <p className="text-base sm:text-lg text-gray-800">{new Date(info.created_at).toLocaleDateString()}</p>
                </div>
              </div>
              <div className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(info.status)}`}>
                {t(`components.payoutInfo.status.${info.status}`)}
              </div>
              <button 
                onClick={() => deletePayoutInfo(info.id)} 
                className="mt-2 sm:mt-0 sm:absolute sm:bottom-2 sm:right-2 flex items-center px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200"
                title={t('components.payoutInfo.delete')}
              >
                <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span className="text-sm">{t('components.payoutInfo.delete')}</span>
              </button>
            </div>
          ))}
          <div className="flex space-x-4">
            <button
              onClick={() => setIsAdding(true)}
              className="flex-1 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition duration-200"
            >
              {t('components.payoutInfo.addAnotherManually')}
            </button>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 z-50 flex items-center justify-center overflow-y-auto p-4">
          <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-md">
            <h3 className="text-lg font-medium text-gray-900 mb-4">{t('components.payoutInfo.addPayoutInfo')}</h3>
            <form onSubmit={addPayoutInfo} className="space-y-4">
              <div>
                <label htmlFor="clabe" className="block text-sm font-medium text-gray-700">
                  {t('components.payoutInfo.clabe')}
                </label>
                <input
                  type="text"
                  id="clabe"
                  value={clabe}
                  onChange={(e) => {
                    const newClabe = e.target.value;
                    setClabe(newClabe);
                    if (newClabe.length >= 3) {
                      const bankCode = newClabe.substring(0, 3);
                      const bankName = getBankNameFromCode(bankCode);
                      setBank(bankName);
                    }
                  }}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="beneficiary" className="block text-sm font-medium text-gray-700">
                  {t('components.payoutInfo.beneficiary')}
                </label>
                <input
                  type="text"
                  id="beneficiary"
                  value={beneficiary}
                  onChange={(e) => setBeneficiary(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
                  {t('components.payoutInfo.bank')}
                </label>
                <input
                  type="text"
                  id="bank"
                  value={bank}
                  readOnly
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-gray-50 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                  {t('components.payoutInfo.reference')}
                </label>
                <input
                  type="text"
                  id="reference"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                />
              </div>
              <div className="flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0 sm:space-x-3 mt-6">
                <button
                  type="button"
                  onClick={() => setIsAdding(false)}
                  className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  {t('components.payoutInfo.cancel')}
                </button>
                <button
                  type="submit"
                  className="w-full sm:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  {t('components.payoutInfo.addPayoutInfo')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      
    </div>
  );
};
  
  function getBankNameFromCode(code) {
    const bankCodes = {
      '002': 'Banamex',
      '012': 'BBVA Mexico',
      '014': 'Santander',
      '021': 'HSBC',
      '030': 'Bajio',
      '036': 'Inbursa',
      '042': 'Mifel',
      '044': 'Scotiabank',
      '058': 'Banregio',
      '059': 'Invex',
      '060': 'Bansi',
      '062': 'Afirme',
      '072': 'Banorte',
      '106': 'Bank of America',
      '108': 'MUFG',
      '110': 'Jp Morgan',
      '112': 'Bmonex',
      '113': 'Ve por Mas',
      '127': 'Azteca',
      '128': 'Autofin',
      '129': 'Barclays',
      '130': 'Compartamos',
      '132': 'Multiva Banco',
      '133': 'Actinver',
      '136': 'Intercam Banco',
      '137': 'Bancoppel',
      '138': 'Abc Capital',
      '140': 'Consubanco',
      '141': 'Volkswagen',
      '143': 'Cibanco',
      '145': 'BBase',
      '147': 'Bankaool',
      '148': 'Pagatodo',
      '150': 'Inmobiliario',
      '151': 'Donde',
      '152': 'Bancrea',
      '154': 'Banco Covalto',
      '155': 'ICBC',
      '156': 'Sabadell',
      '157': 'Shinhan',
      '158': 'Mizuho Bank',
      '159': 'Bank of China',
      '160': 'Banco S3',
      '600': 'MonexCb',
      '601': 'GBM',
      '602': 'Masari',
      '605': 'Value',
      '608': 'Vector',
      '616': 'Finamex',
      '617': 'Valmex',
      '620': 'Profuturo',
      '630': 'CB Intercam',
      '631': 'CI Bolsa',
      '634': 'Fincomun',
      '638': 'Nu Mexico',
      '642': 'Reforma',
      '646': 'Stp',
      '652': 'Credicapital',
      '653': 'Kuspit',
      '656': 'Unagra',
      '659': 'ASP Interga OPC',
      '661': 'Alternativos',
      '670': 'Libertad',
      '677': 'Caja Pop Mexica',
      '680': 'Cristobal Colon',
      '683': 'Caja Telefonist',
      '684': 'Transfer',
      '685': 'Fondo (FIRA)',
      '686': 'Invercap',
      '689': 'FOMPED',
      '703': 'Tesored',
      '706': 'Arcus',
      '710': 'NVIO',
      '722': 'Mercado Pago',
      '723': 'Cuenca',
      '902': 'Indeval'
    };
    return bankCodes[code] || 'Unknown Bank';
  }

  export default PayoutInfo;