import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingAnimation from './LoadingAnimation';
import { useTranslation } from 'react-i18next';
import WeddingSettings from './WeddingSettings';
import { useLocation, useNavigate } from 'react-router-dom';

// Updated Profile component
const Profile = () => {
  const { t } = useTranslation();
  const [profileData, setProfileData] = useState({
    fullName: '',
    phone: '',
    address: '',
    email: ''
  });
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('profile');
  const supabase = getSupabase();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfile();
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab === 'weddingSettings') {
      setActiveTab('weddingSettings');
    }
  }, [location]);

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const response = await fetch(`${API_BASE_URL}/profile`, {
          headers: {
            'Authorization': `Bearer ${session.access_token}`
          }
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setProfileData({
          fullName: data.full_name || '',
          phone: data.phone || '',
          address: data.address || '',
          email: data.email || ''
        });
      } else {
        setError('No active session. Please log in.');
      }
    } catch (e) {
      console.error('Error fetching profile:', e);
      setError(`Failed to fetch profile: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      const response = await fetch(`${API_BASE_URL}/profile`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          full_name: profileData.fullName,
          phone: profileData.phone,
          address: profileData.address
        })
      });
      if (response.ok) {
        toast.success('Profile updated successfully');
        setIsEditing(false);
        fetchProfile();
      } else {
        toast.error('Failed to update profile');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = [
    { id: 'profile', label: t('components.profile.profile'), icon: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z' },
    { id: 'weddingSettings', label: t('components.profile.weddingSettings'), icon: 'M3.5,13.5A8.5,8.5,0,1,0,14.354,5.339L15.5,3.556,14.625,2H9.375L8.5,3.556,9.646,5.339A8.509,8.509,0,0,0,3.5,13.5ZM12,7a6.5,6.5,0,1,1-6.5,6.5A6.508,6.508,0,0,1,12,7Z' }
  ];

  const renderProfileField = (label, value) => (
    <div className="bg-white shadow-sm rounded-lg p-4">
      <p className="text-sm text-gray-500">{label}</p>
      {isLoading ? (
        <div className="h-6 bg-gray-300 rounded w-3/4 animate-pulse"></div>
      ) : (
        <p className="text-lg font-medium">{value || t('components.profile.notProvided')}</p>
      )}
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">{t('components.profile.yourProfile')}</h2>
      
      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabChange(tab.id)}
                className={`${
                  activeTab === tab.id
                    ? 'border-gray-800 text-gray-800'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center mr-8`}
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={tab.icon} />
                </svg>
                <span className="hidden sm:inline">{tab.label}</span>
                <span className="sm:hidden">{tab.label.split(' ')[0]}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>

      {activeTab === 'profile' && (
        <>
          {isEditing ? (
            <form onSubmit={updateProfile} className="space-y-4">
              {renderProfileField(t('components.profile.email'), profileData.email)}
              <input
                type="text"
                name="fullName"
                placeholder={t('components.profile.fullName')}
                value={profileData.fullName}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <input
                type="tel"
                name="phone"
                placeholder={t('components.profile.phone')}
                value={profileData.phone}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <input
                type="text"
                name="address"
                placeholder={t('components.profile.address')}
                value={profileData.address}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <div className="flex space-x-4">
                <button type="submit" className="flex-1 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200">{t('components.profile.saveChanges')}</button>
                <button type="button" onClick={() => setIsEditing(false)} className="flex-1 px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200">{t('components.profile.cancel')}</button>
              </div>
            </form>
          ) : (
            <div className="space-y-4">
              {renderProfileField(t('components.profile.email'), profileData.email)}
              {renderProfileField(t('components.profile.fullName'), profileData.fullName)}
              {renderProfileField(t('components.profile.phone'), profileData.phone)}
              {renderProfileField(t('components.profile.address'), profileData.address)}
              <button
                onClick={() => setIsEditing(true)}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
              >
                {t('components.profile.editProfile')}
              </button>
            </div>
          )}
        </>
      )}

      {activeTab === 'weddingSettings' && (
        <WeddingSettings />
      )}
    </div>
  );
};

export default Profile;