import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSupabase, API_BASE_URL } from '../config/supabase';
import LoadingSpinner from './LoadingSpinner';

const ConfirmPayoutInfo = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const supabase = getSupabase();

  useEffect(() => {
    const confirmPayoutInfo = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) throw new Error('No session found');

        const response = await fetch(`${API_BASE_URL}/confirm-payout-info/${token}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${session.access_token}` }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to confirm payout information');
        }

        const data = await response.json();
        toast.success('Payout information confirmed successfully');
        navigate('/profile');
      } catch (error) {
        toast.error(error.message);
        navigate('/profile');
      } finally {
        setIsLoading(false);
      }
    };

    confirmPayoutInfo();
  }, [token, navigate, supabase]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return null;
};

export default ConfirmPayoutInfo;
